import { Foundation, Ionicons } from '@expo/vector-icons';
import { Box, Center, Icon, Image } from '@gluestack-ui/themed-native-base';
import { memo } from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useCustomMessageAvatar } from '~/hooks/useCustomMessageAvatar';
import type { Channel } from 'stream-chat';

type Props = {
  userId?: string;
  name?: string;
  online?: boolean;
  size?: number | string;
  iconSize?: number | string;
  channel?: Channel | string;
};

const AvatarComponent = memo(({ userId, name, size, iconSize, channel }: Omit<Props, 'online'>) => {
  const { avatar, isLoadError, onError, onLoad } = useCustomMessageAvatar(userId);

  if (userId === 'alert-chat-notifier-id') {
    return (
      <Icon as={Ionicons} name="notifications-circle-outline" size={iconSize || '2xl'} color="#ff0000" padding={0} />
    );
  }

  if (isLoadError || !avatar) {
    return <Icon as={Ionicons} name="person-circle-outline" size={iconSize || '2xl'} color="#ccc" padding={0} />;
  }

  return (
    <Image
      backgroundColor="onSurfaceHighest"
      // @ts-expect-error TS(2339)
      source={{ uri: channel?.data?.image ? channel?.data?.image : avatar }}
      alt={name}
      onError={onError}
      onLoad={onLoad}
      size={size || 30}
      borderRadius="full"
    />
  );
});

export const AvatarWithOnline = ({ online, ...props }: Props) => {
  return (
    <Box position="relative">
      <AvatarComponent {...props} />
      {online && (
        <Box position="absolute" top={0} right={0}>
          <Center size={3} borderRadius="full" bg="#fff" p={0.5}>
            <Box w="full" h="full" bg="#20E070" borderRadius="full" />
          </Center>
        </Box>
      )}
    </Box>
  );
};

export const AvatarWithRoom = (props: Props) => {
  return (
    <Box position="relative">
      <AvatarComponent {...props} />
      <Box position="absolute" bottom={-gluestackUIConfig.tokens.space[1]} right={-gluestackUIConfig.tokens.space[1]}>
        <Center
          borderColor="onPrimary"
          borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderRadius={gluestackUIConfig.tokens.borderRadius.full}
          backgroundColor="primary"
          p="2xs"
        >
          <Icon as={Foundation} name="home" size="xs" color="onPrimary" />
        </Center>
      </Box>
    </Box>
  );
};

export const AvatarWithDM = (props: Props) => {
  const { channel } = props;

  return (
    <Center width={gluestackUIConfig.tokens.space['2.5']}>
      <Box position="relative">
        <AvatarComponent userId="" name="" />
        <Box
          position="absolute"
          top={-gluestackUIConfig.tokens.space[3]}
          right={-gluestackUIConfig.tokens.space[3]}
          borderColor="onPrimary"
          borderWidth={gluestackUIConfig.tokens.borderWidths.thick}
          borderRadius={gluestackUIConfig.tokens.borderRadius.full}
        >
          <AvatarComponent
            /* @ts-expect-error TS(2339): Property 'state' does not exist on type 'string | Channel<DefaultGenerics>'.Property 'state' does not exist on type 'string'*/
            userId={Object.keys(channel?.state?.members)[0]}
            name="user_1"
            size={props.size}
            iconSize={props.iconSize}
          />
        </Box>
        <Box
          position="absolute"
          bottom={-gluestackUIConfig.tokens.space[3]}
          left={-gluestackUIConfig.tokens.space[3]}
          borderColor="onPrimary"
          borderWidth={gluestackUIConfig.tokens.borderWidths.thick}
          borderRadius={gluestackUIConfig.tokens.borderRadius.full}
        >
          <AvatarComponent
            /* @ts-expect-error TS(2339): Property 'state' does not exist on type 'string | Channel<DefaultGenerics>'.Property 'state' does not exist on type 'string'.ts(2339) */
            userId={Object.keys(channel?.state?.members)[1]}
            name="user_2"
            size={props.size}
            iconSize={props.iconSize}
          />
        </Box>
      </Box>
    </Center>
  );
};
