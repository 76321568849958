import { useTheme, ScrollView, VStack, HStack, Box, Center, Text, View, Image } from '@gluestack-ui/themed-native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useCallback, useEffect, useState } from 'react';
import { StatusBar, Platform, TouchableOpacity } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
// @ts-expect-error TS(2307): Cannot find module '../../assets/logo-uMotion.png'... Remove this comment to see the full error message
import logoC from '../../assets/logo-C.png';
import { useAuth } from '~/hooks/useAuth';
import { phoneLoginSelector } from '~/slices/phoneLoginSlice';
import { useAppSelector } from '~/store';

import { LogoImageDefault } from './LogoImageDefault';
import { UmotionEmailLoginScreen } from './UmotionEmailLoginScreen';
import { UmotionPhoneLoginScreen } from './UmotionPhoneLoginScreen';

export const UmotionLoginModalScreen = () => {
  const [loginMethod, setLoginMethod] = useState(
    Platform.OS !== 'web' ? undefined : localStorage.getItem('LastLoginMethodStorageKey') || 'phone'
  );

  const theme = useTheme();
  const { handleLogin } = useAuth();
  const { isSmsSent } = useAppSelector(phoneLoginSelector);

  const onPhoneLoginPress = useCallback(() => {
    setLoginMethod('phone');
  }, []);

  const onEmailLoginPress = useCallback(() => {
    setLoginMethod('email');
  }, []);

  useEffect(() => {
    if (Platform.OS !== 'web') {
      const init = async () => {
        const lastLoginMethod = await AsyncStorage.getItem('LastLoginMethodStorageKey');
        if (lastLoginMethod === 'phone' || lastLoginMethod === 'email') {
          setLoginMethod(lastLoginMethod);
        } else {
          setLoginMethod('phone');
        }
      };

      init();
    }
  }, []);

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: theme?.dark ? 'black' : 'white',
      }}
    >
      <StatusBar barStyle={theme?.dark ? 'light-content' : 'dark-content'} />
      <ScrollView
        keyboardShouldPersistTaps="handled"
        flex={1}
        justifyContent={Platform.OS === 'web' ? 'center' : undefined}
      >
        <VStack py="xl" gap="xl">
          <Center>
            {Platform.OS === 'web' ? (
              <LogoImageDefault />
            ) : (
              <Image source={require('../../assets/logo-uMotion.png')} width="60" height="5xl" />
            )}
          </Center>

          <Center marginVertical="xs">
            <Box flex={1} maxWidth={360}>
              <Center>
                <VStack
                  gap="sm"
                  backgroundColor="#F6F6F6"
                  paddingHorizontal={Platform.OS === 'web' ? '4xl' : 'xl'}
                  paddingVertical={Platform.OS === 'web' ? 'xl' : '2xl'}
                >
                  {isSmsSent ? null : (
                    <HStack space={2} justifyContent="space-around">
                      <TouchableOpacity onPress={onPhoneLoginPress} style={{ width: '50%' }} testID="login-phone">
                        <View
                          style={{
                            borderBottomColor: '#005fff',
                            borderBottomWidth: loginMethod === 'phone' ? 2 : 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {Platform.OS === 'web' ? (
                            <Text
                              color={loginMethod === 'phone' ? '#171717' : '#9E9E9E'}
                              fontSize="md"
                              fontFamily="Noto Sans JP"
                            >
                              電話番号でログイン
                            </Text>
                          ) : (
                            <VStack alignItems="center" mb="sm">
                              <Text
                                color={loginMethod === 'phone' ? '#171717' : '#9E9E9E'}
                                fontSize="md"
                                fontFamily="Noto Sans JP"
                                lineHeight="2xs"
                              >
                                電話番号で
                              </Text>
                              <Text
                                color={loginMethod === 'phone' ? '#171717' : '#9E9E9E'}
                                fontSize="md"
                                fontFamily="Noto Sans JP"
                                lineHeight="2xs"
                              >
                                ログイン
                              </Text>
                            </VStack>
                          )}
                        </View>
                      </TouchableOpacity>
                      <TouchableOpacity onPress={onEmailLoginPress} style={{ width: '50%' }} testID="login-email">
                        <View
                          style={{
                            borderBottomColor: '#005fff',
                            borderBottomWidth: loginMethod === 'email' ? 2 : 0,
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {Platform.OS === 'web' ? (
                            <Text
                              color={loginMethod === 'email' ? '#171717' : '#9E9E9E'}
                              fontSize="md"
                              fontFamily="Noto Sans JP"
                            >
                              メールでログイン
                            </Text>
                          ) : (
                            <VStack alignItems="center" mb="sm">
                              <Text
                                color={loginMethod === 'email' ? '#171717' : '#9E9E9E'}
                                fontSize="md"
                                fontFamily="Noto Sans JP"
                                lineHeight="2xs"
                              >
                                メールアドレスで
                              </Text>
                              <Text
                                color={loginMethod === 'email' ? '#171717' : '#9E9E9E'}
                                fontSize="md"
                                fontFamily="Noto Sans JP"
                                lineHeight="2xs"
                              >
                                ログイン
                              </Text>
                            </VStack>
                          )}
                        </View>
                      </TouchableOpacity>
                    </HStack>
                  )}

                  {loginMethod === 'email' ? <UmotionEmailLoginScreen /> : <UmotionPhoneLoginScreen />}
                </VStack>
              </Center>
            </Box>
          </Center>
        </VStack>
        {isSmsSent ? null : (
          <VStack space={8} justifyItems="center" paddingX={4}>
            <Center>
              <TouchableOpacity
                onPress={handleLogin}
                style={{
                  borderWidth: 1,
                  borderRadius: 30,
                  borderColor: '#D4D4D4',
                }}
                testID="login-chumly"
              >
                <HStack
                  paddingVertical="sm"
                  alignItems="center"
                  paddingHorizontal={Platform.OS === 'web' ? undefined : '2xl'}
                >
                  <Image source={logoC} width="17px" height="21px" mr="36px" ml="17px" />
                  <Text color="#171717" fontSize="lg" mr="53px" fontFamily="Noto Sans JP">
                    Chumly IDでログイン
                  </Text>
                </HStack>
              </TouchableOpacity>
            </Center>
          </VStack>
        )}
      </ScrollView>
    </SafeAreaView>
  );
};
