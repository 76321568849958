import { HStack, VStack, Text, Box } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { Pressable, Platform } from 'react-native';
import { useStreamChatContext, useStreamChatAuthContext } from '~/contexts/StreamChatContext';

import type { StreamChatGenerics } from '~/types';
import { DateUtil } from '~/utils/DateUtils';
import { ChatCustomChannelAvatar } from './ChatCustomChannelAvatar';
import type { NativeStackNavigationProp } from '@react-navigation/native-stack';
import type { Channel } from 'stream-chat';

interface Props {
  channel: Channel<StreamChatGenerics>;
}

type RootStackParamList = {
  DMNavigator: {
    screen: string;
    params: { channelId?: string };
  };
  ChatChannelMessages: { channelId?: string };
};

type NavigationProps = NativeStackNavigationProp<RootStackParamList>;

export const ChannelSearchResultItem: React.FC<Props> = ({ channel }) => {
  const navigation = useNavigation<NavigationProps>();
  const { chatClient } = useStreamChatAuthContext();
  const { setAppChannel } = useStreamChatContext();
  const linkTo = useLinkTo();

  return (
    <Pressable
      onPress={() => {
        if (Platform.OS === 'web') {
          if (channel.data?.isDM) {
            linkTo(`/chat`);
            const newChannel = chatClient.channel('messaging', channel.id);
            setAppChannel(newChannel);
          } else {
            linkTo(`/chat/${channel.id}`);
          }
        } else {
          setAppChannel(channel);
          navigation.navigate('ChatChannelMessages', { channelId: channel.id });
        }
      }}
    >
      <VStack paddingVertical="xs" style={{ flex: 1 }} mb="md" px="sm" py="xs">
        <HStack>
          <VStack style={{ flex: 1 }}>
            <HStack>
              <Box>
                <ChatCustomChannelAvatar channel={channel} size={50} iconSize="5xl" />
              </Box>
              <VStack style={{ flex: 1 }} overflow="hidden" marginLeft="xs">
                <HStack alignItems="center" justifyContent="space-between">
                  <Text
                    fontSize="md"
                    fontWeight="bold"
                    color="onSurface"
                    ellipsizeMode="tail"
                    numberOfLines={1}
                    maxWidth="80%"
                  >
                    {channel.data?.name}({channel.data?.member_count})
                  </Text>
                  <Text fontSize="sm" color="onSurfaceHigh">
                    {channel.data?.last_message_at &&
                      DateUtil.toYYYYMMDDOrMMDD(new Date(channel.data?.last_message_at as string))}{' '}
                    {channel.data?.last_message_at && DateUtil.tohmm(new Date(channel.data?.last_message_at as string))}
                  </Text>
                </HStack>
                <Text fontSize="sm" color="onSurface">
                  {channel.state.messages.length > 0 && channel.state.messages[channel.state.messages.length - 1].text}
                </Text>
              </VStack>
            </HStack>
          </VStack>
        </HStack>
      </VStack>
    </Pressable>
  );
};
