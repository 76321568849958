import { useState } from 'react';

import { useGetCurrentUserQuery } from '~/api/uFeedApi';
import { useStreamChatContext } from '~/contexts/StreamChatContext';

interface Props {
  name: string;
  members: string[];
  chatTeamId: string;
  description?: string;
  isDM?: boolean;
  isSecret?: boolean;
  isPublic?: boolean;
}

export const useUserChannel = () => {
  const { chatClient } = useStreamChatContext();
  const [errorMessages, setErrorMessages] = useState<string[]>(['']);
  const currentUserQuery = useGetCurrentUserQuery({});

  const createUserChannel = async ({
    name,
    members,
    chatTeamId,
    description,
    isPublic = false,
    isSecret = false,
    isDM = false,
  }: Props) => {
    setErrorMessages((_) => []);
    const now = new Date();
    const channelId = `user-channel-${
      isPublic ? 'public' : isSecret ? 'secret' : isDM ? 'DM' : 'unknown'
    }-${currentUserQuery.data?.id}-${now.valueOf()}`;
    //現在の日付をID文字列にする

    const channel = chatClient?.channel(isPublic ? 'team' : 'messaging', channelId, {
      name,
      members,
      team: chatTeamId,
      channel_overview: description,
      isDM,
      isPublic,
      isSecret,
    });

    const response = await channel?.create().catch((e) => {
      console.error('channel create error', e);
      setErrorMessages([isDM ? 'DMを開始できませんでした' : 'ルームを開始できませんでした']);
      return {
        isError: true,
        message: e.message,
      };
    });

    return response;
  };

  const addMembersToChannel = async (channelId: string, memberChatIds: string[]) => {
    const channel = chatClient?.channel('team', channelId);
    return channel?.addMembers(memberChatIds);
  };

  return { createUserChannel, addMembersToChannel, errorMessages };
};
