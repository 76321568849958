import type { StreamChatGenerics } from '~/types';
import type { StreamChat } from 'stream-chat';

export const queryChannel = async (chatClient: StreamChat<StreamChatGenerics>, id: string) => {
  const channels = await chatClient
    ?.queryChannels(
      {
        id,
      },
      [],
      {
        watch: true,
        state: true,
        limit: 3,
      }
    )
    .catch((error) => console.error('query channel error: ', error));
  // @ts-expect-error TS(2532): Object is possibly 'undefined'.
  if (channels?.length <= 0) {
    return;
  }
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return channels[0];
};
