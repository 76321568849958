import { Image, Pressable, ScrollView, Text, View, VStack } from '@gluestack-ui/themed-native-base';
// @ts-expect-error
import calendar from '../../../u-feed-app/assets/app_icons/calendar.png';
// @ts-expect-error
import cow from '../../../u-feed-app/assets/app_icons/cow.png';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

interface ChatBotQuickActionsProps {
  onSendMessage: (messageText: string) => Promise<void>;
}

export const ChatBotQuickActions: React.FC<ChatBotQuickActionsProps> = ({ onSendMessage }) => {
  return (
    <VStack
      flex="1"
      backgroundColor="surfaceLow"
      alignItems="center"
      padding="5xl"
      space="sm"
      height="100%"
      width="100%"
    >
      <View
        backgroundColor="surfaceLow"
        width={20}
        height={20}
        marginTop="5xl"
        borderRadius={gluestackUIConfig.tokens.radii.full}
        borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderColor="outlineVariant"
        style={{
          shadowColor: '#000',
          shadowOffset: { width: 0, height: 1 },
          shadowOpacity: 0.12,
          shadowRadius: 5,
          elevation: 5,
        }}
      >
        <Image source={require('../../assets/app_icons/app_adaptive_icon_chumly.png')} alt="logo" />
      </View>
      <Text fontSize="xl" color="primary" textAlign="center" bold lineHeight="xl">
        こんにちは！{`\n`} お困りのことを質問してください
      </Text>
      <ScrollView padding="xs">
        <View
          backgroundColor="surfaceLow"
          padding="xs"
          horizontal
          flexWrap="wrap"
          flexDirection="row"
          justifyContent="center"
          maxWidth={600}
        >
          <Pressable
            onPress={() => onSendMessage('血統書から個体を登録したい')}
            backgroundColor="surfaceLow"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={cow} />
            <Text color="onSurface" fontSize="md">
              血統書から個体を登録したい
            </Text>
          </Pressable>
          <Pressable
            onPress={() => onSendMessage('センサー紐づけシートをスキャンして登録したい')}
            backgroundColor="surfaceLow"
            padding="xs"
            borderRadius={gluestackUIConfig.tokens.radii.lg}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
            marginRight="xs"
            marginBottom="xs"
            style={{
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.12,
              shadowRadius: 5,
              elevation: 5,
              width: 106,
              height: 122,
            }}
          >
            <Image width={28} height={28} source={calendar} />
            <Text color="onSurface" fontSize="md">
              センサー紐づけシートをスキャンして登録したい
            </Text>
          </Pressable>
        </View>
      </ScrollView>
    </VStack>
  );
};
