import { Ionicons } from '@expo/vector-icons';
import { HStack, Icon, Pressable, ScrollView, Text, VStack } from '@gluestack-ui/themed-native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { HistoryIcon } from '~/components/icons/HistoryIcon';
import type { StackNavigationProp } from '@react-navigation/stack';

export const ChatbotHistoryScreen: React.FC = () => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const route = useRoute();
  const appChannel = route.params;

  navigation?.setOptions({
    title: '',
    headerLeft: () => (
      <Text fontSize="md" bold lineHeight="3xs" color="onSurface">
        最近
      </Text>
    ),
    headerRight: () => (
      <Icon
        onPress={() => {
          navigation.goBack();
        }}
        as={Ionicons}
        name="close"
        size="lg"
        color="onSurfaceHigh"
      />
    ),
  });
  return (
    <VStack backgroundColor="white" padding="md" flex="1">
      <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false}>
        {// @ts-expect-error
        appChannel?.state?.messageSets[0].messages
          .slice()
          .reverse()
          // @ts-expect-error
          .map((message) => {
            return (
              <Pressable key={message.id}>
                <HStack space="xs" paddingVertical="3xs" marginBottom="sm" alignItems="center">
                  <HistoryIcon />
                  <Text color="onSurface" fontSize="md">
                    {message?.text?.replace(/^@chumly\s*/, '')}
                  </Text>
                </HStack>
              </Pressable>
            );
          })}
      </ScrollView>
    </VStack>
  );
};
