import { HStack, VStack, Pressable, Text, useToken } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { useCallback, useState } from 'react';
import { Platform, Switch } from 'react-native';
import {
  useGetPushConfigsExistQuery,
  useGetPushKeywordsExistQuery,
  usePatchPushConfigsUpdatesMutation,
  usePatchPushKeywordsUpdatesMutation,
  usePostPushConfigsMutation,
  usePostPushKeywordsMutation,
} from '~/api/uFeedApi';
import { ListItem } from '~/components';
import { BaseScreenBuilder } from '~/components/builder';
import type { FieldsProps } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useAuth } from '~/hooks';
import ArrowRightIcon from '~/icons/arrow_right.svg';
import type { SettingsStackParamList } from '~/routes/type';
import type { SettingsState } from '~/slices/settingsSlice';
import { settingsSelector, setEnableWebNotification, setEnablePushNotification } from '~/slices/settingsSlice';
import { useAppSelector, useAppDispatch } from '~/store';
import type { NavigationProp } from '@react-navigation/native';
import type { FC } from 'react';

export const SettingsNotificationScreen: FC = () => {
  const navigation = useNavigation<NavigationProp<SettingsStackParamList>>();
  const dispatch = useAppDispatch();
  const settings: SettingsState = useAppSelector(settingsSelector);
  const { isUmotionUser } = useAuth();

  const [primary, surfaceHigh, surfaceLow, outline] = useToken('colors', [
    'primary',
    'surfaceHigh',
    'surfaceLow',
    'outline',
  ]);

  const pushConfigData = useGetPushConfigsExistQuery();
  const [setPushConfig] = usePostPushConfigsMutation();
  const [patchPushConfig] = usePatchPushConfigsUpdatesMutation();
  const pushKeywordQuery = useGetPushKeywordsExistQuery();
  const [postKeyword] = usePostPushKeywordsMutation();
  const [pathPushKeyWord] = usePatchPushKeywordsUpdatesMutation();

  const [isMentionOnly, setIsMentionOnly] = useState(false);

  const handlePostKeyword = useCallback(
    async (sendingData: Record<string, string>) => {
      return postKeyword({ body: { keyword: sendingData['keyword'] } }).then(navigation.goBack);
    },
    [postKeyword, navigation]
  );

  const handleUpdateKeyword = useCallback(
    async (sendingData: Record<string, string>) => {
      return pathPushKeyWord({ body: { keyword: sendingData['keyword'] } }).then(navigation.goBack);
    },
    [pathPushKeyWord, navigation]
  );

  return (
    <ScreenWidthModal title="通知">
      <BaseScreenBuilder title="通知">
        <VStack gap="md">
          <ListItem
            title="メッセージ通知を受信する"
            right={
              <Switch
                value={settings.enablePushNotification}
                onValueChange={(value: boolean) => {
                  if (pushConfigData.isError) {
                    setPushConfig({ body: { notify_type: value ? 0 : 2 } });
                  } else {
                    patchPushConfig({ body: { notify_type: value ? 0 : 2 } });
                  }
                  dispatch(setEnablePushNotification(value));
                }}
                trackColor={{ true: primary, false: outline }}
                ios_backgroundColor={settings.enablePushNotification ? primary : surfaceLow}
                thumbColor={settings.enablePushNotification ? surfaceLow : surfaceHigh}
                {...Platform.select({
                  web: {
                    activeThumbColor: surfaceLow,
                  },
                })}
              />
            }
          />
          {settings.enablePushNotification && (
            <>
              <ListItem
                title="メンション(@)・DMのみ通知する"
                right={
                  <Switch
                    value={isMentionOnly}
                    onValueChange={(value: boolean) => {
                      setIsMentionOnly(value);
                      patchPushConfig({ body: { notify_type: value ? 1 : 0 } });
                    }}
                    trackColor={{ true: primary, false: outline }}
                    ios_backgroundColor={isMentionOnly ? primary : surfaceLow}
                    thumbColor={isMentionOnly ? surfaceLow : surfaceHigh}
                    {...Platform.select({
                      web: {
                        activeThumbColor: surfaceLow,
                      },
                    })}
                  />
                }
              />
              <Pressable
                disabled={isUmotionUser}
                onPress={() => {
                  const fields: FieldsProps = [
                    {
                      type: 'text',
                      key: 'keyword',
                      label: 'キーワード通知',
                      note: 'カンマ (,) で区切ることで複数キーワードを設定できます',
                    },
                  ];

                  navigation.navigate('SettingsForm', {
                    formBuilderProps: {
                      // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetCurren... Remove this comment to see the full error message
                      queryResult: pushKeywordQuery,
                      errorMessage: [],
                      fields,
                      onCancel: navigation.goBack,
                      onSubmit: pushKeywordQuery.data?.keyword ? handleUpdateKeyword : handlePostKeyword,
                    },
                  });
                }}
              >
                <HStack alignItems="center" justifyContent="space-between">
                  <VStack>
                    <Text color="onSurface" fontSize="sm" lineHeight="sm">
                      キーワード通知を追加
                    </Text>
                    <Text color="onSurfaceHigh" fontSize="md">
                      {pushKeywordQuery?.data?.keyword || '未設定'}
                    </Text>
                  </VStack>
                  {!isUmotionUser && (
                    <>
                      {Platform.OS === 'web' ? (
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g id="Icons">
                            <path
                              id="keyboard_arrow_right"
                              d="M10.8083 9.70443L7.55827 6.45443C7.40549 6.30165 7.3291 6.1072 7.3291 5.87109C7.3291 5.63498 7.40549 5.44054 7.55827 5.28776C7.71105 5.13498 7.90549 5.05859 8.1416 5.05859C8.37771 5.05859 8.57216 5.13498 8.72494 5.28776L12.5583 9.12109C12.6416 9.20443 12.7006 9.2947 12.7354 9.39193C12.7701 9.48915 12.7874 9.59332 12.7874 9.70443C12.7874 9.81554 12.7701 9.9197 12.7354 10.0169C12.7006 10.1141 12.6416 10.2044 12.5583 10.2878L8.72494 14.1211C8.57216 14.2739 8.37771 14.3503 8.1416 14.3503C7.90549 14.3503 7.71105 14.2739 7.55827 14.1211C7.40549 13.9683 7.3291 13.7739 7.3291 13.5378C7.3291 13.3016 7.40549 13.1072 7.55827 12.9544L10.8083 9.70443Z"
                              fill="#737373"
                            />
                          </g>
                        </svg>
                      ) : (
                        <ArrowRightIcon />
                      )}
                    </>
                  )}
                </HStack>
              </Pressable>
              {Platform.OS === 'web' && (
                <ListItem
                  title="ウェブブラウザで通知を受信する(ブラウザ・OSの許可が必要です)"
                  right={
                    <Switch
                      value={settings.enableWebNotification}
                      onValueChange={(value: boolean) => {
                        dispatch(setEnableWebNotification(value));
                      }}
                      trackColor={{ true: primary, false: outline }}
                      ios_backgroundColor={settings.enableWebNotification ? primary : surfaceLow}
                      thumbColor={settings.enableWebNotification ? surfaceLow : surfaceHigh}
                      {...Platform.select({
                        web: {
                          activeThumbColor: surfaceLow,
                        },
                      })}
                    />
                  }
                />
              )}
            </>
          )}
        </VStack>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
