import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { UserSetting } from '~/api/uFeedApi';
import { useGetCurrentUserBookmarksQuery } from '~/api/uFeedApi';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import type { StreamChatGenerics } from '~/types';
import type { MessageResponse, EventHandler } from 'stream-chat';

type Bookmark = Exclude<Exclude<UserSetting['details'], undefined>['bookmarks'], undefined>[number];
type BookmarkChatMessage = Bookmark & {
  type: 'chat-message';
};

const isSameBookmark = (a: Bookmark, b: Bookmark) => {
  return a.id === b.id && a.created_at === b.created_at;
};

export const useBookmarkQuery = () => {
  const bookmarks = useGetCurrentUserBookmarksQuery();
  const [messages, setMessages] = useState<{ message: MessageResponse; bookmark_created_at?: string }[]>([]);
  const [initialized, setInitialized] = useState(false);
  const { chatClient, chatUserId, isUserConnected } = useStreamChatAuthContext();
  const offsetRef = useRef(0);
  const limit = 50;
  const bookmarkType = 'chat-message';
  const bookmarksRef = useRef<BookmarkChatMessage[]>([]);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);

  const fetchBookmarkMessages = useCallback(
    async (targetBookmarks: BookmarkChatMessage[]) => {
      if (targetBookmarks.length === 0) return [];
      if (!chatUserId) {
        throw Error('not authenticated');
      }

      const channelFilters = {
        members: {
          $in: [chatUserId],
        },
      };
      const messageFilters = {
        id: {
          $in: targetBookmarks
            .filter((b): b is BookmarkChatMessage & { id: Exclude<BookmarkChatMessage['id'], undefined> } => !!b.id)
            .map((b) => b.id),
        },
      };

      return chatClient
        ?.search(channelFilters, messageFilters)
        .then((resp) => resp?.results ?? [])
        .then((results) => {
          if (results.length === 0) {
            return [];
          }
          const bookmarkedMessages = targetBookmarks
            .map((bookmark) => {
              const message = results.find((result) => result.message.id === bookmark.id);
              return message ? { ...message, bookmark_created_at: bookmark.created_at } : undefined;
            })
            .filter((msg): msg is Exclude<typeof msg, undefined> => !!msg);
          return bookmarkedMessages;
        })
        .catch((error) => {
          console.error('message search error: ', error);
          return [];
        });
    },

    [chatClient, chatUserId]
  );

  const bookmarkQuery = useCallback(async () => {
    if (isLoadingMore) return;
    const targetBookmarks = bookmarksRef.current.slice(offsetRef.current, offsetRef.current + limit);
    if (targetBookmarks.length === 0) return;
    setIsLoadingMore(true);

    offsetRef.current = offsetRef.current + targetBookmarks.length;
    const addedMessages = await fetchBookmarkMessages(targetBookmarks);
    setMessages((messages) => [...messages, ...addedMessages]);
    setInitialized(true);
    setIsLoadingMore(false);
  }, [chatUserId, fetchBookmarkMessages, isLoadingMore]);

  const handleAddedBookmark = useCallback(
    async (addedBookmarks: BookmarkChatMessage[]) => {
      const addedMessages = await fetchBookmarkMessages(addedBookmarks);
      setMessages((messages) => [...addedMessages, ...messages]);
    },
    [chatUserId, fetchBookmarkMessages]
  );

  const handleRemovedBookmark = useCallback((removedBookmarks: Bookmark[]) => {
    setMessages((messages) => {
      return messages.filter(
        (message) =>
          removedBookmarks.findIndex(
            (b) => b.id === message.message.id && b.created_at === message.bookmark_created_at
          ) < 0
      );
    });
  }, []);
  useEffect(() => {
    const handleMessageDeleted = (event: { message: MessageResponse }) => {
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.message.id !== event.message.id));
    };
    chatClient.on('message.deleted', handleMessageDeleted as EventHandler<StreamChatGenerics>);

    return () => {
      chatClient.off('message.deleted', handleMessageDeleted as EventHandler<StreamChatGenerics>);
    };
  }, [chatClient]);

  useDidUpdate(
    () => {
      if (!isUserConnected) return;
      // 新しいデータがきた or マウントされた
      const newBookmarks = [...(bookmarks.data ?? [])]
        .filter((b) => b.bookmark_type === bookmarkType)
        .sort((a, b) => b.created_at - a.created_at)
        .map((b) => {
          return {
            id: b.message_id,
            created_at: b.created_at,
            type: b.bookmark_type,
          };
        });

      const addedBookmarks = newBookmarks.filter(
        // @ts-expect-error TS(2345): Argument of type '{ id: string; created_at: number... Remove this comment to see the full error message
        (n) => bookmarksRef.current.findIndex((b) => isSameBookmark(n, b)) < 0
      );
      const removedBookmarks = bookmarksRef.current.filter(
        // @ts-expect-error TS(2345): Argument of type '{ id: string; created_at: number... Remove this comment to see the full error message
        (b) => newBookmarks.findIndex((n) => isSameBookmark(n, b)) < 0
      );

      // @ts-expect-error TS(2322): Type '{ id: string; created_at: number; type: stri... Remove this comment to see the full error message
      bookmarksRef.current = newBookmarks;
      if (addedBookmarks.length === newBookmarks.length) {
        setInitialized(false);
        // 全件入れ替え → 初期化
        offsetRef.current = 0;
        const targetBookmarks = addedBookmarks.slice(0, limit);
        // @ts-expect-error TS(2345): Argument of type '{ id: string; created_at: number... Remove this comment to see the full error message
        handleAddedBookmark(targetBookmarks).then(() => {
          offsetRef.current = offsetRef.current + targetBookmarks.length;
          setIsLoadingMore(false);
          setInitialized(true);
        });
        return;
      }
      offsetRef.current = offsetRef.current + addedBookmarks.length - removedBookmarks.length;
      // @ts-expect-error TS(2345): Argument of type '{ id: string; created_at: number... Remove this comment to see the full error message
      if (addedBookmarks.length > 0) handleAddedBookmark(addedBookmarks);
      if (removedBookmarks.length > 0) handleRemovedBookmark(removedBookmarks);
    },
    [bookmarks.data, handleAddedBookmark, handleRemovedBookmark, bookmarkQuery, isUserConnected],
    true
  );

  return {
    initialized,
    messages,
    bookmarkQuery,
  };
};
