import { Avatar, HStack, Pressable, ScrollView, Stack, Text, VStack } from '@gluestack-ui/themed-native-base';
import { useState } from 'react';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useDashboardColumns } from '~/hooks/useDashboardColumns';
import CloseIcons from '~/icons/CloseIcons';
import CreateColumnIcons from '~/icons/CreateColumnIcons';
import HomeIcons from '~/icons/HomeIcons';
import { useAllChannel } from './useAllChannel';

export const AddChannelColumn = () => {
  const [isOpenChoose, setIsOpenChoose] = useState<boolean>(false);

  const { allChannels } = useAllChannel();

  const { insertColumn, columnOptions, columns } = useDashboardColumns();

  return (
    <HStack height="100%" flexGrow={0}>
      {!isOpenChoose ? (
        <VStack justifyContent="center" height="100%" backgroundColor="surfaceHighest">
          <Pressable onPress={() => setIsOpenChoose(!isOpenChoose)}>
            <HStack px="sm" gap="2xs" alignItems="center">
              <CreateColumnIcons />
              <Text fontSize="md" fontWeight="bold" color="primary">
                列を追加
              </Text>
            </HStack>
          </Pressable>
        </VStack>
      ) : (
        <VStack height="100%" backgroundColor="surfaceLow">
          <HStack
            px="md"
            py="xl"
            alignItems="center"
            justifyContent="space-between"
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
          >
            <Text fontSize="xl" fontWeight="bold" color="onSurface">
              列を追加
            </Text>
            <Pressable onPress={() => setIsOpenChoose(!isOpenChoose)}>
              <CloseIcons />
            </Pressable>
          </HStack>
          <ScrollView flex="1">
            <VStack p="md">
              <Stack py="2xs">
                <Text fontSize="md" fontWeight="regular" color="onSurface">
                  1つ選択してください
                </Text>
              </Stack>
              {columnOptions
                .filter((column) => !columns.find((dashboardColumn) => dashboardColumn.type === column.type))
                .map((column) => {
                  return (
                    <Pressable
                      onPress={() => {
                        insertColumn(columns.length, column);
                        setIsOpenChoose(!isOpenChoose);
                      }}
                      key={column.type}
                    >
                      <HStack py="xs" gap="xs" alignItems="center">
                        <Avatar source={{ uri: undefined }} height="30" width="30" backgroundColor="onSurfaceHighest" />

                        <HStack gap="2xs" alignItems="center">
                          <Text fontSize="md" fontWeight="bold" color="onSurface">
                            {column.title}
                          </Text>
                        </HStack>
                      </HStack>
                    </Pressable>
                  );
                })}
              {allChannels
                ?.filter((channel) => !columns.find((column) => column.chatChannelId === channel.cid))
                .map((channel) => (
                  <Pressable
                    onPress={() => {
                      setIsOpenChoose(!isOpenChoose);
                      insertColumn(columns.length, {
                        type: 'chat_channel',
                        title: channel.data?.name || '',
                        chatChannelId: channel.cid,
                      });
                    }}
                    key={channel.cid}
                  >
                    <HStack py="xs" gap="xs" alignItems="center">
                      <Avatar
                        // @ts-expect-error TS(2239)
                        source={{ uri: channel?.data?.created_by?.image }}
                        height="30"
                        width="30"
                        backgroundColor="onSurfaceHighest"
                      />
                      <HStack gap="2xs" alignItems="center">
                        {!channel?.data?.isDM && (
                          <Stack>
                            <HomeIcons />
                          </Stack>
                        )}

                        <Text fontSize="md" fontWeight="bold" color="onSurface">
                          {channel.data?.name}
                        </Text>
                      </HStack>
                    </HStack>
                  </Pressable>
                ))}
            </VStack>
          </ScrollView>
        </VStack>
      )}
    </HStack>
  );
};
