import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';

export const resizeImage = async (
  uri: string,
  width: number,
  height: number,
  maxShortSide: number = 1080,
  saveFormat: SaveFormat = SaveFormat.JPEG,
  quality: number = 0.8
) => {
  // リサイズ条件
  const aspectRatio = width / height;
  let targetWidth = width;
  let targetHeight = height;

  if (Math.min(width, height) > maxShortSide) {
    if (width < height) {
      targetWidth = maxShortSide;
      targetHeight = Math.round(maxShortSide / aspectRatio);
    } else {
      targetHeight = maxShortSide;
      targetWidth = Math.round(maxShortSide * aspectRatio);
    }
  }

  // リサイズとフォーマット変換
  const manipResult = await manipulateAsync(uri, [{ resize: { width: targetWidth, height: targetHeight } }], {
    compress: quality,
    format: saveFormat,
  });

  return manipResult;
};
