import { Alert, Button, Center, Text, VStack } from 'native-base';
import React from 'react';

export const ErrorDialog = ({ text, onCancel }: { text: string; onCancel?: () => void }) => {
  return (
    <Center flex={1}>
      <VStack space={4}>
        <Alert status="error" colorScheme="error">
          <Alert.Icon />
          <Text textAlign="center">{text}</Text>
        </Alert>
        <Button variant="ghost" onPress={onCancel}>
          キャンセル
        </Button>
      </VStack>
    </Center>
  );
};
