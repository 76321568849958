import { HStack } from '@gluestack-ui/themed-native-base';
import { differenceInWeeks } from 'date-fns';
import React, { useMemo } from 'react';

import type { DefaultStreamChatGenerics } from '~/lib/StreamChatReact';
import { useMessageContext, isDate } from '~/lib/StreamChatReact';
import type { MessageTimestampProps } from 'stream-chat-react';

const UnMemoizedMessageTimestamp = <StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics>(
  props: MessageTimestampProps<StreamChatGenerics>
) => {
  const { message: propMessage } = props;
  const { message: contextMessage } = useMessageContext<StreamChatGenerics>('MessageTimestamp');
  const { isMyMessage } = useMessageContext<StreamChatGenerics>('MessageStatus');
  const message = propMessage || contextMessage;

  if (!message.created_at) {
    return;
  }

  const messageCreatedAtLocale = isDate(message.created_at) ? message.created_at.toLocaleString() : message.created_at;
  const messageCreatedAtLocaleWidthoutSeconds = isDate(message.created_at)
    ? message.created_at.toLocaleString('ja-JP', {
        hour: '2-digit',
        minute: '2-digit',
      })
    : message.created_at;

  const isWeekAgo = useMemo(() => {
    if (!message.created_at) return false;
    const today = new Date();
    const created = isDate(message.created_at) ? message.created_at : new Date(message.created_at);
    return !!differenceInWeeks(today, created);
  }, [message.created_at]);

  return (
    <HStack justifyContent={isMyMessage() ? 'flex-end' : 'flex-start'} title={messageCreatedAtLocale}>
      <time className="str-chat__message-simple-timestamp">{messageCreatedAtLocaleWidthoutSeconds}</time>
    </HStack>
  );
};

export const CustomMessageTimestamp = React.memo(UnMemoizedMessageTimestamp) as typeof UnMemoizedMessageTimestamp;
