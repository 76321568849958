import { Ionicons } from '@expo/vector-icons';
import { HStack, VStack, Text, Pressable, Icon } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { Platform } from 'react-native';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import ArrowRightIcon from '~/icons/arrow_right.svg';
import LanguageJapaneseKanaIcon from '~/icons/language_japanese_kana.svg';
import LockIcon from '~/icons/lock.svg';
import NotificationsIcon from '~/icons/notifications.svg';
import ProfileIcon from '~/icons/profile.svg';
import type { SettingsStackParamList } from '~/routes/type';
import type { StackNavigationProp } from '@react-navigation/stack';
import type { FC } from 'react';

export const SettingsTopScreen: FC = () => {
  const navigation = useNavigation<StackNavigationProp<SettingsStackParamList>>();

  return (
    <BaseScreenBuilder title="設定" padding0 noScroll flex={1}>
      <VStack px="md">
        <Pressable onPress={() => navigation.navigate('SettingsProfile')}>
          <HStack
            alignItems="center"
            justifyContent="space-between"
            py="md"
            borderBottomWidth="medium"
            borderColor="outlineVariant"
          >
            <HStack alignItems="center" gap="xs">
              <ProfileIcon />
              <Text color="onSurface" fontSize="md">
                プロフィール
              </Text>
            </HStack>
            <ArrowRightIcon />
          </HStack>
        </Pressable>
        <Pressable onPress={() => navigation.navigate('SettingsAccount')}>
          <HStack
            alignItems="center"
            justifyContent="space-between"
            py="md"
            borderBottomWidth="medium"
            borderColor="outlineVariant"
          >
            <HStack alignItems="center" gap="xs">
              <LockIcon />
              <Text color="onSurface" fontSize="md">
                アカウント
              </Text>
            </HStack>
            <ArrowRightIcon />
          </HStack>
        </Pressable>
        <Pressable onPress={() => navigation.navigate('SettingsNotification')}>
          <HStack
            alignItems="center"
            justifyContent="space-between"
            py="md"
            borderBottomWidth="medium"
            borderColor="outlineVariant"
          >
            <HStack alignItems="center" gap="xs">
              <NotificationsIcon />
              <Text color="onSurface" fontSize="md">
                通知
              </Text>
            </HStack>
            <ArrowRightIcon />
          </HStack>
        </Pressable>
        <Pressable onPress={() => navigation.navigate('SettingsKanasapo')}>
          <HStack
            alignItems="center"
            justifyContent="space-between"
            py="md"
            borderBottomWidth="medium"
            borderColor="outlineVariant"
          >
            <HStack alignItems="center" gap="xs">
              <LanguageJapaneseKanaIcon />
              <Text color="onSurface" fontSize="md">
                かなサポ
              </Text>
            </HStack>
            <ArrowRightIcon />
          </HStack>
        </Pressable>
        {Platform.OS === 'web' && (
          <Pressable onPress={() => navigation.navigate('SettingsOther')}>
            <HStack
              alignItems="center"
              justifyContent="space-between"
              py="md"
              borderBottomWidth="medium"
              borderColor="outlineVariant"
            >
              <HStack alignItems="center" gap="xs">
                <Icon
                  as={Ionicons}
                  name="ellipsis-horizontal-outline"
                  alignSelf="center"
                  color="onSurfaceHigh"
                  size="sm"
                  bold
                />
                <Text color="onSurface" fontSize="md">
                  その他
                </Text>
              </HStack>
              <ArrowRightIcon />
            </HStack>
          </Pressable>
        )}
      </VStack>
    </BaseScreenBuilder>
  );
};
