import { Feather, Ionicons } from '@expo/vector-icons';
import { Box, HStack, Icon, Pressable, ScrollView, Text, VStack } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import * as FileSystem from 'expo-file-system';
import * as ImagePicker from 'expo-image-picker';
import { Toast } from 'native-base';
import { useGetCurrentUserSettingQuery } from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import * as Sentry from '~/lib/Sentry';
import type { StackNavigationProp } from '@react-navigation/stack';

export const BackgroundColorSettingScreen: React.FC = () => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const { appChannel, chatUserId } = useStreamChatContext();
  const colors = [
    '#FFFFFF',
    '#F9CFC4',
    '#D9B99A',
    '#F3E6C6',
    '#C6DCE7',
    '#A7BD8F',
    '#BED2E9',
    '#F9CD9B',
    '#EFDEF2',
    '#CEECCD',
    '#E6B3B3',
    '#F5E8A1',
  ];

  const userSetting = useGetCurrentUserSettingQuery();
  const userChatBackground = userSetting.data?.details?.channel_background ?? {};
  //@ts-expect-error
  const chatColor = userChatBackground[appChannel?.id as string] || '#FFF';

  const pickImage = async () => {
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      quality: 1,
    });

    if (!result.canceled && result.assets && result.assets.length > 0) {
      const { uri } = result.assets[0];

      const channelId = appChannel?.id ?? '';
      const uniqueLocalUri = `${FileSystem.documentDirectory}/${channelId}-background-${Date.now()}.jpg`;

      try {
        if (FileSystem.documentDirectory !== null) {
          const files = await FileSystem.readDirectoryAsync(FileSystem.documentDirectory);

          const deletePromises = files
            .filter((file) => file.includes(`${channelId}-background`))
            .map((file) => FileSystem.deleteAsync(`${FileSystem.documentDirectory}${file}`));

          await Promise.all(deletePromises).catch((error) => {
            Sentry.captureException({
              message: 'Error deleting existing background images',
              error,
            });
          });

          await FileSystem.copyAsync({ from: uri, to: uniqueLocalUri }).catch((error) => {
            Sentry.captureException({
              message: 'Error copying background image',
              path: uniqueLocalUri,
              error,
            });
            Toast.show({
              placement: 'top',
              description: '背景画像のコピーに失敗しました',
            });
            throw error;
          });

          navigation.navigate('BackgroundChannelPreview', { localImageUri: uniqueLocalUri });
        }
      } catch (error) {
        console.error('Error saving image:', error);
      }
    }
  };

  navigation?.setOptions({
    title: '',
    headerLeft: () => (
      <HStack alignItems="center" gap="2xs">
        <Icon
          onPress={() => {
            navigation.goBack();
          }}
          as={Ionicons}
          name="chevron-back"
          size="2xl"
          color="onSurfaceHigh"
        />
        <Text fontSize="xl" fontWeight="bold" color="onSurface">
          背景デザイン
        </Text>
      </HStack>
    ),
    headerRight: () => (
      <Icon
        onPress={() => {
          navigation.goBack();
        }}
        as={Ionicons}
        name="close"
        size="xl"
        color="onSurfaceHigh"
      />
    ),
  });

  return (
    <VStack backgroundColor="white" flex="1">
      <HStack p="md" gap="sm">
        <VStack alignItems="center">
          <Box
            height={70}
            width={70}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor={chatColor}
            justifyContent="center"
            alignItems="center"
            backgroundColor={chatColor}
            borderRadius="md"
            onPress={() => navigation.navigate('BackgroundImageSetting')}
          />
          <Text fontSize="sm" fontWeight="regular" color="onSurface">
            カラー
          </Text>
        </VStack>
        <VStack alignItems="center">
          <Pressable
            height={70}
            width={70}
            justifyContent="center"
            alignItems="center"
            backgroundColor="surfaceHighest"
            borderRadius="md"
            onPress={pickImage}
          >
            <Icon as={<Ionicons name="images-sharp" />} color="onSurfaceHigh" width={8} height={8} fontSize={32} />
          </Pressable>
          <Text fontSize="sm" fontWeight="regular" color="onSurface">
            自分の写真
          </Text>
        </VStack>
      </HStack>

      <ScrollView flex={1}>
        <Box flexDirection="row" flexWrap="wrap" gap="3xs">
          {colors.map((colorI, index) => (
            <Pressable
              key={colorI}
              width="32.9%"
              height={170}
              alignItems="flex-end"
              backgroundColor={colorI}
              borderWidth={chatColor === colorI ? gluestackUIConfig.tokens.borderWidths.thick : 0}
              borderColor="primary"
              onPress={() => {
                navigation.navigate('BackgroundChannelPreview', { colorI });
              }}
            >
              {chatColor === colorI && (
                <Box m="2xs" backgroundColor="primary" borderRadius={gluestackUIConfig.tokens.borderRadius.full}>
                  <Icon
                    onPress={() => {
                      navigation.goBack();
                    }}
                    as={Feather}
                    name="check"
                    size="sm"
                    color="onPrimary"
                  />
                </Box>
              )}
            </Pressable>
          ))}
        </Box>
      </ScrollView>
    </VStack>
  );
};
