import { VStack, HStack, Button, Heading, Divider } from '@gluestack-ui/themed-native-base';
import * as React from 'react';
import { useController, useFieldArray } from 'react-hook-form';

import { ListItem } from '../ListItem';

import { FormFarmDetail } from './FormFarmDetail';
import type { Logic } from 'native-base-form-builder';

export const FormFarmDetails: React.FC<React.ComponentProps<typeof Logic>> = ({
  label,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  options,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'details',
  });

  return (
    <VStack space="xs" flex={1} zIndex={1000}>
      <Heading fontSize="xl" alignItems="center" color="onSurface">
        詳細
      </Heading>
      <ListItem
        key="add-button"
        title="詳細を追加"
        leftIconName="add-circle"
        onPress={() => append({ label: '', phone_number: '', address: '' })}
        link
      />
      {fields.map((field, index) => (
        <>
          <Divider bgColor="outline" />
          <VStack space="xs">
            <HStack alignItems="center" justifyContent="space-between" space="xs">
              <Heading fontSize="md" alignItems="center" color="onSurface">
                詳細{index + 1}
              </Heading>
              <Button
                variant="link"
                onPress={() => {
                  remove(index);
                }}
                padding="2xs"
                size="lg"
                testID={`farm-detail-delete-${index}`}
                _text={{
                  color: 'primary',
                  fontWeight: 'bold',
                }}
              >
                削除
              </Button>
            </HStack>
            <FormFarmDetail
              key={field.id}
              control={control}
              index={index}
              update={update}
              field={field}
              formState={formState}
            />
          </VStack>
        </>
      ))}
    </VStack>
  );
};
