import { Box, HStack, Text } from '@gluestack-ui/themed-native-base';
import { forwardRef } from 'react';
import { Pressable } from 'react-native';

import { AvatarWithOnline } from '../AvatarWithOnline';
import type { CustomSuggestionItemProps } from './types';

export const CustomSuggestionItem = forwardRef((props: CustomSuggestionItemProps, ref: React.Ref<HTMLDivElement>) => {
  const { suggestionType, item, onHoverIn, onHoverOut, onPress, selected } = props;
  switch (suggestionType) {
    case 'mention':
      return (
        <Box ref={ref}>
          <Pressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} onPress={onPress}>
            <HStack space="xs" padding="xs" alignItems="center" backgroundColor={selected && 'selectedSurface'}>
              <AvatarWithOnline userId={item.id} name={item.name || ''} online={item.isOnline} size={8} iconSize={8} />
              <Text fontSize="md" bold color="onSurface">
                {item.name}
              </Text>
            </HStack>
          </Pressable>
        </Box>
      );
    case 'emoji':
      return (
        <Box ref={ref}>
          <Pressable onHoverIn={onHoverIn} onHoverOut={onHoverOut} onPress={onPress}>
            <HStack space="xs" padding="xs" alignItems="center" backgroundColor={selected && 'selectedSurface'}>
              <Box h="2xl" w="2xl" alignItems="center" justifyContent="center">
                <Text fontSize="2xl">{item.skins?.[0].native}</Text>
              </Box>
              <Text fontSize="md" bold paddingX={2} color="onSurface">
                {`:${item.id}:`}
              </Text>
            </HStack>
          </Pressable>
        </Box>
      );
    default:
      return null;
  }
});
