import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import type { IconProps } from 'stream-chat-react/dist/types/types';

const ThreadIcons: React.FC<IconProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="12" height="8" viewBox="0 0 12 8" fill="none">
      <path
        d="M4.66667 8H11.3333C11.7 8 12 7.7 12 7.33333C12 6.96667 11.7 6.66667 11.3333 6.66667H4.66667C4.3 6.66667 4 6.96667 4 7.33333C4 7.7 4.3 8 4.66667 8ZM0 0.666667C0 1.03333 0.3 1.33333 0.666667 1.33333H11.3333C11.7 1.33333 12 1.03333 12 0.666667C12 0.3 11.7 0 11.3333 0H0.666667C0.3 0 0 0.3 0 0.666667ZM4.66667 4.66667H11.3333C11.7 4.66667 12 4.36667 12 4C12 3.63333 11.7 3.33333 11.3333 3.33333H4.66667C4.3 3.33333 4 3.63333 4 4C4 4.36667 4.3 4.66667 4.66667 4.66667Z"
        fill={gluestackUIConfig.tokens.colors.onSurfaceHigh}
      />
    </svg>
  );
};

export default ThreadIcons;
