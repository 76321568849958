import { U_FEED_URL_BASE } from '@env';
import clsx from 'clsx';
import type { ComponentProps } from 'react';

export const anchorComponent = ({ children, href, ...props }: ComponentProps<'a'>) => {
  const isEmail = href?.startsWith('mailto:');
  const isUrl = href?.startsWith('http');

  if (!href || (!isEmail && !isUrl)) return <>{children}</>;

  const isChumlyUrl = href.match(new RegExp(`${U_FEED_URL_BASE}/chat/`));

  return (
    <a
      className={clsx({ 'str-chat__message-url-link': isUrl })}
      href={href}
      rel="nofollow noreferrer noopener"
      //target={isChumlyUrl ? '_self' : '_blank'}
      target="_self"
      {...props}
    >
      {children}
    </a>
  );
};
