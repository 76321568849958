import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { VStack, Input, Box } from '@gluestack-ui/themed-native-base';
import { useState } from 'react';
import { useController } from 'react-hook-form';
import { TouchableOpacity } from 'react-native';

import { AlertBox } from '~/components/builder/AlertBox';
import { FormFieldLabel } from '~/components/builder/FormFieldLabel';
import { Icon } from '~/components/builder/Icon';
import type { Logic } from 'native-base-form-builder';

export const FormRemoveFileIds: React.FC<React.ComponentProps<typeof Logic>> = ({
  label,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  options,
  isRequired,
}) => {
  const { field } = useController({
    name,
    shouldUnregister,
    defaultValue,
    control,
  });
  const [fileUploadErrorMessage, setFileUplaodErrorMessage] = useState('');
  const [files, setFiles] = useState(options || []);
  const [removeFiles, setRemoveFiles] = useState([]);

  const onRemoveFilePress = (removeFile: any) => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ label: str... Remove this comment to see the full error message
    setFiles(files.filter((file) => file.id !== removeFile.id));
    // @ts-expect-error TS(2322): Type 'any' is not assignable to type 'never'.
    setRemoveFiles([...removeFiles, removeFile]);
    field.onChange([...removeFiles, removeFile].map((file) => file.id));
  };

  const onUnRemoveFilePress = (unRemoveFile: any) => {
    setFiles([...files, unRemoveFile]);
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    setRemoveFiles(removeFiles.filter((file) => file.id !== unRemoveFile.id));
    field.onChange([...files, unRemoveFile].map((file) => file.id));
  };

  useDidUpdate(() => {
    setFiles(options || []);
  }, [options]);

  return (
    <VStack space="xs" flex={1}>
      <FormFieldLabel label={label} isRequired={isRequired} />
      {removeFiles.map((file) => (
        <Input
          variant="outline"
          size="lg"
          borderColor="black"
          // @ts-expect-error TS(2339): Property 'url' does not exist on type 'never'.
          value={`削除: ${file?.url}`}
          editable={false}
          paddingX="sm"
          paddingY={3}
          InputLeftElement={
            <Box padding="xs">
              <Icon name="trash-outline" />
            </Box>
          }
          InputRightElement={
            <TouchableOpacity onPress={() => onUnRemoveFilePress(file)}>
              <Box padding="xs">
                <Icon name="close-outline" />
              </Box>
            </TouchableOpacity>
          }
        />
      ))}

      {files.map((file) => (
        <Input
          variant="outline"
          size="lg"
          borderColor="black"
          // @ts-expect-error TS(2339): Property 'url' does not exist on type '{ label: st... Remove this comment to see the full error message
          value={file?.url}
          editable={false}
          paddingX="sm"
          paddingY={3}
          InputLeftElement={
            <Box padding="xs">
              <Icon name="document-outline" />
            </Box>
          }
          InputRightElement={
            <TouchableOpacity onPress={() => onRemoveFilePress(file)}>
              <Box padding="xs">
                <Icon name="trash-outline" />
              </Box>
            </TouchableOpacity>
          }
        />
      ))}
      {fileUploadErrorMessage ? <AlertBox type="error" message={fileUploadErrorMessage} /> : null}
    </VStack>
  );
};
