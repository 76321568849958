import { ScrollView, FormControl, VStack, TextArea } from '@gluestack-ui/themed-native-base';
import { useController } from 'react-hook-form';

import { FormFieldLabel } from './FormFieldLabel';
import type { Logic } from 'native-base-form-builder';

export const FormTextArea: React.FC<React.ComponentProps<typeof Logic>> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  label,
  textInputProps,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  const isRequired = !!rules?.required;
  const errorMessage = formState.errors?.[field.name]?.message;

  return (
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <VStack>
        <FormFieldLabel label={label} isRequired={isRequired} />
        <TextArea
          value={field.value}
          // @ts-expect-error TS(2339): Property 'value' does not exist on type 'number'.
          onChange={(e) => {
            if (e.currentTarget.value === undefined) {
              return;
            }
            field.onChange(e.currentTarget.value);
          }}
          // @ts-expect-error
          onChangeText={(text) => field.onChange(text)}
          size="md"
          variant="outline"
          borderColor="outline"
          py="2xs"
          testID={textInputProps?.testID}
          _input={{
            color: 'onSurface',
            props: {
              //FIXMEplaceholderTextColor: 'onSurfaceHigh',
            },
          }}
          _focus={{
            borderColor: 'primary',
            backgroundColor: 'surfaceLow',
          }}
          {...textInputProps}
        />
        {errorMessage && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </ScrollView>
        )}
      </VStack>
    </FormControl>
  );
};
