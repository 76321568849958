import { Box } from '@gluestack-ui/themed-native-base';
import { useEffect } from 'react';
import { FlatList } from 'react-native';
import { useChannelMediaList } from '~/hooks/useChannelMediaList';
import ChannelInfoMediaContent from './Content';
import type { Media } from '../Media';
import type { Channel } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

type Props = {
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
};

const PER_PAGE = 10;

export const ThumbnailScrollView: React.FC<Props> = ({ appChannel }) => {
  const { mediaList, fetchMedia, offset, setOffset, setMediaList } = useChannelMediaList(appChannel);

  useEffect(() => {
    setOffset(0);
    setMediaList([]);
    fetchMedia(0);
  }, [appChannel]);

  return (
    <FlatList
      horizontal
      data={mediaList}
      keyExtractor={(item: Media) => item.id}
      renderItem={({ item }: { item: Media }) =>
        item.url ? (
          <Box width="20" height="20">
            <ChannelInfoMediaContent item={item} appChannel={appChannel} />
          </Box>
        ) : (
          <Box width="20" height="20" backgroundColor="onSurfaceHighest" />
        )
      }
      ItemSeparatorComponent={() => <Box width="1" />}
      onEndReached={() => {
        if (mediaList.length % PER_PAGE !== 0) return;
        const nextOffset = offset + 1;
        setOffset(nextOffset);
        fetchMedia(nextOffset);
      }}
    />
  );
};
