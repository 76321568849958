import { useState, useCallback } from 'react';
import { ChannelNewMember } from './AddNewMember';
import ChannelBackgroundEdit from './BackgroundEdit';
import ChannelInfoEdit from './EditChannel';
import ChannelInfoHome from './Home';
import ChannelInfoMedia from './Media';
import type { FC } from 'react';
import type { Channel } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

export const MODE_ENUM = {
  HOME: 'home',
  EDIT_CHANNEL: 'edit_channel',
  MEDIA: 'media',
  BACKGROUND: 'background',
  MEMBERS: 'members',
};

export type MODES = (typeof MODE_ENUM)[keyof typeof MODE_ENUM];

const ChannelInfo: FC<{
  open?: boolean;
  onClose: (() => void) | undefined;
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
}> = ({ open, appChannel, onClose }) => {
  const [mode, setMode] = useState<MODES>(MODE_ENUM.HOME);

  const handleClose = useCallback(() => {
    onClose && onClose();
    setMode(MODE_ENUM.HOME);
  }, [onClose]);

  if (!open) return null;

  switch (mode) {
    case MODE_ENUM.HOME:
      return <ChannelInfoHome open={open} onClose={handleClose} appChannel={appChannel} setMode={setMode} />;
    case MODE_ENUM.EDIT_CHANNEL:
      return <ChannelInfoEdit onClose={() => setMode(MODE_ENUM.HOME)} appChannel={appChannel} />;
    case MODE_ENUM.MEMBERS:
      return <ChannelNewMember onClose={() => setMode(MODE_ENUM.HOME)} appChannel={appChannel} />;
    case MODE_ENUM.MEDIA:
      return <ChannelInfoMedia onClose={() => setMode(MODE_ENUM.HOME)} appChannel={appChannel} />;
    case MODE_ENUM.BACKGROUND:
      return (
        <ChannelBackgroundEdit onClose={() => setMode(MODE_ENUM.HOME)} appChannel={appChannel} setMode={setMode} />
      );
    default:
      return null;
  }
};

export default ChannelInfo;
