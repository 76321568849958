import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { HStack, Center, Spinner } from '@gluestack-ui/themed-native-base';
import { useState, useCallback, useEffect } from 'react';
import { SortableItem } from '~/components/SortableItem';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useDashboardColumns } from '~/hooks/useDashboardColumns';
import { type DashboardColumn } from '~/hooks/useDashboardColumns';
import { ActivityGraphColumn } from './ActivityGraphColumn';
import { ActivityTrendPanelColumn } from './ActivityTrendPanelColumn';
import { AddChannelColumn } from './AddChannelColumn';
import { ChannelColumnWithId } from './ChannelColumn';
import { MilkingsGraphColumn } from './MilkingsGraphColumn';
import { MilkingsTrendPanelColumn } from './MilkingsTrendPanelColumn';
import { NotificationColumn } from './NotificationColumn';
import { AllChannelProvider } from './useAllChannel';
import type { DragEndEvent } from '@dnd-kit/core';

const COLUMN_WIDTH = 400;

export const DashboardScreen = () => {
  const { isUserConnected } = useStreamChatContext();
  const { setColumns, columns, updateColumn } = useDashboardColumns();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        const activeId = columns?.findIndex((column) => `${column.type}-${column.chatChannelId}` === event.active.id);
        const overId = columns?.findIndex((column) => `${column.type}-${column.chatChannelId}` === event.over?.id);
        const newColumn = arrayMove(columns, activeId, overId);
        setColumns(newColumn);
        updateColumn(newColumn);
      }
    },
    [columns, setColumns, updateColumn]
  );

  const renderColumn = useCallback(
    (item: DashboardColumn, index: number) => {
      const chatColumns = columns?.filter((column) => column.type === 'chat_channel');
      const chatIndex = chatColumns?.findIndex((column) => column.chatChannelId === item.chatChannelId);

      switch (item.type) {
        case 'chat_channel':
          return (
            <SortableItem
              key={item.chatChannelId}
              id={`${item.type}-${item.chatChannelId}`}
              index={index}
              timeout={isInitialized ? 0 : 1000 * chatIndex}
            >
              <ChannelColumnWithId width={COLUMN_WIDTH} channelId={item.chatChannelId || ''} />
            </SortableItem>
          );
        case 'activity_trend':
          return (
            <SortableItem key={item.type} id={`${item.type}-${item.chatChannelId}`} index={index}>
              <ActivityTrendPanelColumn width={COLUMN_WIDTH} />
            </SortableItem>
          );
        case 'milkings_trend':
          return (
            <SortableItem key={item.type} id={`${item.type}-${item.chatChannelId}`} index={index}>
              <MilkingsTrendPanelColumn width={COLUMN_WIDTH} />
            </SortableItem>
          );
        case 'activity_graph':
          return (
            <SortableItem key={item.type} id={`${item.type}-${item.chatChannelId}`} index={index}>
              <ActivityGraphColumn width={COLUMN_WIDTH} />
            </SortableItem>
          );
        case 'milkings_graph':
          return (
            <SortableItem key={item.type} id={`${item.type}-${item.chatChannelId}`} index={index}>
              <MilkingsGraphColumn width={COLUMN_WIDTH} />
            </SortableItem>
          );
        case 'notifications':
          return (
            <SortableItem key={item.type} id={`${item.type}-${item.chatChannelId}`} index={index}>
              {/* @ts-expect-error */}
              <NotificationColumn width={COLUMN_WIDTH} />
            </SortableItem>
          );
        default:
          return null;
      }
    },
    [columns, isInitialized]
  );

  useEffect(() => {
    setTimeout(() => {
      setIsInitialized(true);
    }, 5000);
  }, []);

  if (!isUserConnected || !columns) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <AllChannelProvider>
      <HStack gap="2xs" backgroundColor="surfaceHighest" height="100%" overflowX="scroll" width="auto">
        <HStack gap="2xs" backgroundColor="surfaceHighest" height="100%" width="auto">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            modifiers={[restrictToHorizontalAxis]}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={columns.map((column) => `${column.type}-${column.chatChannelId}`)}
              strategy={horizontalListSortingStrategy}
            >
              {columns?.map((column, index) => renderColumn(column, index))}
            </SortableContext>
          </DndContext>
        </HStack>
        <AddChannelColumn />
      </HStack>
    </AllChannelProvider>
  );
};
