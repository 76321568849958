import { MaterialIcons } from '@expo/vector-icons';
import { HStack, Spinner, Text, useToken } from '@gluestack-ui/themed-native-base';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native';

import { useSearchHistory } from '~/hooks/useSearchHistory';

interface Props {
  query: string;
  onSubmit: (query: string | undefined) => void;
}

export const SearchHistoryItem: React.FC<Props> = ({ query, onSubmit }) => {
  const [onSurfaceHigh, onSurfaceHighest] = useToken('colors', ['onSurfaceHigh', 'onSurfaceHighest']);
  const [md, lg] = useToken('space', ['md', 'lg']);
  const { deleteSearchHistory } = useSearchHistory();

  const [spinner, setSpinner] = useState(false);
  return (
    <TouchableOpacity
      onPress={() => {
        onSubmit(query);
      }}
    >
      <HStack alignItems="center" justifyContent="space-between" paddingHorizontal="none" py="2xs">
        <HStack alignItems="center" fontSize="md" gap="xs">
          <MaterialIcons name="search" size={md} color={onSurfaceHigh} />
          <Text fontSize="md" color="onSurface">
            {query}
          </Text>
        </HStack>
        {spinner ? (
          <HStack padding="3xs">
            <Spinner size="sm" />
          </HStack>
        ) : (
          <TouchableOpacity
            onPress={() => {
              setSpinner(true);
              deleteSearchHistory(query);
            }}
          >
            <HStack marginLeft="xs">
              <MaterialIcons name="close" size={lg} color={onSurfaceHighest} />
            </HStack>
          </TouchableOpacity>
        )}
      </HStack>
    </TouchableOpacity>
  );
};
