import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import type { IconProps } from 'stream-chat-react/dist/types/types';

const ActionsIcons: React.FC<IconProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        d="M4.03646 9.08122C3.66979 9.08122 3.3559 8.95066 3.09479 8.68955C2.83368 8.42844 2.70312 8.11455 2.70312 7.74788C2.70312 7.38122 2.83368 7.06733 3.09479 6.80622C3.3559 6.54511 3.66979 6.41455 4.03646 6.41455C4.40313 6.41455 4.71701 6.54511 4.97813 6.80622C5.23924 7.06733 5.36979 7.38122 5.36979 7.74788C5.36979 8.11455 5.23924 8.42844 4.97813 8.68955C4.71701 8.95066 4.40313 9.08122 4.03646 9.08122ZM8.03646 9.08122C7.66979 9.08122 7.3559 8.95066 7.09479 8.68955C6.83368 8.42844 6.70312 8.11455 6.70312 7.74788C6.70312 7.38122 6.83368 7.06733 7.09479 6.80622C7.3559 6.54511 7.66979 6.41455 8.03646 6.41455C8.40313 6.41455 8.71701 6.54511 8.97813 6.80622C9.23924 7.06733 9.36979 7.38122 9.36979 7.74788C9.36979 8.11455 9.23924 8.42844 8.97813 8.68955C8.71701 8.95066 8.40313 9.08122 8.03646 9.08122ZM12.0365 9.08122C11.6698 9.08122 11.3559 8.95066 11.0948 8.68955C10.8337 8.42844 10.7031 8.11455 10.7031 7.74788C10.7031 7.38122 10.8337 7.06733 11.0948 6.80622C11.3559 6.54511 11.6698 6.41455 12.0365 6.41455C12.4031 6.41455 12.717 6.54511 12.9781 6.80622C13.2392 7.06733 13.3698 7.38122 13.3698 7.74788C13.3698 8.11455 13.2392 8.42844 12.9781 8.68955C12.717 8.95066 12.4031 9.08122 12.0365 9.08122Z"
        fill={gluestackUIConfig.tokens.colors.onSurfaceHigh}
      />
    </svg>
  );
};

export default ActionsIcons;
