import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { HStack, Text, Pressable, VStack } from '@gluestack-ui/themed-native-base';
import { Toast } from 'native-base';
import React, { useCallback, useContext, useEffect } from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { ShowPinMessagesDispatchContext } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useChannelMembers } from '~/hooks';
import { useDashboardColumns } from '~/hooks/useDashboardColumns';
import CloseIcons from '~/icons/CloseIcons';
import DragIcons from '~/icons/DragIcons';
import InforIcons from '~/icons/InforIcons';
import type { DefaultStreamChatGenerics } from '~/lib/StreamChatReact';
import { useChannelStateContext } from '~/lib/StreamChatReact';
import { SortableItemContext } from './SortableItem';
import type { Channel } from 'stream-chat';

const UnMemoizedCustomHeader: React.FC<{
  handleOpenInfo?: (() => void) | undefined;
  channelSelected?: Channel<DefaultStreamChatGenerics>;
  multiSection?: boolean;
  onClose?: (() => void) | undefined;
}> = ({ handleOpenInfo, multiSection, channelSelected, onClose }) => {
  const { channel, thread } = useChannelStateContext();

  if (thread) {
    return null;
  }
  const { chatClient } = useStreamChatContext();
  const { removeColumn } = useDashboardColumns();

  const { allMembers } = useChannelMembers(multiSection ? channelSelected?.id || '' : channel?.id || '');

  const dispatch = useContext(ShowPinMessagesDispatchContext);

  useEffect(() => {
    if (thread) {
      handleOpenInfo;
    }
  }, [thread]);

  useDidUpdate(() => {
    dispatch && dispatch({ type: 'close' });
  }, [channel?.cid]);

  //トーストの問題の対応 https://github.com/GeekyAnts/NativeBase/issues/5107
  setTimeout(() => Toast.closeAll(), 5000);

  const memberIds = channel?.state?.members ? Object.keys(channel?.state?.members) : [];
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { attributes, listeners, ref, index } = useContext(SortableItemContext);

  const handleClose = useCallback(() => {
    if (typeof index === 'number') {
      removeColumn(index);
      return;
    }
    if (onClose) {
      onClose();
    }
  }, [onClose, index, removeColumn]);

  return (
    <HStack
      id="custom-header"
      width="100%"
      py="sm"
      px="md"
      borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderColor="outlineVariant"
      style={{
        zIndex: 100,
        justifyContent: multiSection ? 'space-between' : 'center',
      }}
      backgroundColor="surfaceLow"
    >
      <HStack alignItems="center" justifyContent="space-between" width="100%">
        {multiSection ? (
          <HStack gap="2xs" alignItems="center">
            <strong>
              <Pressable {...attributes} {...listeners} ref={ref}>
                <DragIcons />
              </Pressable>
            </strong>
            <VStack>
              <Text fontWeight="bold" fontSize="lg" color="onSurface">
                {!channelSelected?.data?.isDM || memberIds.length <= 1
                  ? channelSelected?.data?.name
                  : memberIds
                      .filter((key) => key !== chatClient.userID || memberIds.length === 1)
                      .map((key) => channelSelected?.state?.members[key]?.user?.name)
                      .join(', ')}
              </Text>
              <Text fontSize="sm" fontWeight="regular" color="onSurface">
                {allMembers?.length} 名のメンバー
              </Text>
            </VStack>
          </HStack>
        ) : (
          <VStack>
            <Text fontWeight="bold" fontSize="lg" color="onSurface">
              {!channel?.data?.isDM || memberIds.length <= 1
                ? channel?.data?.name
                : memberIds
                    .filter((key) => key !== chatClient.userID || memberIds.length === 1)
                    .map((key) => channel?.state?.members[key]?.user?.name)
                    .join(', ')}
            </Text>
            <Text fontSize="sm" fontWeight="regular" color="onSurface">
              {allMembers?.length} 名のメンバー
            </Text>
          </VStack>
        )}
        <HStack marginRight={0} marginLeft="auto">
          {multiSection ? (
            <Pressable onPress={handleClose}>
              <CloseIcons />
            </Pressable>
          ) : (
            <Pressable onPress={handleOpenInfo}>
              <HStack alignItems="center" space={1}>
                <InforIcons />
              </HStack>
            </Pressable>
          )}
        </HStack>
      </HStack>
    </HStack>
  );
};

export const CustomHeader = React.memo(UnMemoizedCustomHeader);
