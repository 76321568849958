import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';
import type { PayloadAction } from '@reduxjs/toolkit';

export type StreamChatState = {
  registeredPushToken?: string | null;
};

const slice = createSlice({
  name: 'streamChat',
  initialState: {
    registeredPushToken: null,
  } as StreamChatState,
  reducers: {
    setRegisteredPushToken: (state, { payload }: PayloadAction<string | null>) => {
      state.registeredPushToken = payload;
    },
  },
});

export const { setRegisteredPushToken } = slice.actions;

export const streamChatSelector = (state: RootState) => {
  return state.streamChat;
};

export default slice.reducer;
