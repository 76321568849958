import { Ionicons } from '@expo/vector-icons';
import { HStack, Text, Icon, Pressable } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { memo } from 'react';
import { Platform } from 'react-native';

const WAITING_TIME_FOR_CLOSING_DRAWER = 250; // 250 ms

// Ioniconsのname属性の型を取得
type IoniconsNames = keyof typeof Ionicons.glyphMap;

interface Props {
  icon?: IoniconsNames;
  title: string;
  navigateTo?: string;
  navigateToOptions?: object;
  onPress?: () => void;
  testID?: string;
}

export const MenuItem: React.FC<Props> = memo(({ icon, title, navigateTo, navigateToOptions, onPress, testID }) => {
  const navigation = useNavigation();
  const handleOnPress = () => {
    if (navigateTo) {
      if (navigateToOptions) {
        // @ts-expect-error TS(2769): No overload matches this call.
        navigation.navigate(navigateTo, navigateToOptions);
      } else if (Platform.OS === 'web') {
        requestAnimationFrame(() => {
          setTimeout(() => {
            // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            navigation.navigate('MenuNavigator', {
              screen: navigateTo,
            });
          }, WAITING_TIME_FOR_CLOSING_DRAWER);
        });
      } else {
        // @ts-expect-error TS(2769): No overload matches this call.
        navigation.navigate(navigateTo);
      }
    } else if (onPress) {
      onPress();
    }
  };

  return (
    <Pressable onPress={handleOnPress} testID={testID}>
      <HStack space="xs" justifyContent="center" alignItems="center">
        {icon ? (
          <Text>
            <Icon as={Ionicons} name={icon} color="onSurfaceHigh" size="md" />
          </Text>
        ) : null}
        <Text textAlign="left" flexShrink={1} maxHeight={8} width="100%" fontWeight="bold" color="onSurface">
          <Text fontSize="md" fontWeight="bold" flex={1} color="onSurface">
            {title}
          </Text>
        </Text>
        <Text>
          <Icon as={Ionicons} name="chevron-forward-outline" alignSelf="center" color="onSurfaceHigh" size="lg" />
        </Text>
      </HStack>
    </Pressable>
  );
});
