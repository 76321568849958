import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import debounce from 'lodash/debounce';
import { useRef, useState, useCallback } from 'react';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import type { Channel, ChannelMemberResponse, DefaultGenerics } from 'stream-chat';

const getUserList = (usersChannels: Channel[], chatUserId: string, query: string) => {
  const userName = query.match(/@[^\s]*(?![^\s])/)?.[0]?.replace('@', '');
  const uniqueUserList: ChannelMemberResponse[] = [];
  const userListDuplicatable = usersChannels.flatMap((c) => {
    if (!c.state) {
      return [];
    }
    return (Object.values(c.state?.members) as ChannelMemberResponse[]).filter(
      (member) => member.user_id !== chatUserId && (!userName || member.user?.name?.startsWith(userName))
    );
  });

  userListDuplicatable.forEach((user) => {
    if (!uniqueUserList.find((u) => u.user_id === user.user_id)) {
      uniqueUserList.push(user);
    }
  });

  return uniqueUserList;
};

export const useChannelQuery = () => {
  const [query, setQuery] = useState<string>('');
  const [channels, setChannels] = useState<Channel<DefaultGenerics>[]>([]);
  const [usersChannels, setUsersChannels] = useState<Channel[]>([]);
  const [userList, setUserList] = useState<ChannelMemberResponse[]>([]);
  const channelOffsetRef = useRef(0);
  const { chatUserId, chatClient, isUserConnected } = useStreamChatContext();

  const channellimit = 30;

  const resetChannels = useCallback(() => {
    setChannels([]);
    channelOffsetRef.current = 0;
  }, []);

  const usersChannelsQuery = useCallback(async () => {
    const filter = {
      $or: [
        {
          members: {
            $in: [chatUserId],
          },
          //name: { $autocomplete: channelQuery },
        },
        {
          members: {
            $nin: [chatUserId],
          },
          type: { $in: ['team'] },
          //name: { $autocomplete: channelQuery },
        },
      ],
    };
    const sort = [{ last_message_at: -1 }];

    let respCount = 30;
    let allChannels: any = [];

    while (respCount === channellimit) {
      const userChannels = await chatClient
        // @ts-expect-error TS(2345): Argument of type '{ $or: ({ members: { $in: (strin... Remove this comment to see the full error message
        .queryChannels(filter, sort, {
          watch: true,
          state: true,
          limit: channellimit,
          offset: channelOffsetRef.current,
        })
        .catch((error) => {
          console.error('channel search error: ', error);
        });

      respCount = userChannels?.length ? userChannels?.length : 0;
      // @ts-expect-error TS(2488): Type 'void | Channel<StreamChatGenerics>[]' must h... Remove this comment to see the full error message
      allChannels = [...allChannels, ...userChannels];
      channelOffsetRef.current = channelOffsetRef.current + channellimit;
    }
    return new Set(allChannels);
  }, [chatUserId, chatClient]);

  useDidUpdate(
    () => {
      debounce(() => {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        setUserList(getUserList(usersChannels, chatUserId, query));
      }, 1000)();
    },
    [usersChannels, chatUserId, query],
    true
  );

  const initUsersChannels = useCallback(async () => {
    if (usersChannels.length || !isUserConnected) return;
    const data = (await usersChannelsQuery()) as unknown as Channel[];
    setUsersChannels([...data]);
  }, [isUserConnected, usersChannelsQuery]);

  const channelQuery = async (channelQuery: string) => {
    if (!channelQuery) {
      return;
    }
    const queryWords = channelQuery.split(/\s+/);

    setChannels(
      usersChannels.filter(
        (c) => queryWords.filter((q) => c.data?.name?.toLocaleLowerCase().includes(q.toLocaleLowerCase()))?.length > 0
      )
    );
  };

  return {
    query,
    channelQuery,
    channels,
    setQuery,
    resetChannels,
    usersChannelsQuery,
    usersChannels,
    userList: userList || [],
    initUsersChannels,
  };
};
