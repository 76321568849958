import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import {
  Box,
  HStack,
  Text,
  Skeleton,
  VStack,
  Button,
  useTheme,
  ScrollView,
  Switch,
} from '@gluestack-ui/themed-native-base';
import { View } from 'native-base';
import * as React from 'react';
import { useState } from 'react';

import { useLactationMilkAmountByRange } from '~/hooks/useLactationMilkAmountByRange';
import { VictoryLine, VictoryChart, VictoryTheme, VictoryAxis, VictoryScatter, VictoryLegend } from '~/lib/Victory';
import { DateUtil } from '~/utils/DateUtils';

import { GraphAlertBox } from './GraphAlertBox';
import { TableChart } from './TableChart';
import type { StyledProps } from 'native-base';

interface Props {
  endDate: Date;
  range: number;
  showTable: boolean;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  queryParameters?: {
    endDate: string;
    range: number;
    mode: 'table' | 'graph';
  };
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  onTypeChange?: (type: string) => void;
  shareButton?: React.ReactElement;
}

export const MilkAmountByLactationNumberChart: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    showTable,
    chatChannelId,
    groupId,
    farmId,
    queryParameters,
    isPreview = false,
    width = 400,
    height = 350,
    barWidth = 8,
    onTypeChange,
    shareButton,
  }) => {
    const { setEndDate, setRange, milkings, milkingsMA, preMilkings } = useLactationMilkAmountByRange(
      groupId || 0,
      endDate,
      range
    );
    const [shouldShowPrev, setShouldShowPrev] = useState(false);
    const { colors } = useTheme();

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    useDidUpdate(() => {
      queryParameters?.endDate && setEndDate(DateUtil.dateHourToDate(queryParameters?.endDate));
      queryParameters?.range && setRange(queryParameters?.range);
      // @ts-expect-error TS(2304): Cannot find name 'setTableMode'.
      queryParameters?.mode === 'table' && setTableMode(true);
    }, [queryParameters]);

    useDidMount(() => {
      queryParameters?.endDate && setEndDate(DateUtil.dateHourToDate(queryParameters?.endDate));
      queryParameters?.range && setRange(queryParameters?.range);
      // @ts-expect-error TS(2304): Cannot find name 'setTableMode'.
      queryParameters?.mode === 'table' && setTableMode(true);
    });

    const tableData = milkingsMA.data?.reduce((acc, group) => {
      group.data?.forEach((datum) => {
        const date = DateUtil.toMMDD(datum.date);
        const amount = datum.amount;
        const parity = group.parity;

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (!acc[date]) {
          // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          acc[date] = { date };
        }

        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[date][parity] = amount || 0;
      });

      return acc;
    }, {});

    const stackedBarChart = React.useMemo(
      () => (
        <VictoryChart
          width={isPreview ? 220 : width}
          height={isPreview ? 182 : height}
          theme={VictoryTheme.material}
          domainPadding={{ x: [0, 0], y: [0, 0] }}
          padding={isPreview ? { top: 20, bottom: 40, left: 40, right: 20 } : undefined}
        >
          <VictoryAxis
            fixLabelOverlap
            tickFormat={(x, i) => {
              const date = new Date(x);
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }}
          />
          <VictoryAxis dependentAxis tickFormat={(y) => y} />
          {isPreview ? null : (
            <VictoryLegend
              orientation="horizontal"
              x={52}
              gutter={60}
              data={[
                { name: '日毎の搾乳量', symbol: { fill: '#aaa' } },
                { name: '5日間移動平均', symbol: { fill: '#aaa', type: 'minus' } },
              ]}
            />
          )}
          {shouldShowPrev
            ? preMilkings.data
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                ?.filter((orig) => orig.parity !== 0 && orig.parity < 7)
                .map((preMilking, index) => {
                  return (
                    <VictoryLine
                      key={index.toString()}
                      data={
                        preMilking?.data?.map((cur) => {
                          const utcDate = DateUtil.dateHourToDate(cur.date, 'yyyy-MM-dd');
                          return {
                            x: DateUtil.toYYYYMMDD(DateUtil.addDays(utcDate, range), '-'),
                            y: cur.amount || 0,
                          };
                        }) ?? []
                      }
                      style={{
                        data: {
                          // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                          stroke: colors.um.lactationNumbers[preMilking.parity - 1],
                          strokeOpacity: 0.8,
                          strokeDasharray: 4,
                        },
                      }}
                    />
                  );
                })
            : null}
          {milkingsMA.data
            // @ts-expect-error TS(2532): Object is possibly 'undefined'.
            ?.filter((orig) => orig.parity !== 0 && orig.parity < 7)
            .map((milking) => {
              return (
                <VictoryLine
                  data={
                    milking?.data?.map((cur) => {
                      return {
                        x: cur.date,
                        y: cur.amount || 0,
                      };
                    }) ?? []
                  }
                  style={{
                    // @ts-expect-error TS(2339): Property 'um' does not exist on type '{ contrastTh... Remove this comment to see the full error message
                    data: { stroke: colors.um.lactationNumbers[milking.parity - 1], strokeWidth: isPreview ? 2 : 4 },
                  }}
                />
              );
            })}
          {range === 30
            ? milkings.data
                // @ts-expect-error TS(2532): Object is possibly 'undefined'.
                ?.filter((orig) => orig.parity !== 0 && orig.parity < 7)
                .map((milking, index) => (
                  <VictoryScatter
                    data={
                      milking?.data?.map((cur) => {
                        return {
                          x: cur.date,
                          y: cur.amount || 0,
                        };
                      }) ?? []
                    }
                    size={isPreview ? 2 : 3}
                    style={{
                      data: { fill: colors.um.lactationNumbers[index], opacity: 0.5 },
                    }}
                  />
                ))
            : null}
        </VictoryChart>
      ),
      [milkings, preMilkings, shouldShowPrev, milkingsMA]
    );

    if (milkings?.isLoading) {
      return <Skeleton height={isPreview ? 216 : height} />;
    }

    if (milkings.isError || preMilkings.isError || milkingsMA.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            milkings.refetch();
            preMilkings.refetch();
            milkingsMA.refetch();
          }}
        />
      );
    }

    if (isPreview) {
      return <VStack>{stackedBarChart}</VStack>;
    }

    return (
      <View>
        <ScrollView horizontal nestedScrollEnabled>
          <HStack p="xs">
            {milkings.data
              ?.filter((orig) => orig.parity !== 0)
              .map((byGroup, index) => (
                <Button
                  size="sm"
                  m="2xs"
                  borderRadius="full"
                  backgroundColor={colors.um.lactationNumbers[byGroup.parity || 0 - 1]}
                  key={byGroup.parity}
                  _text={{ fontWeight: 'bold', color: 'onSurface' }}
                >
                  {`${byGroup?.parity}産`}
                </Button>
              ))}
          </HStack>
        </ScrollView>

        <Box paddingX="auto">{stackedBarChart}</Box>
        {false && (
          <HStack alignItems="center" justifyContent="flex-end" space={2} paddingX={4}>
            <Text>前の期間と比較</Text>
            {/* @ts-expect-error */}
            <Switch value={shouldShowPrev} onToggle={(newValue) => setShouldShowPrev(newValue)} size="sm" />
          </HStack>
        )}
        <TableChart
          // @ts-expect-error TS(2322): Type '(number | { date?: string | undefined; amoun... Remove this comment to see the full error message
          data={tableData && Object.values(tableData)}
          width={width}
          headers={['日付'].concat(
            milkingsMA.data ? milkingsMA.data.map((milking) => (milking.parity ? String(milking.parity) : '')) : []
          )}
          columns={['date'].concat(
            milkingsMA.data ? milkingsMA.data?.map((milking) => (milking.parity ? String(milking.parity) : '')) : []
          )}
          padding={4}
          showTable={showTable}
          widthArr={new Array<number>(
            ['日付'].concat(
              milkingsMA.data ? milkingsMA.data.map((milking) => (milking.parity ? String(milking.parity) : '')) : []
            ).length
          ).fill(50)}
        />
        {shareButton ? shareButton : null}
      </View>
    );
  }
);
