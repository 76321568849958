import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import {
  VStack,
  Text,
  Divider,
  Button,
  HStack,
  Alert,
  Center,
  Spinner,
  Skeleton,
} from '@gluestack-ui/themed-native-base';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { FormBuilder } from 'native-base-form-builder';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import type { ReactNode } from 'react';

interface Props {
  queryResult: {
    data: [];
    error: boolean;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
    errorMessage: string;
    refetch: () => void;
  };
  update: ({}) => void;
  updateResult: any;
  emptyMessage?: string | ReactNode;
  fields: object[] | [][];
}

export const EditFormBuilder = ({ queryResult, update, updateResult, emptyMessage, fields }: Props) => {
  const { data, error, isSuccess, isLoading, errorMessage, isError, refetch } = queryResult;
  const navigation = useNavigation();
  const { isUpdating, data: updatedData } = updateResult;

  const { control, setFocus, handleSubmit, setValue } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (sendingData: object) => {
    update({
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '[]'.
      id: data?.id,
      body: {
        ...sendingData,
      },
    })
      // @ts-expect-error TS(2339): Property 'unwrap' does not exist on type 'void'.
      .unwrap()
      .then((updated: any) => {
        navigation.goBack();
      });
  };

  useDidUpdate(
    () => {
      if (data) {
        // @ts-expect-error TS(2339): Property 'key' does not exist on type 'object'.
        fields.forEach((field) => setValue(field.key, data?.[field.key]));
      }
    },
    [data],
    true
  );

  const onCancel = () => navigation.goBack();

  useFocusEffect(() => {
    if (!isSuccess && !isLoading && !isError) {
      refetch();
    }
  });

  const formConfigArray = fields.map((field) => ({
    ...field,
    // @ts-expect-error TS(2339): Property 'type' does not exist on type 'object | [... Remove this comment to see the full error message
    type: field?.type || 'text',
    // @ts-expect-error TS(2339): Property 'key' does not exist on type 'object | []... Remove this comment to see the full error message
    name: field?.key,
  }));

  return (
    <VStack>
      {data && !isUpdating ? (
        <VStack space="xs">
          <FormBuilder control={control} setFocus={setFocus} formConfigArray={formConfigArray} inputSpacing={29} />
          <Divider my={2} />
          <Button variant="outline" onPress={onCancel}>
            キャンセル
          </Button>
          <Button onPress={() => handleSubmit(onSubmit)()}>保存</Button>
        </VStack>
      ) : null}
      {isUpdating ? (
        <Center padding={20}>
          <HStack space="md">
            <Spinner />
            <Text>更新中...</Text>
          </HStack>
        </Center>
      ) : null}
      {isSuccess && data.length === 0 ? (
        <Center paddingX="xl" paddingY="md">
          <Alert status="warning" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="space-between" space="xs" flexShrink={1}>
                <Alert.Icon />
                {emptyMessage ? (
                  typeof emptyMessage === 'string' ? (
                    <Text flexShrink={1} fontSize="md">
                      {emptyMessage}
                    </Text>
                  ) : (
                    { emptyMessage }
                  )
                ) : null}
              </HStack>
            </VStack>
          </Alert>
        </Center>
      ) : null}
      {isLoading
        ? [...Array(10).keys()].map((key) => (
            <>
              <Skeleton.Text
                height={24}
                width={30}
                rounded="md"
                startColor="surfaceHighest"
                isLoaded={false}
                padding="xl"
              />
            </>
          ))
        : null}
      {error ? (
        <Center paddingX="xl" paddingY="md">
          <Alert status="error" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="space-between" space="xs" flexShrink={1}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {errorMessage}
                </Text>
              </HStack>
            </VStack>
          </Alert>
        </Center>
      ) : null}
    </VStack>
  );
};
