import { Box, HStack, Pressable } from '@gluestack-ui/themed-native-base';
import { some, size } from 'lodash';
import React from 'react';
import { Linking, StyleSheet, Text, View } from 'react-native';
import SimpleMarkdown, { inlineRegex } from 'simple-markdown';

import { settingsSelector } from '~/slices/settingsSlice';
import { useAppSelector } from '~/store';
import type { Capture, Parser, State } from 'simple-markdown';

type ListItemProps =
  | {
      type: 'text';
      content: string;
    }
  | {
      type: 'ruby';
      text: string;
      furi: string;
    }
  | {
      type: 'paragraph';
      content: ListItemProps[];
    }
  | {
      type: 'list';
      items: ListItemProps[][];
      ordered: boolean;
    };

type FuriProps = {
  text: string;
  furi: string;
};

type ParsedListItemProps = (FuriProps | ParsedListItemProps)[];

const getParsedList = (itemList: ListItemProps[]): ParsedListItemProps => {
  return itemList.map((item) => {
    if (item.type === 'text') {
      return {
        text: item.content,
        furi: '',
      };
    }
    if (item.type === 'ruby') {
      return {
        text: item.text,
        furi: item.furi,
      };
    }
    if (item.type === 'paragraph') {
      return getParsedList(item.content);
    }
    if (item.type === 'list') {
      return item.items.flatMap((item) => getParsedList(item));
    }
    return [];
  });
};

const flattenNestedArrays = (arr: ParsedListItemProps) => {
  const result: FuriProps[][] = [];

  function flattenAndSeparate(subArr: ParsedListItemProps) {
    if (subArr.every((item) => !Array.isArray(item))) {
      result.push(subArr as FuriProps[]);
    } else {
      if (subArr.every((item) => Array.isArray(item))) {
        subArr.forEach((innerArr) => {
          flattenAndSeparate(innerArr as (FuriProps | FuriProps[])[]);
        });
      } else {
        const newSublist = subArr.filter((item) => !Array.isArray(item));
        if (newSublist.length > 0) {
          result.push(newSublist as FuriProps[]);
        }
        subArr
          .filter((item) => Array.isArray(item))
          .forEach((innerArr) => {
            flattenAndSeparate(innerArr as (FuriProps | FuriProps[])[]);
          });
      }
    }
  }

  flattenAndSeparate(arr);
  return result;
};

const LinkText: React.FC<{ text: string }> = ({ text }) => {
  const settings = useAppSelector(settingsSelector);

  const fontFamilySettings = React.useMemo(() => {
    return settings.enableUDFont ? { fontFamily: 'BIZUDPGothic_400Regular' } : {};
  }, [settings.enableUDFont]);

  return (
    <Pressable onPress={() => Linking.openURL(text)}>
      <Text style={{ ...styles.autolink, ...styles.bodyText, ...fontFamilySettings }}>{text}</Text>
    </Pressable>
  );
};

const TextWithFuri: React.FC<{ text: string; furi?: string }> = ({ text, furi = '' }) => {
  const settings = useAppSelector(settingsSelector);

  const fontFamilySettings = React.useMemo(() => {
    return settings.enableUDFont ? { fontFamily: 'BIZUDPGothic_400Regular' } : {};
  }, [settings.enableUDFont]);

  const furiMarginSettings = React.useMemo(() => {
    return settings.enableUDFont ? {} : { marginBottom: -2 };
  }, [settings.enableUDFont]);

  return (
    <View style={styles.textWithFuriContainer}>
      {!!furi && <Text style={{ ...styles.furiText, ...fontFamilySettings, ...furiMarginSettings }}>{furi}</Text>}
      <View style={styles.bodyContainer}>
        {[...text].map((c, index) => (
          <Text key={index} style={{ ...styles.bodyText, ...fontFamilySettings }}>
            {c}
          </Text>
        ))}
      </View>
    </View>
  );
};

const BulletText: React.FC<{ ordered: boolean; index: number }> = ({ ordered, index }) => {
  const settings = useAppSelector(settingsSelector);

  const fontFamilySettings = React.useMemo(() => {
    return settings.enableUDFont ? { paddingTop: 11, fontFamily: 'BIZUDPGothic_400Regular' } : { paddingTop: 8 };
  }, [settings.enableUDFont]);

  return <Text style={fontFamilySettings}>{ordered ? `${index + 1}. ` : '\u2022 '}</Text>;
};

const ContentText: React.FC<{ key: string; text: string }> = ({ key, text }) => (
  <Box>
    {text.split('\n').map((line, index) => (
      <HStack key={`content-${key}-${index}`} alignItems="flex-end" flexWrap="wrap">
        {[...line]
          .filter((val) => val !== ' ')
          .map((c, innerIndex) => (
            <TextWithFuri key={`content-${key}-${index}-${innerIndex}`} text={c} furi="" />
          ))}
        {index === text.split('\n').length - 1 && <Box w={0.5} />}
      </HStack>
    ))}
  </Box>
);

// Original code is copied from react-native-markdown-package/styles.js
const styles = StyleSheet.create({
  autolink: {
    color: 'blue',
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },
  paragraphCenter: {
    marginTop: 10,
    marginBottom: 10,
    flexWrap: 'wrap',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  paragraphWithImage: {
    flex: 1,
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  noMargin: {
    marginTop: 0,
    marginBottom: 0,
  },
  textWithFuriContainer: {
    justifyContent: 'flex-end',
    marginBottom: 2,
  },
  furiText: {
    fontSize: 8,
    textAlign: 'center',
  },
  bodyContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bodyText: {
    fontSize: 16,
  },
});

// Original code is copied from react-native-markdown-package/rules.js
export const markdownRulesForKanasapo = {
  paragraph: {
    react(node: any, output: any, { ...state }) {
      let paragraphStyle = styles.paragraph;
      // Allow image to drop in next line within the paragraph
      if (some(node.content, { type: 'image' })) {
        state.withinParagraphWithImage = true;
        const paragraph = React.createElement(
          View,
          {
            key: state.key,
            style: styles.paragraphWithImage,
          },
          output(node.content, state)
        );
        state.withinParagraphWithImage = false;
        return paragraph;
      } else if (size(node.content) < 3 && some(node.content, { type: 'strong' })) {
        // align to center for Strong only content
        // require a check of content array size below 3,
        // as parse will include additional space as `text`
        // @ts-expect-error TS(2322): Type '{ marginTop: number; marginBottom: number; f... Remove this comment to see the full error message
        paragraphStyle = styles.paragraphCenter;
      }
      if (state.withinList) {
        // @ts-expect-error TS(2740): Type '{ marginTop: number; marginBottom: number; }... Remove this comment to see the full error message
        paragraphStyle = [paragraphStyle, styles.noMargin];
      }
      return React.createElement(
        View,
        {
          key: state.key,
          style: paragraphStyle,
        },
        output(node.content, state)
      );
    },
  },
  list: {
    react(node: any, output: any, state: any) {
      const { ordered, items, start } = node;
      const list = flattenNestedArrays((items as ListItemProps[][]).map((item) => getParsedList(item)));

      return (
        <Box py={1.5}>
          {list.map((item, index) => (
            <HStack key={`${state.key}-${index}`} alignItems="flex-start" flexWrap="nowrap">
              <BulletText ordered={ordered} index={start + index} />
              <HStack flexWrap="wrap" alignItems="flex-end">
                <Box height={7} />
                {item.map((c, innerIndex) => (
                  <>
                    {c.furi ? (
                      <TextWithFuri
                        key={`${state.key}-${index}-${innerIndex}`}
                        text={c.text.replaceAll('\n', '')}
                        furi={c.furi}
                      />
                    ) : (
                      <ContentText key={state.key} text={c.text} />
                    )}
                  </>
                ))}
              </HStack>
            </HStack>
          ))}
        </Box>
      );
    },
  },
  ruby: {
    order: SimpleMarkdown.defaultRules.escape.order - 0.5,
    match: inlineRegex(/^\\<ruby\\>(.+?)《(.+?)》\\<\/ruby\\>/),
    parse: (capture: Capture, nestedParse: Parser, state: State) => {
      return {
        type: 'ruby',
        text: capture[1] ?? '',
        furi: capture[2] ?? '',
      };
    },
    react: (node: any, output: any, state: any) => <TextWithFuri key={state.key} text={node.text} furi={node.furi} />,
    html: null,
  },
  text: {
    react: (node: any, output: any, state: any) => {
      if (node.content.match(/^https?:\/\/\S+$/)) {
        return <LinkText text={node.content} />;
      }

      return <ContentText key={state.key} text={node.content as string} />;
    },
  },
};
