import { HStack, VStack, Text, Center, Alert } from '@gluestack-ui/themed-native-base';
import type { PropsWithChildren } from 'react';
interface Props {
  colorScheme: string;
}

export const UsageNote: React.FC<Props & PropsWithChildren> = ({ colorScheme = 'default', children }) => {
  return (
    <Center>
      <Alert colorScheme={colorScheme}>
        <VStack flexShrink={1} width="100%">
          <HStack alignItems="center" justifyContent="space-between" space={2} flexShrink={1}>
            <Alert.Icon />
            <Text flexShrink={1}>{children}</Text>
          </HStack>
        </VStack>
      </Alert>
    </Center>
  );
};
