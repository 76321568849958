import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

const ArrowLeftIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
      <g clipPath="url(#clip0_1814_8679)">
        <path
          d="M3.54922 10.0001L10.8992 17.3501C11.1492 17.6001 11.2701 17.8918 11.2617 18.2251C11.2534 18.5584 11.1242 18.8501 10.8742 19.1001C10.6242 19.3501 10.3326 19.4751 9.99922 19.4751C9.66589 19.4751 9.37422 19.3501 9.12422 19.1001L1.42422 11.4251C1.22422 11.2251 1.07422 11.0001 0.974219 10.7501C0.874219 10.5001 0.824219 10.2501 0.824219 10.0001C0.824219 9.75011 0.874219 9.50011 0.974219 9.25011C1.07422 9.00011 1.22422 8.77511 1.42422 8.57511L9.12422 0.87511C9.37422 0.62511 9.67005 0.504277 10.0117 0.51261C10.3534 0.520944 10.6492 0.65011 10.8992 0.90011C11.1492 1.15011 11.2742 1.44178 11.2742 1.77511C11.2742 2.10844 11.1492 2.40011 10.8992 2.65011L3.54922 10.0001Z"
          fill={gluestackUIConfig.tokens.colors.onSurfaceHigh}
        />
      </g>
      <defs>
        <clipPath id="clip0_1814_8679">
          <rect width="12" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ArrowLeftIcons;
