import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { FontAwesome } from '@expo/vector-icons';
import { Icon, View, Button, HStack, useToken } from '@gluestack-ui/themed-native-base';
import React, { useState } from 'react';
import { StyleSheet } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { Table, Row, Rows } from 'react-native-table-component';
import type { StyledProps } from 'native-base';

interface Props {
  data: object[];
  headers: string[];
  columns: string[];
  showTable: boolean;
  widthArr?: number[];
  width?: number;
}

export const TableChart: React.FC<Props & StyledProps> = React.memo(
  ({ data, headers, columns, showTable, widthArr, width = 400, ...props }) => {
    const [tableData, setTableData] = useState<[object] | []>([]);
    const [tableMode, setTableMode] = useState(showTable);
    const [gray700] = useToken('colors', ['gray.700']);

    useDidUpdate(
      () => {
        if (!data) {
          return;
        }
        // @ts-expect-error TS(2345): Argument of type 'any[][]' is not assignable to pa... Remove this comment to see the full error message
        setTableData(data.map((item) => columns.map((column) => item[column])));
      },
      [data],
      true
    );

    return (
      <>
        <HStack justifyContent="center" mt={6}>
          <Button
            leftIcon={<Icon as={FontAwesome} name="table" color="onSurface" />}
            onPress={() => setTableMode(!tableMode)}
            variant="outline"
            rounded="full"
            px={6}
            borderColor="onSurface"
            gap={1}
            _text={{
              color: 'onSurface',
            }}
          >
            {tableMode ? 'テーブルを閉じる' : 'テーブルを表示'}
          </Button>
        </HStack>

        {tableMode ? (
          <View {...props} width={width}>
            <ScrollView horizontal nestedScrollEnabled>
              <View minWidth="100%" style={styles.container}>
                <Table borderBottomStyle={{ borderColor: '#a9a9a9' }}>
                  <Row data={headers} style={styles.head} textStyle={styles.textHead} widthArr={widthArr} />
                  <Rows data={tableData} style={styles.tbode} textStyle={styles.textTbody} widthArr={widthArr} />
                </Table>
              </View>
            </ScrollView>
          </View>
        ) : null}
      </>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    minWidth: '100%',
  },
  head: {
    height: 40,
    borderBottomColor: '#a9a9a9',
    borderBottomWidth: 2,
  },
  tbode: { height: 40, borderBottomColor: '#a9a9a9', borderBottomWidth: 1 },
  textHead: { margin: 3, color: '#4d4d4d', fontSize: 12 },
  textTbody: { margin: 3, color: '#000000', fontSize: 14 },
});
