import { VStack, Alert, Button, Text, Box } from '@gluestack-ui/themed-native-base';
import { useCallback, useState, memo } from 'react';
import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';

import { setEnableWebNotification } from '~/slices/settingsSlice';
import { useAppDispatch } from '~/store';
import { NotificationList } from './NotificationList';

type Selected = { type: 'my' | 'dm'; channelId?: string; messageId?: string };

const SideMenu: React.FC<{ setSelected: React.Dispatch<React.SetStateAction<Selected>> }> = memo(({ setSelected }) => {
  const handleMessagePress = useCallback(
    (message: { type: 'my' | 'dm'; channelId: string; messageId: string }) => {
      setSelected({
        type: message.type,
        channelId: message.channelId,
        messageId: message.messageId,
      });
    },
    [setSelected]
  );

  const dispatch = useAppDispatch();
  const onWebPushConfigPress = useCallback(async () => {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      await dispatch(setEnableWebNotification(true));
      location.reload();
    }
  }, []);

  return (
    <Box
      width={450}
      height="100%"
      borderColor="surfaceHighest"
      borderRightWidth={gluestackUIConfig.tokens.borderWidths.thick}
    >
      {Notification?.permission === 'default' && (
        <VStack backgroundColor="surfaceLow" padding="xs" borderBottomWidth="medium" borderBottomColor="outline">
          <Alert status="info" padding="xs">
            <VStack space="xs">
              <VStack>
                <Text lineHeight="md" color="onSurface">
                  ウェブ・プッシュ通知を許可すると、メッセージを受信したときに通知バナーを表示できます。{'\n'}
                  開始する場合は「通知を設定する」をクリックして、ブラウザの通知を「許可」してください。
                </Text>
              </VStack>
              <Button onPress={onWebPushConfigPress} backgroundColor="primary" _text={{ color: 'onPrimary' }}>
                通知を設定する
              </Button>
            </VStack>
          </Alert>
        </VStack>
      )}
      <NotificationList messagePressFn={handleMessagePress} />
    </Box>
  );
});

export const NotificationsScreen = () => {
  const [selected, setSelected] = useState<Selected>({
    type: 'my',
  });

  return (
    <>
      {selected.channelId ? (
        <ChatChannelListWeb
          type={selected?.type}
          channelId={selected?.channelId}
          messageId={selected?.messageId}
          sideMenu={<SideMenu setSelected={setSelected} />}
          hideChannelList
        />
      ) : (
        <SideMenu setSelected={setSelected} />
      )}
    </>
  );
};
