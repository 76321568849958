import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export type OverlayReactionState = {
  targetMessageId: string;
  showEmojiModal: boolean;
};

const slice = createSlice({
  name: 'overlayReaction',
  initialState: {} as OverlayReactionState,
  reducers: {
    setTargetMessageId: (state, { payload }: PayloadAction<string>) => {
      state.targetMessageId = payload;
    },
    toggleShowEmojiModal: (state) => {
      state.showEmojiModal = !state.showEmojiModal;
    },
  },
});

export const overlayReactionSelector = (state: { overlayReaction: OverlayReactionState }) => {
  return state.overlayReaction;
};

export const { setTargetMessageId, toggleShowEmojiModal } = slice.actions;

export default slice.reducer;
