import React from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';

const BackgroundImageIcons = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <mask id="mask0_674_10402" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="32">
        <rect width="32" height="32" fill={gluestackUIConfig.tokens.colors.outline} />
      </mask>
      <g mask="url(#mask0_674_10402)">
        <path
          d="M11.9974 18.6667H25.3307L20.7307 12.6667L17.6641 16.6667L15.5974 14.0001L11.9974 18.6667ZM10.6641 24.0001C9.93073 24.0001 9.30295 23.739 8.78073 23.2167C8.25851 22.6945 7.9974 22.0667 7.9974 21.3334V5.33341C7.9974 4.60007 8.25851 3.9723 8.78073 3.45007C9.30295 2.92785 9.93073 2.66674 10.6641 2.66674H26.6641C27.3974 2.66674 28.0252 2.92785 28.5474 3.45007C29.0696 3.9723 29.3307 4.60007 29.3307 5.33341V21.3334C29.3307 22.0667 29.0696 22.6945 28.5474 23.2167C28.0252 23.739 27.3974 24.0001 26.6641 24.0001H10.6641ZM5.33073 29.3334C4.5974 29.3334 3.96962 29.0723 3.4474 28.5501C2.92517 28.0279 2.66406 27.4001 2.66406 26.6667V8.00007H5.33073V26.6667H23.9974V29.3334H5.33073Z"
          fill={gluestackUIConfig.tokens.colors.onSurfaceHigh}
        />
      </g>
    </svg>
  );
};

export default BackgroundImageIcons;
