import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { useEffect, useMemo, useRef } from 'react';
import { AppState } from 'react-native';

import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import type { Avatars } from '~/slices/avatarsCacheSlice';
import { avatarsCacheSelector, setAvatars } from '~/slices/avatarsCacheSlice';
import { useAppDispatch, useAppSelector } from '~/store';

import { useCurrentTime } from './useCurrentTime';
import type { AppStateStatus } from 'react-native';

const REFETCH_INTERVAL_SECONDS = 60 * 60 * 2; // 2 hours

export const useAvatars = () => {
  const { avatars, avatarsByUserId, avatarsByChatUserId, updatedAt } = useAppSelector(avatarsCacheSelector);
  const dispatch = useAppDispatch();

  const currentTime = useCurrentTime({ intervalSec: 60 });
  const { refetch } = useGetCurrentUserGroupsQuery();

  const shouldRefetch = useMemo(() => {
    if (updatedAt === 0) return true;
    const expired = (currentTime - updatedAt) / 1000 > REFETCH_INTERVAL_SECONDS;
    return expired;
  }, [updatedAt, currentTime]);

  const shouldRefetchRef = useRef(shouldRefetch);

  useEffect(() => {
    shouldRefetchRef.current = shouldRefetch;
  }, [shouldRefetch]);

  const currentUserGroupsQuery = shouldRefetch
    ? useGetCurrentUserGroupsQuery(undefined, {
        refetchOnMountOrArgChange: REFETCH_INTERVAL_SECONDS,
        refetchOnFocus: true,
        pollingInterval: REFETCH_INTERVAL_SECONDS * 1000,
      })
    : useGetCurrentUserGroupsQuery(skipToken, {});

  useEffect(() => {
    const handleAppStateChange = (nextAppState: AppStateStatus) => {
      if (nextAppState === 'active' && shouldRefetchRef.current) {
        refetch();
      }
    };

    const subscription = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      subscription.remove();
    };
  }, []);

  useDidUpdate(
    () => {
      if (!currentUserGroupsQuery.isSuccess) {
        return;
      }
      const groups = currentUserGroupsQuery.data || [];
      const users = groups
        .map((g) => g.approved_users)
        ?.flat()
        .map((u) => {
          return {
            userId: Number(u?.id),
            chatUserId: u?.chat_user_id,
            avatar: u?.avatar,
          };
        }) as Avatars;

      dispatch(setAvatars(users));
    },
    [currentUserGroupsQuery.data],
    true
  );

  return {
    avatars,
    avatarsByUserId,
    avatarsByChatUserId,
    isLoading: currentUserGroupsQuery.isLoading,
  };
};
