import { useActionSheet } from '@expo/react-native-action-sheet';
import { Ionicons } from '@expo/vector-icons';
import { Icon, Image } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import { useGetGroupsByGroupIdGroupUsersQuery } from '~/api/uFeedApi';

import { ItemList } from './ItemList';
import { ListItem } from './ListItem';
import { Section } from './Section';
import type { StyledProps } from 'native-base';

interface Props {
  groupId: number;
  admin?: boolean;
}

export const GroupMemberCard: React.FC<Props & StyledProps> = React.memo(
  ({ groupId, admin = false, ...remainingProps }) => {
    const { showActionSheetWithOptions } = useActionSheet();
    const navigation = useNavigation();
    const linkTo = useLinkTo();

    const groupUsers = useGetGroupsByGroupIdGroupUsersQuery({
      groupId,
    });

    const onAddGroupMemberPress = () =>
      showActionSheetWithOptions(
        {
          title: 'メンバーの追加',
          options: ['同じ会社のメンバー', '他の会社のユーザーを招待', 'キャンセル'],
          cancelButtonIndex: 2,
        },
        (i) => {
          switch (i) {
            case 0:
              Platform.OS === 'web'
                ? linkTo(`/admin/group_user/create/${groupId}`)
                : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('AdminGroupUserCreate', {
                    groupId,
                  });
              break;
            case 1:
              Platform.OS === 'web'
                ? linkTo(`/admin/external_group_user/invite/groups/${groupId}`)
                : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('AdminExternalGroupUserInvite', {
                    groupId,
                  });
              break;
          }
        }
      );

    return (
      // @ts-expect-error
      <Section {...remainingProps}>
        <ItemList>
          {admin && (
            <ListItem
              key="add-button"
              title="グループ・メンバーを追加"
              left={<Icon as={Ionicons} name="add-circle" color="primary" />}
              onPress={onAddGroupMemberPress}
              link
            />
          )}
          {groupUsers.data?.map((groupUser) => {
            return (
              <ListItem
                key={groupUser.id}
                title={groupUser.user?.name || ''}
                left={
                  groupUser.user?.avatar ? (
                    <Image source={{ uri: groupUser.user.avatar }} size="sm" borderRadius="full" alt="" />
                  ) : (
                    <Icon as={Ionicons} name="person-circle-outline" size="6xl" color="onSurface" />
                  )
                }
                description={`${groupUser.user?.account?.name} ${groupUser.roles
                  ?.map((role) => {
                    switch (role) {
                      case 'owner':
                        return '（オーナー）';
                      case 'partner':
                        return '（パートナー）';
                      case 'guest':
                        return '（ゲスト）';
                      default:
                        return '（エラー）';
                    }
                  })
                  .join('')}`}
                onPress={() => {
                  Platform.OS === 'web'
                    ? admin
                      ? linkTo(`/admin/group_user/groups/${groupId}/users/${groupUser?.id}`)
                      : linkTo(`/groups/${groupId}/user/${groupUser?.id}`)
                    : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                      navigation.navigate(admin ? 'AdminGroupUserDetail' : 'GroupUserDetail', {
                        groupId,
                        userId: groupUser?.id,
                      });
                }}
                arrow
              />
            );
          })}
        </ItemList>
      </Section>
    );
  }
);
