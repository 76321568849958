import { baseApi as api } from './baseQuery';
export const addTagTypes = [
  'Accounts',
  'Additives',
  'AppliedFeedDesigns',
  'GroupMilkings',
  'GroupMilkingsParities',
  'GroupMilkingsUMCattleGroups',
  'GroupMilkingsStatistic',
  'Farms',
  'FeedDesigns',
  'GroupFeedDesigns',
  'GroupActivities',
  'Groups',
  'GroupUsers',
  'CurrentUser',
  'InvitedGroups',
  'LinkageRequests',
  'Reminders',
  'Tasks',
  'Users',
  'UsersCurrent',
  'UserSetting',
  'CurrentUser::Bookmark',
  'Visits',
  'Chat::Channel',
  'Chat',
  'UmCattleGroups',
  'Appointments::Service',
  'Appointments::Slot',
  'Appointment',
  'PushNotifications::Token',
  'PushConfig',
  'Notification',
  'PushMuteConfig',
  'PushKeyword',
  'UConcierge',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getAccounts: build.query<GetAccountsApiResponse, GetAccountsApiArg>({
        query: () => ({ url: `/accounts` }),
        providesTags: ['Accounts'],
      }),
      putAccounts: build.mutation<PutAccountsApiResponse, PutAccountsApiArg>({
        query: (queryArg) => ({ url: `/accounts`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Accounts'],
      }),
      getGroupsByGroupIdAdditives: build.query<
        GetGroupsByGroupIdAdditivesApiResponse,
        GetGroupsByGroupIdAdditivesApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/additives` }),
        providesTags: ['Additives'],
      }),
      postGroupsByGroupIdAdditives: build.mutation<
        PostGroupsByGroupIdAdditivesApiResponse,
        PostGroupsByGroupIdAdditivesApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/additives`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Additives'],
      }),
      getAdditivesByAdditiveId: build.query<GetAdditivesByAdditiveIdApiResponse, GetAdditivesByAdditiveIdApiArg>({
        query: (queryArg) => ({ url: `/additives/${queryArg.additiveId}` }),
        providesTags: ['Additives'],
      }),
      putAdditivesByAdditiveId: build.mutation<PutAdditivesByAdditiveIdApiResponse, PutAdditivesByAdditiveIdApiArg>({
        query: (queryArg) => ({ url: `/additives/${queryArg.additiveId}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Additives'],
      }),
      deleteAdditivesByAdditiveId: build.mutation<
        DeleteAdditivesByAdditiveIdApiResponse,
        DeleteAdditivesByAdditiveIdApiArg
      >({
        query: (queryArg) => ({ url: `/additives/${queryArg.additiveId}`, method: 'DELETE' }),
        invalidatesTags: ['Additives'],
      }),
      getGroupsByGroupIdAppliedFeedDesigns: build.query<
        GetGroupsByGroupIdAppliedFeedDesignsApiResponse,
        GetGroupsByGroupIdAppliedFeedDesignsApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/applied_feed_designs` }),
        providesTags: ['AppliedFeedDesigns'],
      }),
      postGroupsByGroupIdAppliedFeedDesigns: build.mutation<
        PostGroupsByGroupIdAppliedFeedDesignsApiResponse,
        PostGroupsByGroupIdAppliedFeedDesignsApiArg
      >({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/applied_feed_designs`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['AppliedFeedDesigns'],
      }),
      getAppliedFeedDesignsById: build.query<GetAppliedFeedDesignsByIdApiResponse, GetAppliedFeedDesignsByIdApiArg>({
        query: (queryArg) => ({ url: `/applied_feed_designs/${queryArg.id}` }),
        providesTags: ['AppliedFeedDesigns'],
      }),
      putAppliedFeedDesignsById: build.mutation<PutAppliedFeedDesignsByIdApiResponse, PutAppliedFeedDesignsByIdApiArg>({
        query: (queryArg) => ({ url: `/applied_feed_designs/${queryArg.id}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['AppliedFeedDesigns'],
      }),
      deleteAppliedFeedDesignsById: build.mutation<
        DeleteAppliedFeedDesignsByIdApiResponse,
        DeleteAppliedFeedDesignsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/applied_feed_designs/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['AppliedFeedDesigns'],
      }),
      getGroupsByGroupIdMilkings: build.query<GetGroupsByGroupIdMilkingsApiResponse, GetGroupsByGroupIdMilkingsApiArg>({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/milkings`,
          params: {
            aggregate_type: queryArg.aggregateType,
            begin_date: queryArg.beginDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ['GroupMilkings'],
      }),
      getGroupsByGroupIdMilkingsParities: build.query<
        GetGroupsByGroupIdMilkingsParitiesApiResponse,
        GetGroupsByGroupIdMilkingsParitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/milkings/parities`,
          params: {
            aggregate_type: queryArg.aggregateType,
            begin_date: queryArg.beginDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ['GroupMilkingsParities'],
      }),
      getGroupsByGroupIdMilkingsUmCattleGroups: build.query<
        GetGroupsByGroupIdMilkingsUmCattleGroupsApiResponse,
        GetGroupsByGroupIdMilkingsUmCattleGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/milkings/um_cattle_groups`,
          params: {
            aggregate_type: queryArg.aggregateType,
            begin_date: queryArg.beginDate,
            end_date: queryArg.endDate,
          },
        }),
        providesTags: ['GroupMilkingsUMCattleGroups'],
      }),
      getGroupsByGroupIdMilkingsStatistic: build.query<
        GetGroupsByGroupIdMilkingsStatisticApiResponse,
        GetGroupsByGroupIdMilkingsStatisticApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/milkings/statistic` }),
        providesTags: ['GroupMilkingsStatistic'],
      }),
      getFarms: build.query<GetFarmsApiResponse, GetFarmsApiArg>({
        query: () => ({ url: `/farms` }),
        providesTags: ['Farms'],
      }),
      postFarms: build.mutation<PostFarmsApiResponse, PostFarmsApiArg>({
        query: (queryArg) => ({ url: `/farms`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Farms'],
      }),
      getFarmsById: build.query<GetFarmsByIdApiResponse, GetFarmsByIdApiArg>({
        query: (queryArg) => ({ url: `/farms/${queryArg.id}` }),
        providesTags: ['Farms'],
      }),
      putFarmsById: build.mutation<PutFarmsByIdApiResponse, PutFarmsByIdApiArg>({
        query: (queryArg) => ({ url: `/farms/${queryArg.id}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Farms'],
      }),
      deleteFarmsById: build.mutation<DeleteFarmsByIdApiResponse, DeleteFarmsByIdApiArg>({
        query: (queryArg) => ({ url: `/farms/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Farms'],
      }),
      getFeedDesigns: build.query<GetFeedDesignsApiResponse, GetFeedDesignsApiArg>({
        query: () => ({ url: `/feed_designs` }),
        providesTags: ['FeedDesigns'],
      }),
      postFeedDesigns: build.mutation<PostFeedDesignsApiResponse, PostFeedDesignsApiArg>({
        query: (queryArg) => ({ url: `/feed_designs`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['FeedDesigns'],
      }),
      getFeedDesignsById: build.query<GetFeedDesignsByIdApiResponse, GetFeedDesignsByIdApiArg>({
        query: (queryArg) => ({ url: `/feed_designs/${queryArg.id}` }),
        providesTags: ['FeedDesigns'],
      }),
      putFeedDesignsById: build.mutation<PutFeedDesignsByIdApiResponse, PutFeedDesignsByIdApiArg>({
        query: (queryArg) => ({ url: `/feed_designs/${queryArg.id}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['FeedDesigns', 'GroupFeedDesigns'],
      }),
      deleteFeedDesignsById: build.mutation<DeleteFeedDesignsByIdApiResponse, DeleteFeedDesignsByIdApiArg>({
        query: (queryArg) => ({ url: `/feed_designs/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['FeedDesigns', 'GroupFeedDesigns'],
      }),
      getFeedDesignsByFeedDesignsIdGroups: build.query<
        GetFeedDesignsByFeedDesignsIdGroupsApiResponse,
        GetFeedDesignsByFeedDesignsIdGroupsApiArg
      >({
        query: (queryArg) => ({ url: `/feed_designs/${queryArg.feedDesignsId}/groups` }),
        providesTags: ['GroupFeedDesigns'],
      }),
      getFeedDesignsByFeedDesignsIdGroupFeedDesigns: build.query<
        GetFeedDesignsByFeedDesignsIdGroupFeedDesignsApiResponse,
        GetFeedDesignsByFeedDesignsIdGroupFeedDesignsApiArg
      >({
        query: (queryArg) => ({ url: `/feed_designs/${queryArg.feedDesignsId}/group_feed_designs` }),
        providesTags: ['GroupFeedDesigns'],
      }),
      getGroupsByGroupIdGroupActivities: build.query<
        GetGroupsByGroupIdGroupActivitiesApiResponse,
        GetGroupsByGroupIdGroupActivitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/group_activities`,
          params: { begin_date: queryArg.beginDate, end_date: queryArg.endDate },
        }),
        providesTags: ['GroupActivities'],
      }),
      getGroupsByGroupIdActivitiesStatistic: build.query<
        GetGroupsByGroupIdActivitiesStatisticApiResponse,
        GetGroupsByGroupIdActivitiesStatisticApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/activities/statistic` }),
        providesTags: ['GroupActivities'],
      }),
      getGroupMilkings: build.query<GetGroupMilkingsApiResponse, GetGroupMilkingsApiArg>({
        query: () => ({ url: `/group_milkings` }),
        providesTags: ['GroupMilkings'],
      }),
      postGroupMilkings: build.mutation<PostGroupMilkingsApiResponse, PostGroupMilkingsApiArg>({
        query: (queryArg) => ({ url: `/group_milkings`, method: 'POST', body: queryArg.groupMilkings }),
        invalidatesTags: ['GroupMilkings'],
      }),
      putGroupMilkings: build.mutation<PutGroupMilkingsApiResponse, PutGroupMilkingsApiArg>({
        query: (queryArg) => ({ url: `/group_milkings`, method: 'PUT', body: queryArg.groupMilkings }),
        invalidatesTags: ['GroupMilkings'],
      }),
      deleteGroupMilkings: build.mutation<DeleteGroupMilkingsApiResponse, DeleteGroupMilkingsApiArg>({
        query: () => ({ url: `/group_milkings`, method: 'DELETE' }),
        invalidatesTags: ['GroupMilkings'],
      }),
      getFarmsByFarmIdGroups: build.query<GetFarmsByFarmIdGroupsApiResponse, GetFarmsByFarmIdGroupsApiArg>({
        query: (queryArg) => ({ url: `/farms/${queryArg.farmId}/groups` }),
        providesTags: ['Groups'],
      }),
      postFarmsByFarmIdGroups: build.mutation<PostFarmsByFarmIdGroupsApiResponse, PostFarmsByFarmIdGroupsApiArg>({
        query: (queryArg) => ({ url: `/farms/${queryArg.farmId}/groups`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Groups'],
      }),
      getGroupsByGroupId: build.query<GetGroupsByGroupIdApiResponse, GetGroupsByGroupIdApiArg>({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}` }),
        providesTags: ['Groups'],
      }),
      putGroupsByGroupId: build.mutation<PutGroupsByGroupIdApiResponse, PutGroupsByGroupIdApiArg>({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Groups'],
      }),
      deleteGroupsByGroupId: build.mutation<DeleteGroupsByGroupIdApiResponse, DeleteGroupsByGroupIdApiArg>({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}`, method: 'DELETE' }),
        invalidatesTags: ['Groups'],
      }),
      getGroupsByGroupIdGroupUsers: build.query<
        GetGroupsByGroupIdGroupUsersApiResponse,
        GetGroupsByGroupIdGroupUsersApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/group_users` }),
        providesTags: ['GroupUsers'],
      }),
      postGroupsByGroupIdGroupUsers: build.mutation<
        PostGroupsByGroupIdGroupUsersApiResponse,
        PostGroupsByGroupIdGroupUsersApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/group_users`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['GroupUsers', 'CurrentUser'],
      }),
      getGroupUsersByUserId: build.query<GetGroupUsersByUserIdApiResponse, GetGroupUsersByUserIdApiArg>({
        query: (queryArg) => ({ url: `/group_users/${queryArg.userId}` }),
        providesTags: ['GroupUsers'],
      }),
      putGroupUsersByUserId: build.mutation<PutGroupUsersByUserIdApiResponse, PutGroupUsersByUserIdApiArg>({
        query: (queryArg) => ({ url: `/group_users/${queryArg.userId}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['GroupUsers', 'CurrentUser'],
      }),
      deleteGroupUsersByUserId: build.mutation<DeleteGroupUsersByUserIdApiResponse, DeleteGroupUsersByUserIdApiArg>({
        query: (queryArg) => ({ url: `/group_users/${queryArg.userId}`, method: 'DELETE' }),
        invalidatesTags: ['GroupUsers', 'CurrentUser'],
      }),
      getInvitedGroups: build.query<GetInvitedGroupsApiResponse, GetInvitedGroupsApiArg>({
        query: () => ({ url: `/invited_groups` }),
        providesTags: ['InvitedGroups'],
      }),
      postInvitedGroups: build.mutation<PostInvitedGroupsApiResponse, PostInvitedGroupsApiArg>({
        query: (queryArg) => ({ url: `/invited_groups`, method: 'POST', body: queryArg.invitedGroups }),
        invalidatesTags: ['InvitedGroups'],
      }),
      putInvitedGroups: build.mutation<PutInvitedGroupsApiResponse, PutInvitedGroupsApiArg>({
        query: (queryArg) => ({ url: `/invited_groups`, method: 'PUT', body: queryArg.invitedGroups }),
        invalidatesTags: ['InvitedGroups'],
      }),
      deleteInvitedGroups: build.mutation<DeleteInvitedGroupsApiResponse, DeleteInvitedGroupsApiArg>({
        query: () => ({ url: `/invited_groups`, method: 'DELETE' }),
        invalidatesTags: ['InvitedGroups'],
      }),
      getLinkageRequests: build.query<GetLinkageRequestsApiResponse, GetLinkageRequestsApiArg>({
        query: () => ({ url: `/linkage_requests` }),
        providesTags: ['LinkageRequests'],
      }),
      postLinkageRequests: build.mutation<PostLinkageRequestsApiResponse, PostLinkageRequestsApiArg>({
        query: (queryArg) => ({ url: `/linkage_requests`, method: 'POST', body: queryArg.linkageRequests }),
        invalidatesTags: ['LinkageRequests'],
      }),
      putLinkageRequests: build.mutation<PutLinkageRequestsApiResponse, PutLinkageRequestsApiArg>({
        query: (queryArg) => ({ url: `/linkage_requests`, method: 'PUT', body: queryArg.linkageRequests }),
        invalidatesTags: ['LinkageRequests'],
      }),
      deleteLinkageRequests: build.mutation<DeleteLinkageRequestsApiResponse, DeleteLinkageRequestsApiArg>({
        query: () => ({ url: `/linkage_requests`, method: 'DELETE' }),
        invalidatesTags: ['LinkageRequests'],
      }),
      getGroupsByGroupIdReminders: build.query<
        GetGroupsByGroupIdRemindersApiResponse,
        GetGroupsByGroupIdRemindersApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/reminders` }),
        providesTags: ['Reminders'],
      }),
      postGroupsByGroupIdReminders: build.mutation<
        PostGroupsByGroupIdRemindersApiResponse,
        PostGroupsByGroupIdRemindersApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/reminders`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Reminders'],
      }),
      getGroupsByGroupIdRemindersAndReminderId: build.query<
        GetGroupsByGroupIdRemindersAndReminderIdApiResponse,
        GetGroupsByGroupIdRemindersAndReminderIdApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/reminders/${queryArg.reminderId}` }),
        providesTags: ['Reminders'],
      }),
      putGroupsByGroupIdRemindersAndReminderId: build.mutation<
        PutGroupsByGroupIdRemindersAndReminderIdApiResponse,
        PutGroupsByGroupIdRemindersAndReminderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/reminders/${queryArg.reminderId}`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['Reminders'],
      }),
      deleteGroupsByGroupIdRemindersAndReminderId: build.mutation<
        DeleteGroupsByGroupIdRemindersAndReminderIdApiResponse,
        DeleteGroupsByGroupIdRemindersAndReminderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/reminders/${queryArg.reminderId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Reminders'],
      }),
      getGroupsByGroupIdTasks: build.query<GetGroupsByGroupIdTasksApiResponse, GetGroupsByGroupIdTasksApiArg>({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/tasks` }),
        providesTags: ['Tasks'],
      }),
      postGroupsByGroupIdTasks: build.mutation<PostGroupsByGroupIdTasksApiResponse, PostGroupsByGroupIdTasksApiArg>({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/tasks`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Tasks'],
      }),
      getTasksById: build.query<GetTasksByIdApiResponse, GetTasksByIdApiArg>({
        query: (queryArg) => ({ url: `/tasks/${queryArg.id}` }),
        providesTags: ['Tasks'],
      }),
      putTasksById: build.mutation<PutTasksByIdApiResponse, PutTasksByIdApiArg>({
        query: (queryArg) => ({ url: `/tasks/${queryArg.id}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Tasks'],
      }),
      deleteTasksById: build.mutation<DeleteTasksByIdApiResponse, DeleteTasksByIdApiArg>({
        query: (queryArg) => ({ url: `/tasks/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Tasks'],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: () => ({ url: `/users` }),
        providesTags: ['Users'],
      }),
      postUsers: build.mutation<PostUsersApiResponse, PostUsersApiArg>({
        query: (queryArg) => ({ url: `/users`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Users'],
      }),
      getUsersById: build.query<GetUsersByIdApiResponse, GetUsersByIdApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg.id}` }),
        providesTags: ['Users'],
      }),
      putUsersById: build.mutation<PutUsersByIdApiResponse, PutUsersByIdApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg.id}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Users'],
      }),
      deleteUsersById: build.mutation<DeleteUsersByIdApiResponse, DeleteUsersByIdApiArg>({
        query: (queryArg) => ({ url: `/users/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Users'],
      }),
      getCurrentUser: build.query<GetCurrentUserApiResponse, GetCurrentUserApiArg>({
        query: (queryArg) => ({ url: `/current_user`, params: { real: queryArg.real } }),
        providesTags: ['CurrentUser', 'UsersCurrent', 'Users'],
      }),
      deleteCurrentUser: build.mutation<DeleteCurrentUserApiResponse, DeleteCurrentUserApiArg>({
        query: () => ({ url: `/current_user`, method: 'DELETE' }),
        invalidatesTags: ['CurrentUser', 'UsersCurrent', 'Users'],
      }),
      getCurrentUserFarms: build.query<GetCurrentUserFarmsApiResponse, GetCurrentUserFarmsApiArg>({
        query: () => ({ url: `/current_user/farms` }),
        providesTags: ['Farms', 'CurrentUser', 'UsersCurrent'],
      }),
      getCurrentUserTasks: build.query<GetCurrentUserTasksApiResponse, GetCurrentUserTasksApiArg>({
        query: () => ({ url: `/current_user/tasks` }),
        providesTags: ['Farms', 'CurrentUser', 'UsersCurrent', 'Tasks'],
      }),
      getCurrentUserGroups: build.query<GetCurrentUserGroupsApiResponse, GetCurrentUserGroupsApiArg>({
        query: () => ({ url: `/current_user/groups` }),
        providesTags: ['Groups', 'CurrentUser', 'UsersCurrent'],
      }),
      getCurrentUserSetting: build.query<GetCurrentUserSettingApiResponse, GetCurrentUserSettingApiArg>({
        query: () => ({ url: `/current_user/setting` }),
        providesTags: ['UserSetting'],
      }),
      putCurrentUserSetting: build.mutation<PutCurrentUserSettingApiResponse, PutCurrentUserSettingApiArg>({
        query: (queryArg) => ({ url: `/current_user/setting`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['UserSetting'],
      }),
      getCurrentUserBookmarks: build.query<GetCurrentUserBookmarksApiResponse, GetCurrentUserBookmarksApiArg>({
        query: () => ({ url: `/current_user/bookmarks` }),
        providesTags: ['CurrentUser::Bookmark'],
      }),
      postCurrentUserBookmarks: build.mutation<PostCurrentUserBookmarksApiResponse, PostCurrentUserBookmarksApiArg>({
        query: (queryArg) => ({ url: `/current_user/bookmarks`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['CurrentUser::Bookmark'],
      }),
      deleteCurrentUserBookmarksById: build.mutation<
        DeleteCurrentUserBookmarksByIdApiResponse,
        DeleteCurrentUserBookmarksByIdApiArg
      >({
        query: (queryArg) => ({ url: `/current_user/bookmarks/${queryArg.id}"`, method: 'DELETE' }),
        invalidatesTags: ['CurrentUser::Bookmark'],
      }),
      getGroupsByGroupIdVisits: build.query<GetGroupsByGroupIdVisitsApiResponse, GetGroupsByGroupIdVisitsApiArg>({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/visits` }),
        providesTags: ['Visits'],
      }),
      postGroupsByGroupIdVisits: build.mutation<PostGroupsByGroupIdVisitsApiResponse, PostGroupsByGroupIdVisitsApiArg>({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/visits`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Visits'],
      }),
      getVisitsByVisitsId: build.query<GetVisitsByVisitsIdApiResponse, GetVisitsByVisitsIdApiArg>({
        query: (queryArg) => ({ url: `/visits/${queryArg.visitsId}` }),
        providesTags: ['Visits'],
      }),
      putVisitsByVisitsId: build.mutation<PutVisitsByVisitsIdApiResponse, PutVisitsByVisitsIdApiArg>({
        query: (queryArg) => ({ url: `/visits/${queryArg.visitsId}`, method: 'PUT', body: queryArg.body }),
        invalidatesTags: ['Visits'],
      }),
      deleteVisitsByVisitsId: build.mutation<DeleteVisitsByVisitsIdApiResponse, DeleteVisitsByVisitsIdApiArg>({
        query: (queryArg) => ({ url: `/visits/${queryArg.visitsId}`, method: 'DELETE' }),
        invalidatesTags: ['Visits'],
      }),
      getGroupsByGroupIdGroupFeedDesigns: build.query<
        GetGroupsByGroupIdGroupFeedDesignsApiResponse,
        GetGroupsByGroupIdGroupFeedDesignsApiArg
      >({
        query: (queryArg) => ({ url: `/groups/${queryArg.groupId}/group_feed_designs` }),
        providesTags: ['GroupFeedDesigns'],
      }),
      postGroupsByGroupIdGroupFeedDesigns: build.mutation<
        PostGroupsByGroupIdGroupFeedDesignsApiResponse,
        PostGroupsByGroupIdGroupFeedDesignsApiArg
      >({
        query: (queryArg) => ({
          url: `/groups/${queryArg.groupId}/group_feed_designs`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['GroupFeedDesigns'],
      }),
      getGroupFeedDesignsByGroupFeedDesignsId: build.query<
        GetGroupFeedDesignsByGroupFeedDesignsIdApiResponse,
        GetGroupFeedDesignsByGroupFeedDesignsIdApiArg
      >({
        query: (queryArg) => ({ url: `/group_feed_designs/${queryArg.groupFeedDesignsId}` }),
        providesTags: ['GroupFeedDesigns'],
      }),
      putGroupFeedDesignsByGroupFeedDesignsId: build.mutation<
        PutGroupFeedDesignsByGroupFeedDesignsIdApiResponse,
        PutGroupFeedDesignsByGroupFeedDesignsIdApiArg
      >({
        query: (queryArg) => ({
          url: `/group_feed_designs/${queryArg.groupFeedDesignsId}`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['GroupFeedDesigns'],
      }),
      deleteGroupFeedDesignsByGroupFeedDesignsId: build.mutation<
        DeleteGroupFeedDesignsByGroupFeedDesignsIdApiResponse,
        DeleteGroupFeedDesignsByGroupFeedDesignsIdApiArg
      >({
        query: (queryArg) => ({ url: `/group_feed_designs/${queryArg.groupFeedDesignsId}`, method: 'DELETE' }),
        invalidatesTags: ['GroupFeedDesigns'],
      }),
      patchChatChannelsById: build.mutation<PatchChatChannelsByIdApiResponse, PatchChatChannelsByIdApiArg>({
        query: (queryArg) => ({ url: `/chat/channels/${queryArg.id}`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['Chat::Channel'],
      }),
      getChatToken: build.query<GetChatTokenApiResponse, GetChatTokenApiArg>({
        query: () => ({ url: `/chat/token` }),
        providesTags: ['Chat'],
      }),
      getChatEphemeralToken: build.query<GetChatEphemeralTokenApiResponse, GetChatEphemeralTokenApiArg>({
        query: () => ({ url: `/chat/ephemeral_token` }),
        providesTags: ['Chat'],
      }),
      getFarmsByFarmIdUmCattleGroups: build.query<
        GetFarmsByFarmIdUmCattleGroupsApiResponse,
        GetFarmsByFarmIdUmCattleGroupsApiArg
      >({
        query: (queryArg) => ({ url: `/farms/${queryArg.farmId}/um_cattle_groups` }),
        providesTags: ['UmCattleGroups'],
      }),
      getAppointmentsServices: build.query<GetAppointmentsServicesApiResponse, GetAppointmentsServicesApiArg>({
        query: () => ({ url: `/appointments/services` }),
        providesTags: ['Appointments::Service'],
      }),
      postAppointmentsServices: build.mutation<PostAppointmentsServicesApiResponse, PostAppointmentsServicesApiArg>({
        query: (queryArg) => ({ url: `/appointments/services`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Appointments::Service'],
      }),
      getAppointmentsServicesById: build.query<
        GetAppointmentsServicesByIdApiResponse,
        GetAppointmentsServicesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/appointments/services/${queryArg.id}` }),
        providesTags: ['Appointments::Service'],
      }),
      patchAppointmentsServicesById: build.mutation<
        PatchAppointmentsServicesByIdApiResponse,
        PatchAppointmentsServicesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/appointments/services/${queryArg.id}`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['Appointments::Service'],
      }),
      deleteAppointmentsServicesById: build.mutation<
        DeleteAppointmentsServicesByIdApiResponse,
        DeleteAppointmentsServicesByIdApiArg
      >({
        query: (queryArg) => ({ url: `/appointments/services/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Appointments::Service'],
      }),
      getAppointmentsSlots: build.query<GetAppointmentsSlotsApiResponse, GetAppointmentsSlotsApiArg>({
        query: () => ({ url: `/appointments/slots` }),
        providesTags: ['Appointments::Slot'],
      }),
      postAppointmentsSlots: build.mutation<PostAppointmentsSlotsApiResponse, PostAppointmentsSlotsApiArg>({
        query: (queryArg) => ({ url: `/appointments/slots`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['Appointments::Slot'],
      }),
      patchAppointmentsSlotsById: build.mutation<
        PatchAppointmentsSlotsByIdApiResponse,
        PatchAppointmentsSlotsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/appointments/slots/${queryArg.id}`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['Appointments::Slot'],
      }),
      deleteAppointmentsSlotsById: build.mutation<
        DeleteAppointmentsSlotsByIdApiResponse,
        DeleteAppointmentsSlotsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/appointments/slots/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['Appointments::Slot'],
      }),
      getAppointments: build.query<GetAppointmentsApiResponse, GetAppointmentsApiArg>({
        query: (queryArg) => ({ url: `/appointments`, params: { history: queryArg.history, type: queryArg['type'] } }),
        providesTags: ['Appointment'],
      }),
      patchAppointmentsByAppointmentSlotId: build.mutation<
        PatchAppointmentsByAppointmentSlotIdApiResponse,
        PatchAppointmentsByAppointmentSlotIdApiArg
      >({
        query: (queryArg) => ({
          url: `/appointments/${queryArg.appointmentSlotId}`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['Appointment'],
      }),
      patchAppointmentsByAppointmentSlotIdCancel: build.mutation<
        PatchAppointmentsByAppointmentSlotIdCancelApiResponse,
        PatchAppointmentsByAppointmentSlotIdCancelApiArg
      >({
        query: (queryArg) => ({ url: `/appointments/${queryArg.appointmentSlotId}/cancel`, method: 'PATCH' }),
        invalidatesTags: ['Appointment'],
      }),
      getAppointmentsReservableServices: build.query<
        GetAppointmentsReservableServicesApiResponse,
        GetAppointmentsReservableServicesApiArg
      >({
        query: () => ({ url: `/appointments/reservable_services` }),
        providesTags: ['Appointment'],
      }),
      getAppointmentsReservableSlots: build.query<
        GetAppointmentsReservableSlotsApiResponse,
        GetAppointmentsReservableSlotsApiArg
      >({
        query: (queryArg) => ({
          url: `/appointments/reservable_slots`,
          params: { appointment_service_id: queryArg.appointmentServiceId },
        }),
        providesTags: ['Appointment'],
      }),
      postPushNotificationsToken: build.mutation<
        PostPushNotificationsTokenApiResponse,
        PostPushNotificationsTokenApiArg
      >({
        query: (queryArg) => ({ url: `/push_notifications/token`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['PushNotifications::Token'],
      }),
      deletePushNotificationsTokenRemove: build.mutation<
        DeletePushNotificationsTokenRemoveApiResponse,
        DeletePushNotificationsTokenRemoveApiArg
      >({
        query: (queryArg) => ({ url: `/push_notifications/token/remove`, method: 'DELETE', body: queryArg.body }),
        invalidatesTags: ['PushNotifications::Token'],
      }),
      deletePushNotificationsTokenRemoveAll: build.mutation<
        DeletePushNotificationsTokenRemoveAllApiResponse,
        DeletePushNotificationsTokenRemoveAllApiArg
      >({
        query: () => ({ url: `/push_notifications/token/remove_all`, method: 'DELETE' }),
        invalidatesTags: ['PushNotifications::Token'],
      }),
      postPushConfigs: build.mutation<PostPushConfigsApiResponse, PostPushConfigsApiArg>({
        query: (queryArg) => ({ url: `/push_configs`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['PushConfig'],
      }),
      patchPushConfigsUpdates: build.mutation<PatchPushConfigsUpdatesApiResponse, PatchPushConfigsUpdatesApiArg>({
        query: (queryArg) => ({ url: `/push_configs/updates`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['PushConfig'],
      }),
      getPushConfigsExist: build.query<GetPushConfigsExistApiResponse, GetPushConfigsExistApiArg>({
        query: () => ({ url: `/push_configs/exist` }),
        providesTags: ['PushConfig'],
      }),
      getNotifications: build.query<GetNotificationsApiResponse, GetNotificationsApiArg>({
        query: (queryArg) => ({
          url: `/notifications`,
          params: { last_id: queryArg.lastId, with_reaction: queryArg.withReaction },
        }),
        providesTags: ['Notification'],
      }),
      patchNotificationsRead: build.mutation<PatchNotificationsReadApiResponse, PatchNotificationsReadApiArg>({
        query: (queryArg) => ({ url: `/notifications/read`, method: 'PATCH', params: { type: queryArg['type'] } }),
        invalidatesTags: ['Notification'],
      }),
      patchNotificationsById: build.mutation<PatchNotificationsByIdApiResponse, PatchNotificationsByIdApiArg>({
        query: (queryArg) => ({ url: `/notifications/${queryArg.id}`, method: 'PATCH' }),
        invalidatesTags: ['Notification'],
      }),
      postPushMuteConfigs: build.mutation<PostPushMuteConfigsApiResponse, PostPushMuteConfigsApiArg>({
        query: (queryArg) => ({ url: `/push_mute_configs`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['PushMuteConfig'],
      }),
      getPushMuteConfigsExist: build.query<GetPushMuteConfigsExistApiResponse, GetPushMuteConfigsExistApiArg>({
        query: (queryArg) => ({
          url: `/push_mute_configs/exist`,
          params: { target_id: queryArg.targetId, target_type: queryArg.targetType },
        }),
        providesTags: ['PushMuteConfig'],
      }),
      deletePushMuteConfigsById: build.mutation<DeletePushMuteConfigsByIdApiResponse, DeletePushMuteConfigsByIdApiArg>({
        query: (queryArg) => ({ url: `/push_mute_configs/${queryArg.id}`, method: 'DELETE' }),
        invalidatesTags: ['PushMuteConfig'],
      }),
      postPushKeywords: build.mutation<PostPushKeywordsApiResponse, PostPushKeywordsApiArg>({
        query: (queryArg) => ({ url: `/push_keywords`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['PushKeyword'],
      }),
      getPushKeywordsExist: build.query<GetPushKeywordsExistApiResponse, GetPushKeywordsExistApiArg>({
        query: () => ({ url: `/push_keywords/exist` }),
        providesTags: ['PushKeyword'],
      }),
      patchPushKeywordsUpdates: build.mutation<PatchPushKeywordsUpdatesApiResponse, PatchPushKeywordsUpdatesApiArg>({
        query: (queryArg) => ({ url: `/push_keywords/updates`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['PushKeyword'],
      }),
      postUConcierge: build.mutation<PostUConciergeApiResponse, PostUConciergeApiArg>({
        query: (queryArg) => ({ url: `/u_concierge`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['UConcierge'],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as uFeedApi };
export type GetAccountsApiResponse = /** status 200 カレントユーザのアカウント(企業)情報が返される */ AccountRead;
export type GetAccountsApiArg = void;
export type PutAccountsApiResponse = /** status 200 OK */ AccountRead;
export type PutAccountsApiArg = {
  body: {
    account: Account;
  };
};
export type GetGroupsByGroupIdAdditivesApiResponse = /** status 200 添加剤追加一覧が返される */ AdditiveRead[];
export type GetGroupsByGroupIdAdditivesApiArg = {
  /** グループID */
  groupId: number;
};
export type PostGroupsByGroupIdAdditivesApiResponse = unknown;
export type PostGroupsByGroupIdAdditivesApiArg = {
  /** グループID */
  groupId: number;
  body: {
    additive: AdditiveWrite;
  };
};
export type GetAdditivesByAdditiveIdApiResponse = /** status 200 添加剤追加が返される */ AdditiveRead;
export type GetAdditivesByAdditiveIdApiArg = {
  /** 添加剤ID */
  additiveId: number;
};
export type PutAdditivesByAdditiveIdApiResponse = unknown;
export type PutAdditivesByAdditiveIdApiArg = {
  /** 添加剤ID */
  additiveId: number;
  body: {
    additive: AdditiveWrite;
  };
};
export type DeleteAdditivesByAdditiveIdApiResponse = unknown;
export type DeleteAdditivesByAdditiveIdApiArg = {
  /** 添加剤ID */
  additiveId: number;
};
export type GetGroupsByGroupIdAppliedFeedDesignsApiResponse =
  /** status 200 飼料設計適用一覧が返される */ AppliedFeedDesignRead[];
export type GetGroupsByGroupIdAppliedFeedDesignsApiArg = {
  /** グループID */
  groupId: number;
};
export type PostGroupsByGroupIdAppliedFeedDesignsApiResponse = unknown;
export type PostGroupsByGroupIdAppliedFeedDesignsApiArg = {
  /** グループID */
  groupId: number;
  body: {
    applied_feed_design: AppliedFeedDesignWrite;
  };
};
export type GetAppliedFeedDesignsByIdApiResponse = /** status 200 飼料設計適用が返される */ AppliedFeedDesignRead;
export type GetAppliedFeedDesignsByIdApiArg = {
  /** 飼料設計適用ID */
  id: number;
};
export type PutAppliedFeedDesignsByIdApiResponse = unknown;
export type PutAppliedFeedDesignsByIdApiArg = {
  /** 飼料設計適用ID */
  id: number;
  body: {
    applied_feed_design: AppliedFeedDesignWrite;
  };
};
export type DeleteAppliedFeedDesignsByIdApiResponse = unknown;
export type DeleteAppliedFeedDesignsByIdApiArg = {
  /** 飼料設計適用ID */
  id: number;
};
export type GetGroupsByGroupIdMilkingsApiResponse = /** status 200 添加剤追加一覧が返される */ AdditiveRead[];
export type GetGroupsByGroupIdMilkingsApiArg = {
  /** グループID */
  groupId: number;
  /** 集約の種類 */
  aggregateType: 'avg' | 'sum' | 'median';
  /** 取得開始日(YYYY-MM-DD) */
  beginDate: string;
  /** 取得終了日(YYYY-MM-DD) */
  endDate: string;
};
export type GetGroupsByGroupIdMilkingsParitiesApiResponse =
  /** status 200 グループに属する牛を産次毎で集計した搾乳量を返す */ MilkingsByLactationNumber[];
export type GetGroupsByGroupIdMilkingsParitiesApiArg = {
  /** グループID */
  groupId: number;
  /** 集約の種類 */
  aggregateType: 'avg' | 'sum' | 'median';
  /** 取得開始日(YYYY-MM-DD) */
  beginDate: string;
  /** 取得終了日(YYYY-MM-DD) */
  endDate: string;
};
export type GetGroupsByGroupIdMilkingsUmCattleGroupsApiResponse =
  /** status 200 グループに属する牛を産次毎で集計した搾乳量を返す */ MilkingsByUmCattleGroup[];
export type GetGroupsByGroupIdMilkingsUmCattleGroupsApiArg = {
  /** グループID */
  groupId: number;
  /** 集約の種類 */
  aggregateType: 'avg' | 'sum' | 'median';
  /** 取得開始日(YYYY-MM-DD) */
  beginDate: string;
  /** 取得終了日(YYYY-MM-DD) */
  endDate: string;
};
export type GetGroupsByGroupIdMilkingsStatisticApiResponse = /** status 200 搾乳量の統計情報 */ MilkingsStatistic;
export type GetGroupsByGroupIdMilkingsStatisticApiArg = {
  /** グループID */
  groupId: number;
};
export type GetFarmsApiResponse = /** status 200 牧場一覧が返される */ FarmRead[];
export type GetFarmsApiArg = void;
export type PostFarmsApiResponse = unknown;
export type PostFarmsApiArg = {
  body: {
    farm: Farm;
  };
};
export type GetFarmsByIdApiResponse = /** status 200 牧場が返される */ FarmRead;
export type GetFarmsByIdApiArg = {
  /** 牧場ID */
  id: number;
};
export type PutFarmsByIdApiResponse = unknown;
export type PutFarmsByIdApiArg = {
  /** 牧場ID */
  id: number;
  body: {
    farm: Farm;
  };
};
export type DeleteFarmsByIdApiResponse = unknown;
export type DeleteFarmsByIdApiArg = {
  /** 牧場ID */
  id: number;
};
export type GetFeedDesignsApiResponse = /** status 200 資料設計一覧が返される */ FeedDesignRead[];
export type GetFeedDesignsApiArg = void;
export type PostFeedDesignsApiResponse = unknown;
export type PostFeedDesignsApiArg = {
  body: {
    feed_design: FeedDesignWrite;
  };
};
export type GetFeedDesignsByIdApiResponse = /** status 200 飼料設計が返される */ FeedDesignRead;
export type GetFeedDesignsByIdApiArg = {
  /** 飼料設計ID */
  id: number;
};
export type PutFeedDesignsByIdApiResponse = unknown;
export type PutFeedDesignsByIdApiArg = {
  /** 飼料設計ID */
  id: number;
  body: {
    feed_design: FeedDesignWrite;
  };
};
export type DeleteFeedDesignsByIdApiResponse = unknown;
export type DeleteFeedDesignsByIdApiArg = {
  /** 飼料設計ID */
  id: number;
};
export type GetFeedDesignsByFeedDesignsIdGroupsApiResponse =
  /** status 200 資料設計グループ一覧が返される */ GroupRead[];
export type GetFeedDesignsByFeedDesignsIdGroupsApiArg = {
  /** 飼料設計ID */
  feedDesignsId: number;
};
export type GetFeedDesignsByFeedDesignsIdGroupFeedDesignsApiResponse =
  /** status 200 資料設計グループ一覧が返される */ GroupFeedDesignRead[];
export type GetFeedDesignsByFeedDesignsIdGroupFeedDesignsApiArg = {
  /** 飼料設計ID */
  feedDesignsId: number;
};
export type GetGroupsByGroupIdGroupActivitiesApiResponse =
  /** status 200 活動量グラフデータが返される */ GroupActivity[];
export type GetGroupsByGroupIdGroupActivitiesApiArg = {
  /** グループID */
  groupId: number;
  /** 取得開始日(YYYY-MM-DD) */
  beginDate: string;
  /** 取得終了日(YYYY-MM-DD) */
  endDate: string;
};
export type GetGroupsByGroupIdActivitiesStatisticApiResponse = /** status 200 活動量の統計を返す */ ActivityStatistic;
export type GetGroupsByGroupIdActivitiesStatisticApiArg = {
  /** グループID */
  groupId: number;
};
export type GetGroupMilkingsApiResponse = /** status 200 GroupMilkingsが返される */ GroupMilkings[];
export type GetGroupMilkingsApiArg = void;
export type PostGroupMilkingsApiResponse = unknown;
export type PostGroupMilkingsApiArg = {
  groupMilkings: GroupMilkings;
};
export type PutGroupMilkingsApiResponse = unknown;
export type PutGroupMilkingsApiArg = {
  groupMilkings: GroupMilkings;
};
export type DeleteGroupMilkingsApiResponse = unknown;
export type DeleteGroupMilkingsApiArg = void;
export type GetFarmsByFarmIdGroupsApiResponse = /** status 200 牧場のグループ一覧が返される */ GroupRead[];
export type GetFarmsByFarmIdGroupsApiArg = {
  /** 牧場ID */
  farmId: number;
};
export type PostFarmsByFarmIdGroupsApiResponse = unknown;
export type PostFarmsByFarmIdGroupsApiArg = {
  /** 牧場ID */
  farmId: number;
  body: {
    group: GroupWrite;
  };
};
export type GetGroupsByGroupIdApiResponse = /** status 200 牧場のグループが返される */ GroupRead;
export type GetGroupsByGroupIdApiArg = {
  /** グループID */
  groupId: number;
};
export type PutGroupsByGroupIdApiResponse = unknown;
export type PutGroupsByGroupIdApiArg = {
  /** グループID */
  groupId: number;
  body: {
    group: GroupWrite;
  };
};
export type DeleteGroupsByGroupIdApiResponse = unknown;
export type DeleteGroupsByGroupIdApiArg = {
  /** グループID */
  groupId: number;
};
export type GetGroupsByGroupIdGroupUsersApiResponse = /** status 200 グループユーザー一覧が返される */ GroupUserRead[];
export type GetGroupsByGroupIdGroupUsersApiArg = {
  /** グループID */
  groupId: number;
};
export type PostGroupsByGroupIdGroupUsersApiResponse = unknown;
export type PostGroupsByGroupIdGroupUsersApiArg = {
  /** グループID */
  groupId: number;
  body: {
    group_user: GroupUserWrite;
  };
};
export type GetGroupUsersByUserIdApiResponse = /** status 200 グループユーザーが返される */ GroupUserRead;
export type GetGroupUsersByUserIdApiArg = {
  /** ユーザーID */
  userId: number;
};
export type PutGroupUsersByUserIdApiResponse = unknown;
export type PutGroupUsersByUserIdApiArg = {
  /** ユーザーID */
  userId: number;
  body: {
    group_user: GroupUserWrite;
  };
};
export type DeleteGroupUsersByUserIdApiResponse = unknown;
export type DeleteGroupUsersByUserIdApiArg = {
  /** ユーザーID */
  userId: number;
};
export type GetInvitedGroupsApiResponse = /** status 200 InvitedGroups */ InvitedGroups[];
export type GetInvitedGroupsApiArg = void;
export type PostInvitedGroupsApiResponse = unknown;
export type PostInvitedGroupsApiArg = {
  invitedGroups: InvitedGroups;
};
export type PutInvitedGroupsApiResponse = unknown;
export type PutInvitedGroupsApiArg = {
  invitedGroups: InvitedGroups;
};
export type DeleteInvitedGroupsApiResponse = unknown;
export type DeleteInvitedGroupsApiArg = void;
export type GetLinkageRequestsApiResponse = /** status 200 linkage_requests */ LinkageRequests[];
export type GetLinkageRequestsApiArg = void;
export type PostLinkageRequestsApiResponse = unknown;
export type PostLinkageRequestsApiArg = {
  linkageRequests: LinkageRequests;
};
export type PutLinkageRequestsApiResponse = unknown;
export type PutLinkageRequestsApiArg = {
  linkageRequests: LinkageRequests;
};
export type DeleteLinkageRequestsApiResponse = unknown;
export type DeleteLinkageRequestsApiArg = void;
export type GetGroupsByGroupIdRemindersApiResponse = /** status 200 申し送り一覧が返される */ ReminderRead[];
export type GetGroupsByGroupIdRemindersApiArg = {
  /** グループID */
  groupId: number;
};
export type PostGroupsByGroupIdRemindersApiResponse = unknown;
export type PostGroupsByGroupIdRemindersApiArg = {
  /** グループID */
  groupId: number;
  body: {
    reminder: ReminderWrite;
  };
};
export type GetGroupsByGroupIdRemindersAndReminderIdApiResponse = /** status 200 申し送りが返される */ ReminderRead;
export type GetGroupsByGroupIdRemindersAndReminderIdApiArg = {
  /** グループID */
  groupId: number;
  /** 申し送りID */
  reminderId: number;
};
export type PutGroupsByGroupIdRemindersAndReminderIdApiResponse = unknown;
export type PutGroupsByGroupIdRemindersAndReminderIdApiArg = {
  /** グループID */
  groupId: number;
  /** 申し送りID */
  reminderId: number;
  body: {
    reminder: ReminderWrite;
  };
};
export type DeleteGroupsByGroupIdRemindersAndReminderIdApiResponse = unknown;
export type DeleteGroupsByGroupIdRemindersAndReminderIdApiArg = {
  /** グループID */
  groupId: number;
  /** 申し送りID */
  reminderId: number;
};
export type GetGroupsByGroupIdTasksApiResponse = /** status 200 タスク一覧が返される */ TaskRead[];
export type GetGroupsByGroupIdTasksApiArg = {
  /** グループID */
  groupId: number;
};
export type PostGroupsByGroupIdTasksApiResponse = unknown;
export type PostGroupsByGroupIdTasksApiArg = {
  /** グループID */
  groupId: number;
  body: {
    task: TaskWrite;
  };
};
export type GetTasksByIdApiResponse = /** status 200 タスクが返される */ TaskRead;
export type GetTasksByIdApiArg = {
  /** タスクID */
  id: number;
};
export type PutTasksByIdApiResponse = unknown;
export type PutTasksByIdApiArg = {
  /** タスクID */
  id: number;
  body: {
    task: TaskWrite;
  };
};
export type DeleteTasksByIdApiResponse = unknown;
export type DeleteTasksByIdApiArg = {
  /** タスクID */
  id: number;
};
export type GetUsersApiResponse = /** status 200 ユーザー一覧が返される */ UserRead[];
export type GetUsersApiArg = void;
export type PostUsersApiResponse = unknown;
export type PostUsersApiArg = {
  body: {
    user: UserWrite;
  };
};
export type GetUsersByIdApiResponse = /** status 200 ユーザーが返される */ UserRead;
export type GetUsersByIdApiArg = {
  /** ユーザーID */
  id: number;
};
export type PutUsersByIdApiResponse = unknown;
export type PutUsersByIdApiArg = {
  /** ユーザーID */
  id: number;
  body: {
    user: UserWrite;
  };
};
export type DeleteUsersByIdApiResponse = unknown;
export type DeleteUsersByIdApiArg = {
  /** ユーザーID */
  id: number;
};
export type GetCurrentUserApiResponse = /** status 200 カレントユーザーが返される */ UserRead;
export type GetCurrentUserApiArg = {
  /** なりすましを無視して本来のユーザーを取得 */
  real?: boolean;
};
export type DeleteCurrentUserApiResponse = unknown;
export type DeleteCurrentUserApiArg = void;
export type GetCurrentUserFarmsApiResponse = /** status 200 カレントユーザーの牧場一覧が返される */ FarmRead[];
export type GetCurrentUserFarmsApiArg = void;
export type GetCurrentUserTasksApiResponse = /** status 200 カレントユーザーのタスク一覧が返される */ TaskRead[];
export type GetCurrentUserTasksApiArg = void;
export type GetCurrentUserGroupsApiResponse = /** status 200 カレントユーザーのグループ一覧が返される */ (GroupRead & {
  /** 承認ユーザ */
  approved_users?: (UserRead & {
    chat_user_id: string;
  })[];
  /** 牧場ID */
  farm_id?: number;
})[];
export type GetCurrentUserGroupsApiArg = void;
export type GetCurrentUserSettingApiResponse = /** status 200 カレントユーザーのユーザ設定一覧が返される */ UserSetting;
export type GetCurrentUserSettingApiArg = void;
export type PutCurrentUserSettingApiResponse = unknown;
export type PutCurrentUserSettingApiArg = {
  body: {
    user_setting: UserSetting;
  };
};
export type GetCurrentUserBookmarksApiResponse =
  /** status 200 カレントユーザーのブックマーク一覧が返される */ Bookmark[];
export type GetCurrentUserBookmarksApiArg = void;
export type PostCurrentUserBookmarksApiResponse =
  /** status 201 renders a JSON response with the new bookmarked message */ {
    id: number;
    message_id: string;
    bookmark_type: string;
    created_at: number;
  };
export type PostCurrentUserBookmarksApiArg = {
  body: {
    user_bookmark: {
      message_id: string;
      bookmark_type: string;
    };
  };
};
export type DeleteCurrentUserBookmarksByIdApiResponse = unknown;
export type DeleteCurrentUserBookmarksByIdApiArg = {
  id: number;
};
export type GetGroupsByGroupIdVisitsApiResponse = /** status 200 訪問履歴一覧が返される */ VisitRead[];
export type GetGroupsByGroupIdVisitsApiArg = {
  /** グループID */
  groupId: number;
};
export type PostGroupsByGroupIdVisitsApiResponse = unknown;
export type PostGroupsByGroupIdVisitsApiArg = {
  /** グループID */
  groupId: number;
  body: {
    visit: VisitWrite;
  };
};
export type GetVisitsByVisitsIdApiResponse = /** status 200 訪問履歴が返される */ VisitRead;
export type GetVisitsByVisitsIdApiArg = {
  /** 訪問ID */
  visitsId: number;
};
export type PutVisitsByVisitsIdApiResponse = unknown;
export type PutVisitsByVisitsIdApiArg = {
  /** 訪問ID */
  visitsId: number;
  body: {
    visit?: VisitWrite;
  };
};
export type DeleteVisitsByVisitsIdApiResponse = unknown;
export type DeleteVisitsByVisitsIdApiArg = {
  /** 訪問ID */
  visitsId: number;
};
export type GetGroupsByGroupIdGroupFeedDesignsApiResponse =
  /** status 200 グループ飼料設計一覧が返される */ GroupFeedDesignRead[];
export type GetGroupsByGroupIdGroupFeedDesignsApiArg = {
  /** グループID */
  groupId: number;
};
export type PostGroupsByGroupIdGroupFeedDesignsApiResponse = unknown;
export type PostGroupsByGroupIdGroupFeedDesignsApiArg = {
  /** グループID */
  groupId: number;
  body: {
    group_feed_design: GroupFeedDesignWrite;
  };
};
export type GetGroupFeedDesignsByGroupFeedDesignsIdApiResponse =
  /** status 200 グループ資料設計が返される */ GroupFeedDesignRead;
export type GetGroupFeedDesignsByGroupFeedDesignsIdApiArg = {
  /** グループ飼料設計ID */
  groupFeedDesignsId: number;
};
export type PutGroupFeedDesignsByGroupFeedDesignsIdApiResponse = unknown;
export type PutGroupFeedDesignsByGroupFeedDesignsIdApiArg = {
  /** グループ飼料設計ID */
  groupFeedDesignsId: number;
  body: {
    group_feed_design: GroupFeedDesignWrite;
  };
};
export type DeleteGroupFeedDesignsByGroupFeedDesignsIdApiResponse = unknown;
export type DeleteGroupFeedDesignsByGroupFeedDesignsIdApiArg = {
  /** グループ飼料設計ID */
  groupFeedDesignsId: number;
};
export type PatchChatChannelsByIdApiResponse = /** status 200 upload test */ {
  id: string;
  name: string;
  image: string;
  created_at: string;
  updated_at: string;
};
export type PatchChatChannelsByIdApiArg = {
  id: string;
  body: {
    channel: {
      image: Blob;
    };
  };
};
export type GetChatTokenApiResponse = /** status 200 OK */ ChatToken;
export type GetChatTokenApiArg = void;
export type GetChatEphemeralTokenApiResponse = /** status 200 renders a successful response */ {
  token: string;
};
export type GetChatEphemeralTokenApiArg = void;
export type GetFarmsByFarmIdUmCattleGroupsApiResponse = /** status 200 UMの牛群・牛房一覧を返す */ UmCattleGroup[];
export type GetFarmsByFarmIdUmCattleGroupsApiArg = {
  /** 牧場ID */
  farmId: number;
};
export type GetAppointmentsServicesApiResponse = /** status 200 自身の提供サービス一覧を取得 */ {
  id: number;
  name: string;
  description: string;
  duration_minutes: number;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
  };
}[];
export type GetAppointmentsServicesApiArg = void;
export type PostAppointmentsServicesApiResponse = /** status 201 サービスを作成 */ {
  id: number;
  name: string;
  description: string;
  duration_minutes: number;
  notification_text?: string;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
  };
};
export type PostAppointmentsServicesApiArg = {
  body: {
    appointment_service: {
      name: string;
      description: string;
      duration_minutes: number;
      notification_text?: string;
    };
  };
};
export type GetAppointmentsServicesByIdApiResponse = /** status 200 サービスの詳細を取得 */ {
  id: number;
  name: string;
  description: string;
  duration_minutes: number;
  notification_text?: string;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
  };
};
export type GetAppointmentsServicesByIdApiArg = {
  id: number;
};
export type PatchAppointmentsServicesByIdApiResponse =
  /** status 200 renders a JSON response with the appointment_service */ {
    id: number;
    name: string;
    description: string;
    duration_minutes: number;
    notification_text?: string;
    provider: {
      id: number;
      name: string;
      avatar: any | null;
      account: {
        id: number;
        name: string;
      };
    };
  };
export type PatchAppointmentsServicesByIdApiArg = {
  id: number;
  body: {
    appointment_service: {
      name: string;
      description: string;
      duration_minutes: number;
      notification_text?: string;
    };
  };
};
export type DeleteAppointmentsServicesByIdApiResponse = unknown;
export type DeleteAppointmentsServicesByIdApiArg = {
  id: number;
};
export type GetAppointmentsSlotsApiResponse = /** status 200 予約可能な予約枠の一覧を取得 */ {
  id: number;
  start_at: string;
  status: string;
  booked_at: any | null;
  appointment_service: any | null;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
    appointment_services: {
      id: number;
      name: string;
      description: string;
      duration_minutes: number;
    }[];
  };
  booked_by: any | null;
}[];
export type GetAppointmentsSlotsApiArg = void;
export type PostAppointmentsSlotsApiResponse = /** status 201 renders a JSON response with the new appointment_slot */ {
  id: number;
  start_at: string;
  status: string;
  booked_at: any | null;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
    appointment_services: {
      id: number;
      name: string;
      description: string;
      duration_minutes: number;
    }[];
  };
};
export type PostAppointmentsSlotsApiArg = {
  body: {
    appointment_slot: {
      start_at: string;
    };
  };
};
export type PatchAppointmentsSlotsByIdApiResponse =
  /** status 200 renders a JSON response with the appointment_slot */ {
    id: number;
    start_at: string;
    status: string;
    booked_at: any | null;
    provider: {
      id: number;
      name: string;
      avatar: any | null;
      account: {
        id: number;
        name: string;
      };
      appointment_services: {
        id: number;
        name: string;
        description: string;
        duration_minutes: number;
      }[];
    };
  };
export type PatchAppointmentsSlotsByIdApiArg = {
  id: number;
  body: {
    appointment_slot: {
      start_at: string;
    };
  };
};
export type DeleteAppointmentsSlotsByIdApiResponse = unknown;
export type DeleteAppointmentsSlotsByIdApiArg = {
  id: number;
};
export type GetAppointmentsApiResponse =
  /** status 200 自身の予約枠もしくは自身が予約を確定した予約枠の一覧を取得する。<br>
    `type: booked` で自身が確定させた予約枠を、`type: self` で自身が作成して誰かが確定した予約枠を返し、デフォルトは両方の確定済み予約枠を返す。<br>
    `history` を指定した場合は過去の確定済み予約枠を返し、指定がない場合は未来の確定済み予約枠を返す。<br>
    なお、過去の確定済み予約枠は現在から見て開始時刻が近い順( `start_at: :desc` )で、未来の予約枠も現在からみて開始時刻が近い順( `start_at: :asc` )である。  */ {
    id: number;
    start_at: string;
    status: string;
    booked_at: string;
    appointment_service: {
      id: number;
      name: string;
      description: string;
      duration_minutes: number;
    };
    provider: {
      id: number;
      name: string;
      avatar: any | null;
      account: {
        id: number;
        name: string;
      };
    };
    booked_by: {
      id: number;
      name: string;
      avatar: any | null;
      account: {
        id: number;
        name: string;
      };
    };
  }[];
export type GetAppointmentsApiArg = {
  history?: string;
  type?: string;
};
export type PatchAppointmentsByAppointmentSlotIdApiResponse = /** status 200 サービスを指定しつつ予約枠を確定させる */ {
  id: number;
  start_at: string;
  status: string;
  booked_at: string;
  appointment_service: {
    id: number;
    name: string;
    description: string;
    duration_minutes: number;
  };
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
  };
  booked_by: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
  };
};
export type PatchAppointmentsByAppointmentSlotIdApiArg = {
  appointmentSlotId: number;
  body: {
    appointment_service_id: number;
  };
};
export type PatchAppointmentsByAppointmentSlotIdCancelApiResponse = /** status 200 予約枠をキャンセルする */ {
  id: number;
  start_at: string;
  status: string;
  booked_at: any | null;
  appointment_service: any | null;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
  };
  booked_by: any | null;
};
export type PatchAppointmentsByAppointmentSlotIdCancelApiArg = {
  appointmentSlotId: number;
};
export type GetAppointmentsReservableServicesApiResponse = /** status 200 予約対象のサービスを取得 */ {
  id: number;
  name: string;
  description: string;
  duration_minutes: number;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
  };
}[];
export type GetAppointmentsReservableServicesApiArg = void;
export type GetAppointmentsReservableSlotsApiResponse = /** status 200 サービスに対応した予約可能な枠を取得 */ {
  id: number;
  start_at: string;
  status: string;
  booked_at: any | null;
  appointment_service: any | null;
  provider: {
    id: number;
    name: string;
    avatar: any | null;
    account: {
      id: number;
      name: string;
    };
    appointment_services: {
      id: number;
      name: string;
      description: string;
      duration_minutes: number;
    }[];
  };
  booked_by: any | null;
}[];
export type GetAppointmentsReservableSlotsApiArg = {
  appointmentServiceId?: number;
};
export type PostPushNotificationsTokenApiResponse = /** status 201 トークンを送信 */ {
  status: string;
};
export type PostPushNotificationsTokenApiArg = {
  body: {
    push_token: string;
    push_provider_name: string;
  };
};
export type DeletePushNotificationsTokenRemoveApiResponse = unknown;
export type DeletePushNotificationsTokenRemoveApiArg = {
  body: {
    push_token: string;
    push_provider_name: string;
  };
};
export type DeletePushNotificationsTokenRemoveAllApiResponse = unknown;
export type DeletePushNotificationsTokenRemoveAllApiArg = void;
export type PostPushConfigsApiResponse = /** status 201 push_configを作成 */ {
  id: number;
  user_id: number;
  notify_type: string;
};
export type PostPushConfigsApiArg = {
  body: {
    notify_type: number;
  };
};
export type PatchPushConfigsUpdatesApiResponse = /** status 200 push_configを更新 */ {
  id: number;
  user_id: number;
  notify_type: string;
};
export type PatchPushConfigsUpdatesApiArg = {
  body: {
    notify_type: number;
  };
};
export type GetPushConfigsExistApiResponse = /** status 200 push_configの存在チェック */ {
  id: number;
  user_id: number;
  notify_type: string;
};
export type GetPushConfigsExistApiArg = void;
export type GetNotificationsApiResponse = /** status 200 通知一覧を取得 */ {
  id: number;
  created_at: string;
  body: string;
  source_type: string;
  source_link: string;
  source_message_id: string;
  source_id: any | null;
  source_evented_at: any | null;
  source_channel_id: any | null;
  source_channel_is_dm: boolean;
  source_channel_type: string;
  user: {
    id: number;
    name: string;
    yomi: string;
    avatar: any | null;
  };
  source_user: any | null;
  source_channel: any | null;
}[];
export type GetNotificationsApiArg = {
  lastId?: number;
  withReaction?: number;
};
export type PatchNotificationsReadApiResponse = /** status 200 通知を既読にする */ {
  id: number;
  body: string;
  source_type: string;
  source_link: string;
  source_message_id: string;
  source_id: any | null;
  source_evented_at: any | null;
  source_channel_id: any | null;
  source_channel_is_dm: boolean;
  source_channel_type: string;
  user: {
    id: number;
    name: string;
    yomi: string;
    avatar: any | null;
  };
  source_user: any | null;
}[];
export type PatchNotificationsReadApiArg = {
  type?: string;
};
export type PatchNotificationsByIdApiResponse = /** status 200 通知を既読にする */ {
  id: number;
  body: string;
  source_type: string;
  source_link: string;
  source_message_id: string;
  source_id: any | null;
  source_evented_at: any | null;
  source_channel_id: any | null;
  source_channel_is_dm: boolean;
  source_channel_type: string;
  user: {
    id: number;
    name: string;
    yomi: string;
    avatar: any | null;
  };
  source_user: any | null;
};
export type PatchNotificationsByIdApiArg = {
  id: number;
};
export type PostPushMuteConfigsApiResponse = /** status 201 push_mute_configを作成 */ {
  id: number;
  target_id: string;
  target_type: string;
};
export type PostPushMuteConfigsApiArg = {
  body: {
    push_mute_config: {
      target_type: string;
      target_id: string;
    };
  };
};
export type GetPushMuteConfigsExistApiResponse = /** status 200 push_mute_configが存在したら取得 */ {
  id: number;
  target_id: string;
  target_type: string;
};
export type GetPushMuteConfigsExistApiArg = {
  targetId?: string;
  targetType?: string;
};
export type DeletePushMuteConfigsByIdApiResponse = unknown;
export type DeletePushMuteConfigsByIdApiArg = {
  id: number;
};
export type PostPushKeywordsApiResponse = /** status 201 push_keywordを作成 */ {
  id: number;
  user_id: number;
  keyword: string;
};
export type PostPushKeywordsApiArg = {
  body: {
    keyword: string;
  };
};
export type GetPushKeywordsExistApiResponse = /** status 200 push_keywordの存在チェック */ {
  id: number;
  user_id: number;
  keyword: string;
};
export type GetPushKeywordsExistApiArg = void;
export type PatchPushKeywordsUpdatesApiResponse = /** status 200 push_keywordを更新 */ {
  id: number;
  user_id: number;
  keyword: string;
};
export type PatchPushKeywordsUpdatesApiArg = {
  body: {
    keyword: string;
  };
};
export type PostUConciergeApiResponse = unknown;
export type PostUConciergeApiArg = {
  body: {
    message_id: string;
  };
};
export type AccountBasicProps = {
  /** 企業名 */
  name: string;
};
export type AccountBasicPropsRead = {
  id?: number;
  /** 企業名 */
  name: string;
};
export type AccountOptionalProps = {};
export type AccountOptionalPropsRead = {
  /** 契約頭数 */
  headcount_limit?: number;
};
export type Account = {
  '0'?: AccountBasicProps;
  '1'?: AccountOptionalProps;
};
export type AccountRead = {
  '0'?: AccountBasicPropsRead;
  '1'?: AccountOptionalPropsRead;
};
export type User = {
  /** メールアドレス */
  email?: string;
  /** ユーザ名 */
  name?: string;
  /** ユーザ名(よみ) */
  yomi?: string;
  /** アバター */
  avatar?: string;
  /** 認証方法(e-mail sms) */
  auth_method?: string;
  /** SMS認証用電話番号 */
  sms_phone_number?: string;
  /** ロール */
  roles?: string[];
  account?: AccountBasicProps;
  /** ユーザアカウント種別(U-motion ID Chumly ID) */
  user_account_type?: 'u-motion' | 'chumly';
};
export type UserRead = {
  id?: number;
  /** メールアドレス */
  email?: string;
  /** ユーザ名 */
  name?: string;
  /** ユーザ名(よみ) */
  yomi?: string;
  /** アバター */
  avatar?: string;
  /** 認証方法(e-mail sms) */
  auth_method?: string;
  /** SMS認証用電話番号 */
  sms_phone_number?: string;
  /** ロール */
  roles?: string[];
  account?: AccountBasicPropsRead;
  /** ユーザアカウント種別(U-motion ID Chumly ID) */
  user_account_type?: 'u-motion' | 'chumly';
};
export type UserWrite = {
  /** メールアドレス */
  email?: string;
  /** ユーザ名 */
  name?: string;
  /** ユーザ名(よみ) */
  yomi?: string;
  /** パスワード */
  password?: string;
  /** アバター */
  avatar?: string;
  /** 認証方法(e-mail sms) */
  auth_method?: string;
  /** SMS認証用電話番号 */
  sms_phone_number?: string;
  /** ロール */
  roles?: string[];
  account?: AccountBasicProps;
  /** ユーザアカウント種別(U-motion ID Chumly ID) */
  user_account_type?: 'u-motion' | 'chumly';
};
export type Additive = {
  user?: User;
  /** 添加剤追加日 */
  date?: number;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
};
export type AdditiveRead = {
  id?: number;
  user?: UserRead;
  /** 添加剤追加日 */
  date?: number;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
};
export type AdditiveWrite = {
  user?: UserWrite;
  /** 添加剤追加日 */
  date?: number;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
};
export type AppliedFeedDesign = {
  user?: User;
  /** feed_designs.id */
  feed_design_id?: number;
  /** 飼料設計適用日 */
  date?: string;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
};
export type AppliedFeedDesignRead = {
  id?: number;
  user?: UserRead;
  /** feed_designs.id */
  feed_design_id?: number;
  /** 飼料設計適用日 */
  date?: string;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
};
export type AppliedFeedDesignWrite = {
  user?: UserWrite;
  /** feed_designs.id */
  feed_design_id?: number;
  /** 飼料設計適用日 */
  date?: string;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
};
export type MilkingsByLactationNumber = {
  parity?: number;
  data?: {
    date?: string;
    amount?: number;
    cattle_count?: number;
  }[];
};
export type MilkingsByUmCattleGroup = {
  um_cattle_group_id?: number;
  um_cattle_group_name?: string;
  data?: {
    date?: string;
    amount?: number;
    cattle_count?: number;
  }[];
};
export type MilkingsStatistic = {
  has_milking_data?: boolean;
  min_date?: string;
  max_date?: string;
  min_amount?: number;
  max_amount?: number;
  updated_at?: string;
};
export type FarmDetail = {
  label?: string;
  phone_number?: string;
  address?: string;
  longitude?: string;
  latitude?: string;
};
export type Farm = {
  /** 牧場名 */
  name: string;
  /** 牧場オーナー */
  owner_name?: string;
  /** メモ */
  memo?: string;
  details?: FarmDetail[];
};
export type FarmRead = {
  id?: number;
  /** 牧場名 */
  name: string;
  /** 牧場オーナー */
  owner_name?: string;
  /** メモ */
  memo?: string;
  details?: FarmDetail[];
};
export type File = {
  id?: number;
  /** url */
  url?: string;
};
export type FeedDesign = {
  /** 作成者 */
  user?: User;
  /** 名称 */
  name?: string;
  /** メモ */
  memo?: string;
  /** ファイル */
  files?: File[];
};
export type FeedDesignRead = {
  id?: number;
  /** 作成者 */
  user?: UserRead;
  /** 名称 */
  name?: string;
  /** メモ */
  memo?: string;
  /** ファイル */
  files?: File[];
};
export type FeedDesignWrite = {
  /** 作成者 */
  user?: UserWrite;
  /** 名称 */
  name?: string;
  /** メモ */
  memo?: string;
  /** ファイル */
  files?: File[];
  /** 削除するファイルid */
  delete_file_ids?: number[];
};
export type GroupDetail = {
  /** 牛群,牛房の場合 */
  um_cattle_group?: {
    id?: number;
    /** 牛房 */
    pen?: string;
  };
  /** 月齢の場合 */
  month_age?: {
    from?: number;
    to?: number;
  };
};
export type Group = {
  /** グループ名 */
  name: string;
  /** グループ詳細 */
  details?: GroupDetail[];
  /** チャットのチームID */
  chat_team_id?: string;
  /** チャットのチャネルID */
  chat_channel_id?: string;
};
export type GroupRead = {
  id?: number;
  /** グループ名 */
  name: string;
  /** グループ詳細 */
  details?: GroupDetail[];
  /** チャットのチームID */
  chat_team_id?: string;
  /** チャットのチャネルID */
  chat_channel_id?: string;
  /** 承認ユーザ */
  approved_users?: UserRead[];
};
export type GroupWrite = {
  /** グループ名 */
  name: string;
  /** グループ詳細 */
  details?: GroupDetail[];
  /** チャットのチームID */
  chat_team_id?: string;
  /** チャットのチャネルID */
  chat_channel_id?: string;
  /** 作成者をメンバーにする */
  creator_in_members?: boolean;
};
export type GroupFeedDesign = {
  feed_design?: FeedDesign;
  group?: Group;
  user?: User;
};
export type GroupFeedDesignRead = {
  id?: number;
  feed_design?: FeedDesignRead;
  group?: GroupRead;
  user?: UserRead;
};
export type GroupFeedDesignWrite = {
  /** feed_designs.id */
  feed_design_id?: number;
  feed_design?: FeedDesignWrite;
  group?: GroupWrite;
  user?: UserWrite;
};
export type GroupActivity = {
  /** 活動日 */
  date?: number;
  /** 平均採食時間(分) */
  average_feed_min?: number;
  /** 平均動態時間(分) */
  average_move_min?: number;
  /** 平均横臥時間(分) */
  average_lie_min?: number;
  /** 平均起立時間(分) */
  average_stand_min?: number;
  /** 平均起立反芻時間(分) */
  average_rumination_stading_min?: number;
  /** 平均横臥反芻時間(分) */
  average_rumination_lying_min?: number;
};
export type ActivityStatistic = {
  has_activity_data?: boolean;
  min_date?: string;
  max_date?: string;
  updated_at?: string;
};
export type GroupMilkings = {
  id?: number;
  /** groups.id */
  group_id?: number;
  /** 搾乳日 */
  date?: number;
  /** 平均搾乳量 */
  average_amount?: number;
  created_at?: number;
  updated_at?: number;
};
export type GroupUser = {
  status?: string;
  roles?: string[];
  user?: User;
};
export type GroupUserRead = {
  id?: number;
  status?: string;
  roles?: string[];
  user?: UserRead;
};
export type GroupUserWrite = {
  status?: string;
  email?: string;
  sms_phone_number?: string;
  roles?: string[];
  user?: UserWrite;
};
export type InvitedGroups = {
  id?: number;
  /** guests.id */
  guest_id: number;
  /** groups.id */
  group_id: number;
  /** 招待ステータス waiting(承認待ち)  approved(承認済み)  rejected(拒否済み) */
  status?: string;
  created_at?: number;
  updated_at?: number;
};
export type LinkageRequests = {
  id?: number;
  /** farms.id */
  farm_id: number;
  /** U-motionとの連携コード(連携開始の依頼に使用) */
  linkage_code?: string;
  /** U-motionとの連携名(連携開始の依頼に使用) */
  request_name: string;
  /** 連携ステータス waiting(承認待ち)  approved(承認済み)  rejected(拒否済み) */
  status?: string;
  created_at?: number;
  updated_at?: number;
};
export type Reminder = {
  /** 内容 */
  memo?: string;
  /** チャットのスレットID */
  chat_thread_id?: string;
  /** 作成者 */
  user?: User;
};
export type ReminderRead = {
  id?: number;
  /** 内容 */
  memo?: string;
  /** チャットのスレットID */
  chat_thread_id?: string;
  /** 作成者 */
  user?: UserRead;
};
export type ReminderWrite = {
  /** 内容 */
  memo?: string;
  /** チャットのスレットID */
  chat_thread_id?: string;
  /** 作成者 */
  user?: UserWrite;
};
export type SubTask = {
  id?: number;
  /** 割当者(users.id) */
  assigned_user_id?: number;
  /** 作成者(users.id) */
  user_id?: number;
  /** タスクステータス(open in progress closed) */
  status?: string;
  /** 期限日時 */
  deadline?: string;
  /** 内容 */
  content?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
};
export type Task = {
  /** タスクステータス(open in progress closed) */
  status?: string;
  /** 期限日時 */
  deadline?: string;
  /** 内容 */
  content?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
  /** サブタスク */
  sub_tasks?: SubTask[];
  /** 割当者 */
  assigned_user?: User;
  /** 作成者 */
  user?: User;
};
export type TaskRead = {
  id?: number;
  /** タスクステータス(open in progress closed) */
  status?: string;
  /** 期限日時 */
  deadline?: string;
  /** 内容 */
  content?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
  /** サブタスク */
  sub_tasks?: SubTask[];
  /** 割当者 */
  assigned_user?: UserRead;
  /** 作成者 */
  user?: UserRead;
};
export type TaskWrite = {
  /** 割当者(users.id) */
  assigned_user_id?: number;
  /** タスクステータス(open in progress closed) */
  status?: string;
  /** 期限日時 */
  deadline?: string;
  /** 内容 */
  content?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
  /** サブタスク */
  sub_tasks?: SubTask[];
  /** 割当者 */
  assigned_user?: UserWrite;
  /** 作成者 */
  user?: UserWrite;
};
export type UserSetting = {
  details?: {
    search_history?: string[];
    bookmarks?: {
      type?: string;
      id?: string;
      created_at?: string;
    }[];
    pinnedChannels?: string[];
    notificationsTabLastOpenAt?: number;
    channel_background?: {
      additionalProperties?: string;
    };
    dashboard_columns?: {
      type:
        | 'activity_trend'
        | 'milkings_trend'
        | 'activity_graph'
        | 'milkings_graph'
        | 'chat_channel'
        | 'notifications'
        | 'add_columns';
      title: string;
      chatChannelId?: string;
    }[];
  };
};
export type Bookmark = {
  id: number;
  message_id: string;
  bookmark_type: string;
  created_at: number;
};
export type Visit = {
  /** 訪問日 */
  date?: string;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
  user?: User;
};
export type VisitRead = {
  id?: number;
  /** 訪問日 */
  date?: string;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
  user?: UserRead;
};
export type VisitWrite = {
  /** 訪問日 */
  date?: string;
  /** メモ */
  memo?: string;
  /** チャットのスレッドID */
  chat_thread_id?: number;
  user?: UserWrite;
};
export type ChatToken = {
  token?: string;
  chat_user_id?: string;
};
export type UmCattleGroup = {
  id?: number;
  name?: string;
  pens?: string;
};
export const {
  useGetAccountsQuery,
  usePutAccountsMutation,
  useGetGroupsByGroupIdAdditivesQuery,
  usePostGroupsByGroupIdAdditivesMutation,
  useGetAdditivesByAdditiveIdQuery,
  usePutAdditivesByAdditiveIdMutation,
  useDeleteAdditivesByAdditiveIdMutation,
  useGetGroupsByGroupIdAppliedFeedDesignsQuery,
  usePostGroupsByGroupIdAppliedFeedDesignsMutation,
  useGetAppliedFeedDesignsByIdQuery,
  usePutAppliedFeedDesignsByIdMutation,
  useDeleteAppliedFeedDesignsByIdMutation,
  useGetGroupsByGroupIdMilkingsQuery,
  useGetGroupsByGroupIdMilkingsParitiesQuery,
  useGetGroupsByGroupIdMilkingsUmCattleGroupsQuery,
  useGetGroupsByGroupIdMilkingsStatisticQuery,
  useGetFarmsQuery,
  usePostFarmsMutation,
  useGetFarmsByIdQuery,
  usePutFarmsByIdMutation,
  useDeleteFarmsByIdMutation,
  useGetFeedDesignsQuery,
  usePostFeedDesignsMutation,
  useGetFeedDesignsByIdQuery,
  usePutFeedDesignsByIdMutation,
  useDeleteFeedDesignsByIdMutation,
  useGetFeedDesignsByFeedDesignsIdGroupsQuery,
  useGetFeedDesignsByFeedDesignsIdGroupFeedDesignsQuery,
  useGetGroupsByGroupIdGroupActivitiesQuery,
  useGetGroupsByGroupIdActivitiesStatisticQuery,
  useGetGroupMilkingsQuery,
  usePostGroupMilkingsMutation,
  usePutGroupMilkingsMutation,
  useDeleteGroupMilkingsMutation,
  useGetFarmsByFarmIdGroupsQuery,
  usePostFarmsByFarmIdGroupsMutation,
  useGetGroupsByGroupIdQuery,
  usePutGroupsByGroupIdMutation,
  useDeleteGroupsByGroupIdMutation,
  useGetGroupsByGroupIdGroupUsersQuery,
  usePostGroupsByGroupIdGroupUsersMutation,
  useGetGroupUsersByUserIdQuery,
  usePutGroupUsersByUserIdMutation,
  useDeleteGroupUsersByUserIdMutation,
  useGetInvitedGroupsQuery,
  usePostInvitedGroupsMutation,
  usePutInvitedGroupsMutation,
  useDeleteInvitedGroupsMutation,
  useGetLinkageRequestsQuery,
  usePostLinkageRequestsMutation,
  usePutLinkageRequestsMutation,
  useDeleteLinkageRequestsMutation,
  useGetGroupsByGroupIdRemindersQuery,
  usePostGroupsByGroupIdRemindersMutation,
  useGetGroupsByGroupIdRemindersAndReminderIdQuery,
  usePutGroupsByGroupIdRemindersAndReminderIdMutation,
  useDeleteGroupsByGroupIdRemindersAndReminderIdMutation,
  useGetGroupsByGroupIdTasksQuery,
  usePostGroupsByGroupIdTasksMutation,
  useGetTasksByIdQuery,
  usePutTasksByIdMutation,
  useDeleteTasksByIdMutation,
  useGetUsersQuery,
  usePostUsersMutation,
  useGetUsersByIdQuery,
  usePutUsersByIdMutation,
  useDeleteUsersByIdMutation,
  useGetCurrentUserQuery,
  useDeleteCurrentUserMutation,
  useGetCurrentUserFarmsQuery,
  useGetCurrentUserTasksQuery,
  useGetCurrentUserGroupsQuery,
  useGetCurrentUserSettingQuery,
  usePutCurrentUserSettingMutation,
  useGetCurrentUserBookmarksQuery,
  usePostCurrentUserBookmarksMutation,
  useDeleteCurrentUserBookmarksByIdMutation,
  useGetGroupsByGroupIdVisitsQuery,
  usePostGroupsByGroupIdVisitsMutation,
  useGetVisitsByVisitsIdQuery,
  usePutVisitsByVisitsIdMutation,
  useDeleteVisitsByVisitsIdMutation,
  useGetGroupsByGroupIdGroupFeedDesignsQuery,
  usePostGroupsByGroupIdGroupFeedDesignsMutation,
  useGetGroupFeedDesignsByGroupFeedDesignsIdQuery,
  usePutGroupFeedDesignsByGroupFeedDesignsIdMutation,
  useDeleteGroupFeedDesignsByGroupFeedDesignsIdMutation,
  usePatchChatChannelsByIdMutation,
  useGetChatTokenQuery,
  useGetChatEphemeralTokenQuery,
  useGetFarmsByFarmIdUmCattleGroupsQuery,
  useGetAppointmentsServicesQuery,
  usePostAppointmentsServicesMutation,
  useGetAppointmentsServicesByIdQuery,
  usePatchAppointmentsServicesByIdMutation,
  useDeleteAppointmentsServicesByIdMutation,
  useGetAppointmentsSlotsQuery,
  usePostAppointmentsSlotsMutation,
  usePatchAppointmentsSlotsByIdMutation,
  useDeleteAppointmentsSlotsByIdMutation,
  useGetAppointmentsQuery,
  usePatchAppointmentsByAppointmentSlotIdMutation,
  usePatchAppointmentsByAppointmentSlotIdCancelMutation,
  useGetAppointmentsReservableServicesQuery,
  useGetAppointmentsReservableSlotsQuery,
  usePostPushNotificationsTokenMutation,
  useDeletePushNotificationsTokenRemoveMutation,
  useDeletePushNotificationsTokenRemoveAllMutation,
  usePostPushConfigsMutation,
  usePatchPushConfigsUpdatesMutation,
  useGetPushConfigsExistQuery,
  useGetNotificationsQuery,
  usePatchNotificationsReadMutation,
  usePatchNotificationsByIdMutation,
  usePostPushMuteConfigsMutation,
  useGetPushMuteConfigsExistQuery,
  useDeletePushMuteConfigsByIdMutation,
  usePostPushKeywordsMutation,
  useGetPushKeywordsExistQuery,
  usePatchPushKeywordsUpdatesMutation,
  usePostUConciergeMutation,
} = injectedRtkApi;
