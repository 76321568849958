import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';
import type { PayloadAction } from '@reduxjs/toolkit';

export type ChatBotQuickActionState = {
  chatBotQuickAction?: boolean;
  hasOpenedModal?: boolean;
};

const slice = createSlice({
  name: 'chatBotQuickAction',
  initialState: {
    chatBotQuickAction: false,
    hasOpenedModal: false,
  } as ChatBotQuickActionState,
  reducers: {
    setChatBotQuickAction: (state, { payload }: PayloadAction<boolean>) => {
      state.chatBotQuickAction = payload;
    },
    setHasOpenedModal: (state, action) => {
      state.hasOpenedModal = action.payload;
    },
  },
});

export const { setChatBotQuickAction, setHasOpenedModal } = slice.actions;

export const chatBotQuickActionSelector = (state: RootState) => {
  return state.chatBotQuickAction;
};

export default slice.reducer;
