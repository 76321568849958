import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Box, VStack } from '@gluestack-ui/themed-native-base';
import * as React from 'react';
import { Skeleton } from '~/components/Skeleton';

import { useMilkAmountByRange } from '~/hooks/useMilkAmountByRange';
import { VictoryChart, VictoryTheme, VictoryAxis, VictoryBar, VictoryLegend } from '~/lib/Victory';
import type { StyledProps } from 'native-base';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  onTypeChange?: (type: string) => void;
}

export const MilkAmountUpDownChart: React.FC<Props & StyledProps> = React.memo(
  ({ endDate, range, groupId, isPreview = false, width = 400, height = 400, barWidth = 8 }) => {
    // @ts-expect-error
    const { setEndDate, setRange, milkings, preMilkings, milkingsMA } = useMilkAmountByRange(groupId, endDate, range);

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    const Chart = React.useMemo(
      () => (
        <VictoryChart
          width={420}
          height={140}
          theme={VictoryTheme.material}
          domainPadding={{ x: [10, 10], y: [0, 0] }}
          padding={{ top: 5, bottom: 25, left: 50, right: 50 }}
          maxDomain={{ y: 3 }}
          minDomain={{ y: -3 }}
        >
          <VictoryAxis dependentAxis tickFormat={(y) => y} />
          <VictoryLegend title="5日間平均との差(kg)" centerTitle x={170} y={100} data={[]} />
          <VictoryBar
            data={
              milkings.data?.map((cur, index) => {
                return {
                  x: cur.date,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  y: cur.amount - milkingsMA?.data?.[index]?.amount,
                };
              }) ?? []
            }
            style={{
              data: {
                stroke: ({ datum }) => (datum.y < 0 ? '#F47C7C' : '#A1DE93'),
                strokeWidth: 4,
              },
            }}
          />
        </VictoryChart>
      ),
      [milkings, milkingsMA, preMilkings]
    );

    if (milkings?.isLoading || milkingsMA?.isLoading) {
      return <Skeleton height={height} />;
    }

    if (isPreview) {
      return <VStack>{Chart}</VStack>;
    }

    return (
      <>
        <Box paddingX="auto">{Chart}</Box>
      </>
    );
  }
);
