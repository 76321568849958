const regexPatterns = {
  groupList: /^\/farms\/\d+\/groups$/,
  groupDetail: /^\/farms\/\d+\/groups\/\d+$/,
};

interface PramsProps {
  beginDate?: string;
  endDate?: string;
  farmId?: string;
  groupId?: string;
  range?: string;
  type?: string;
  target?: string;
}

export const generateNavigateParameterFromPath = (path: string | undefined) => {
  const pathArray = path?.split('/');
  let screenName = '';
  let params: Record<string, string[] | unknown> = {};

  //https://github.com/u-motion/u-feed-app/blob/main/docs/specs/UrlScheme.md
  switch (true) {
    // グループ一覧
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.groupList.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], farmId: pathArray[2] };
      screenName = 'GroupList';
      break;
    //　グループ詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case regexPatterns.groupDetail.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      params = { farmId: pathArray[2], groupId: pathArray[4] };
      screenName = 'GroupDetail';
      break;
    //　活動量グラフ詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/graphs\/activity\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const queryParamStr = pathArray[9].indexOf('?') ? pathArray[9].substring(pathArray[9].indexOf('?') + 1) : '';
      const queryParams = queryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        range: queryParams.find((param) => param.key === 'range')?.value,
        type: queryParams.find((param) => param.key === 'type')?.value,
      };
      screenName = 'ActivityGraphs';

      break;
    //　搾乳量グラフ詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/graphs\/milk_amount\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const milkAmountQueryParamStr = pathArray[9].indexOf('?')
        ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          pathArray[9].substring(pathArray[9].indexOf('?') + 1)
        : '';
      const milkAmountQueryParams = milkAmountQueryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        range: milkAmountQueryParams.find((param) => param.key === 'range')?.value,
        type: milkAmountQueryParams.find((param) => param.key === 'type')?.value,
      };
      screenName = 'MilkAmountGraphs';

      break;
    //　活動量ダッシュボード詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/dashboards\/activity\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const activityDashboardsQueryParamStr = pathArray[9].indexOf('?')
        ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          pathArray[9].substring(pathArray[9].indexOf('?') + 1)
        : '';
      const activityDashboardsQueryParams = activityDashboardsQueryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        target: activityDashboardsQueryParams.find((param) => param.key === 'target')?.value,
      };
      screenName = 'ActivityDashboard';

      break;
    //　搾乳量ダッシュボード詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/farms\/\d+\/groups\/\d+\/dashboards\/milk_amount\/\d+\d+\d+/.test(path):
      // @ts-expect-error TS(2532): Object is possibly 'undefined'.
      const milkAmoutDashboardsQueryParamStr = pathArray[9].indexOf('?')
        ? // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          pathArray[9].substring(pathArray[9].indexOf('?') + 1)
        : '';
      const milkAmoutDashboardsQueryParams = milkAmoutDashboardsQueryParamStr?.split('&')?.map((param) => {
        return { key: param.split('=')[0], value: param.split('=')[1] };
      });

      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        farmId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        groupId: pathArray[4],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        endDate: `${pathArray[7]}${pathArray[8]}${pathArray[9].substring(0, 2)}`,
        target: milkAmoutDashboardsQueryParams.find((param) => param.key === 'target')?.value,
      };
      screenName = 'MilkAmoutDashboard';

      break;
    //　企業詳細
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/accounts\/\d+$/.test(path):
      params = {};
      screenName = 'AccountDetail';
      break;
    // チャットチャンネル画面
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/(chat|dm)\/[0-9a-zA-Z\-_]+\/[0-9a-zA-Z\-_]+$/.test(path):
      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        channelId: pathArray[2],
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        messageId: pathArray[3],
      };
      screenName = 'ChatChannelMessages';
      break;
    // チャンネルのみのURL
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    case /^\/(chat|dm)\/[0-9a-zA-Z\-_]+$/.test(path):
      params = {
        // @ts-expect-error TS(2532): Object is possibly 'undefined'.
        channelId: pathArray[2],
      };
      screenName = 'ChatChannelMessages';
      break;
    default:
      break;
  }

  return { screenName, params };
};
