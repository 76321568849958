import { useNavigation } from '@react-navigation/native';
import { Box, VStack, HStack, Text, Button } from 'native-base';
import { useState, useCallback } from 'react';
import type { Cow } from '~/packages/u-motion-api/api/cowAutoGeneratedApi';
import { useFetchFarmCurrentQuery } from '~/packages/u-motion-api/api/farmApi';
import type { StackNavigationProp } from '@react-navigation/stack';

export const PreviewCattlePedigreeParams = ({ data }: any) => {
  const [isPosted, setIsPosted] = useState(false);
  const navigation = useNavigation<StackNavigationProp<any>>();
  const farmCurrentQuery = useFetchFarmCurrentQuery();

  if (!data) return null;

  const onRegisterPress = async () => {
    navigation.navigate('UmotionCowRegisterModal', {
      cow: convertToUmotionCowRegisterData(data),
    });
  };

  const onSwitchFarmPress = async () => {
    navigation.navigate('UmotionSwitchFarmModal');
  };

  const PedigreeParam = useCallback(({ label, value }: { label: string; value: string }) => {
    return (
      <HStack>
        <Text width="50%" fontWeight="bold">
          {label}
        </Text>
        <Text width="50%">{value}</Text>
      </HStack>
    );
  }, []);

  return (
    <VStack space="md">
      <HStack alignItems="center" justifyContent="space-between" space="md">
        <HStack space="sm">
          <Text bold>牧場:</Text>
          <Text>{farmCurrentQuery?.data?.farmName}</Text>
        </HStack>
        <Button
          onPress={onSwitchFarmPress}
          variant="outline"
          borderRadius="8px"
          backgroundColor="#ffffff"
          colorScheme="orange"
          borderColor="#d9d9d9"
        >
          牧場切替
        </Button>
      </HStack>
      {!isPosted && (
        <Button onPress={onRegisterPress} backgroundColor="#FF9A47" borderRadius="8px">
          U-motionに登録
        </Button>
      )}
      <Box backgroundColor="white" borderRadius={20} padding={4} marginBottom={4}>
        <VStack space={4}>
          <PedigreeParam label="名前" value={data.name} />
          <PedigreeParam label="牛番号" value={data.cowNo} />
          <PedigreeParam label="個体識別番号" value={data.cowUid} />
          <PedigreeParam label="生年月日" value={data.birthday} />
          <PedigreeParam label="導入日" value={data.introduceDate} />
          <PedigreeParam label="性別" value={data.gender} />
          <PedigreeParam label="父牛の名前" value={data.fatherName} />
          <PedigreeParam label="母牛の名前" value={data.motherName} />
          <PedigreeParam label="母父牛の名前" value={data.maternalGrandfatherName} />
          <PedigreeParam label="母母牛の名前" value={data.maternalGrandmotherName} />
          <PedigreeParam label="母母父牛の名前" value={data.maternalGreatGrandfatherName} />
        </VStack>
      </Box>
    </VStack>
  );
};

export interface OCRCattleData {
  cowNo: string;
  cowUid: string;
  birthday: string;
  name?: string;
  breedingPoint?: string | null;
  cowGroupId?: number | null;
  cowGroupName?: string | null;
  gender: '雄' | '雌';
  introduceDate?: string | null;
  birthNumber?: string | null;
  pen?: string | null;
  penName?: string | null;
  producingArea?: string | null;
  producingFarmName?: string | null;
  registrationNo?: string | null;
  // 三代祖
  fatherName?: string;
  fatherRegistrationNo?: string;
  motherName?: string;
  motherRegistrationNo?: string;
  maternalGrandfatherName?: string; // 母父
  maternalGrandfatherRegistrationNumber?: string;
  maternalGrandmotherName?: string; // 母母
  maternalGrandmotherRegistrationNumber?: string;
  maternalGreatGrandfatherName?: string; // 母母父
  maternalGreatGrandfatherRegistrationNumber?: string;
}

const convertToUmotionCowRegisterData = (ocrData: OCRCattleData): Cow => {
  return {
    cowNo: ocrData?.cowNo,
    cowName: ocrData?.name,
    cowUid: ocrData?.cowUid,
    birthday: ocrData?.birthday,
    introduceDate: ocrData?.introduceDate || '',
    gender: ocrData?.gender,
    cowGroupId: ocrData?.cowGroupId || 0,
    pen: ocrData?.pen || '',
    fatherName: ocrData?.fatherName || '',
    motherName: ocrData?.motherName || '',
    maternalGrandfatherName: ocrData?.maternalGrandfatherName || '',
    maternalGrandmotherName: ocrData?.maternalGrandmotherName,
    maternalGreatGrandfatherName: ocrData?.maternalGreatGrandfatherName,
    birthNumber: ocrData?.birthNumber || '',
    producingArea: ocrData?.producingArea || '',
    producingFarmName: ocrData?.producingFarmName || '',
    breedingPoint: ocrData?.breedingPoint || '',
    registrationNumber: ocrData?.registrationNo || '',
  };
};
