import { HStack, Spinner, VStack } from '@gluestack-ui/themed-native-base';
import { SearchIndex } from 'emoji-mart';
import normalizeUrl from 'normalize-url';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Attachment,
  Channel,
  MessageInput,
  MessageList,
  renderText,
  ScrollToBottomButton,
  Thread,
  Window,
} from 'stream-chat-react';
import { channelType, getStreamChatChannelTypeById } from '../ChatChannelMessagesScreen/channelType';
import { ChatCustomThreadInput, CustomMessageAvatar } from '~/components';
import { ChatCustomMessage } from '~/components/ChatCustomMessage';
import { ChatCustomPinIndicator } from '~/components/ChatCustomPinIndicator';
import ChatCustomReactionSelectorWeb from '~/components/ChatCustomReactionSelectorWeb.web';
import ChatCustomReactionsListWeb from '~/components/ChatCustomReactionsListWeb';
import { anchorComponent } from '~/components/CustomAnchorComponent.web';
import { CustomSuggestionItem } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionItem.web';
import { CustomSuggestionList } from '~/components/CustomAutoCompleteTextArea/CustomSuggestionList.web';
import { CustomDateSeparator } from '~/components/CustomDateSeparator';

import { CustomHeader } from '~/components/CustomHeader.web';
import { CustomMessageActionsListWeb } from '~/components/CustomMessageActionsListWeb';
import CustomMessageOptions from '~/components/CustomMessageOptions.web';
import CustomMessageStatus from '~/components/CustomMessageStatus.web';
import { CustomThreadHeader } from '~/components/CustomThreadHeader';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';
import { useDraft } from '~/hooks/useDraft.web';
import { useShouldSutmit } from '~/hooks/useShouldSutmit';
import type { MutableRefObject } from 'react';
import type { Message, Channel as StreamChannel, UserResponse } from 'stream-chat';
import type {
  AttachmentProps,
  MessageToSend,
  ThreadHeaderProps,
  CustomMessageActionsListProps,
} from 'stream-chat-react';

type MessageInputWithContextProps = {
  overrideSubmitHandler: (
    message: MessageToSend,
    channelCid: string,
    customMessageData?: Partial<Message>
  ) => Promise<void>;
  shouldSubmit: (event: any) => boolean;
  setIsMarkdownRef: (value: boolean) => void;
};

const MessageInputWithContext = ({
  overrideSubmitHandler,
  shouldSubmit,
  setIsMarkdownRef,
}: MessageInputWithContextProps) => {
  const CustomChannelInputWrapper = useCallback(
    (props: any) => <ChatCustomThreadInput {...props} setIsMarkdownRef={setIsMarkdownRef} />,
    []
  );

  return (
    <MessageInput
      Input={CustomChannelInputWrapper}
      overrideSubmitHandler={overrideSubmitHandler}
      shouldSubmit={shouldSubmit}
      grow
      mentionQueryParams={{
        options: { limit: 100 },
      }}
    />
  );
};

const MemorizedMessageInput = memo(MessageInputWithContext, (prev, next) => {
  return prev.overrideSubmitHandler === next.overrideSubmitHandler && prev.shouldSubmit === next.shouldSubmit;
});

const MessageInputWrapper = () => {
  const { overrideSubmitHandler, setIsMarkdownRef } = useDraft();
  const { shouldSubmit } = useShouldSutmit();

  return (
    <MemorizedMessageInput
      overrideSubmitHandler={overrideSubmitHandler}
      shouldSubmit={shouldSubmit}
      setIsMarkdownRef={setIsMarkdownRef}
    />
  );
};

const ThreadWithContext = ({
  overrideSubmitHandler,
  shouldSubmit,
  isCheckedRef,
  toggleCheckedRef,
  uncheckedRef,
  setIsMarkdownRef,
}: MessageInputWithContextProps & {
  isCheckedRef: MutableRefObject<boolean>;
  toggleCheckedRef: () => void;
  uncheckedRef: () => void;
}) => {
  const CustomThreadInputWrapper = useCallback(
    () => (
      <ChatCustomThreadInput
        isChecked={isCheckedRef.current}
        toggleChecked={toggleCheckedRef}
        setIsMarkdownRef={setIsMarkdownRef}
        unchecked={uncheckedRef}
        isThread
      />
    ),
    []
  );

  return (
    <Thread
      Input={CustomThreadInputWrapper}
      additionalMessageInputProps={{
        overrideSubmitHandler,
        shouldSubmit,
        grow: true,
        mentionQueryParams: {
          options: { limit: 100 },
        },
      }}
    />
  );
};

const MemorizedThreadInput = memo(ThreadWithContext, (prev, next) => {
  return prev.overrideSubmitHandler === next.overrideSubmitHandler && prev.shouldSubmit === next.shouldSubmit;
});

const ThreadWrapper = () => {
  const { overrideSubmitHandler, isCheckedRef, toggleCheckedRef, uncheckedRef, setIsMarkdownRef } = useDraft();
  const { shouldSubmit } = useShouldSutmit();

  return (
    <MemorizedThreadInput
      overrideSubmitHandler={overrideSubmitHandler}
      shouldSubmit={shouldSubmit}
      isCheckedRef={isCheckedRef}
      toggleCheckedRef={toggleCheckedRef}
      uncheckedRef={uncheckedRef}
      setIsMarkdownRef={setIsMarkdownRef}
    />
  );
};

const ChannelWindow = ({ width }: { width: number }) => {
  const customRenderText = useCallback(
    (text?: string, mentionedUsers?: UserResponse[]) => {
      return renderText(text, mentionedUsers, {
        customMarkDownRenderers: { a: anchorComponent },
      });
    },
    [anchorComponent]
  );

  return (
    <HStack width={width}>
      <Window hideOnThread>
        <MessageList messageLimit={20} renderText={customRenderText} />
        <MessageInputWrapper />
      </Window>
      <ThreadWrapper />
    </HStack>
  );
};

const renderAttachment = (props: AttachmentProps) => {
  // Remove duplicate attachments by title and title_link
  const uniqueAttachments = props.attachments.filter(
    (item, index, self) =>
      !item.og_scrape_url || // affects only OGAttachment
      index ===
        self.findIndex(
          (t) =>
            !!t.og_scrape_url && // comparison target must be OGAttachment
            t.title === item.title &&
            (t.title_link ? normalizeUrl(t.title_link) : '') === (item.title_link ? normalizeUrl(item.title_link) : '')
        )
  );
  return <Attachment {...props} attachments={uniqueAttachments} />;
};

const CustomEditInputWrapper = () => <ChatCustomThreadInput isEdit />;

const ChannelColumnInner = ({ channel, width }: { channel: StreamChannel; width: number }) => {
  const isDM = useMemo(() => channelType(channel?.cid ?? '') === 'DM', [channel]);
  const { removePinnedChannel } = usePinnedChannels();

  const renderMessageActionList = useCallback(
    (props: CustomMessageActionsListProps) => {
      return <CustomMessageActionsListWeb {...props} isDM={isDM} />;
    },
    [isDM]
  );

  const ThreadHeaderCustom = useCallback(
    (props: ThreadHeaderProps) => {
      return <CustomThreadHeader closeThread={props.closeThread} thread={props.thread} channel={channel} />;
    },
    [channel]
  );

  const renderHeader = useCallback(
    () => <CustomHeader channelSelected={channel} multiSection onClose={() => removePinnedChannel(channel.cid)} />,
    [channel, removePinnedChannel]
  );

  return (
    <Channel
      channel={channel}
      channelQueryOptions={{
        messages: {
          limit: 20,
        },
      }}
      Avatar={CustomMessageAvatar}
      EditMessageInput={CustomEditInputWrapper}
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<Omit<any, "ref"> &... Remove this comment to see the full error message
      ReactionSelector={ChatCustomReactionSelectorWeb}
      ReactionsList={ChatCustomReactionsListWeb}
      // @ts-expect-error TS(2322): Type '(props: Props) => React.JSX.Element' is not ... Remove this comment to see the full error message
      AutocompleteSuggestionList={CustomSuggestionList}
      // @ts-expect-error TS(2322): Type 'ForwardRefExoticComponent<CustomSuggestionIt... Remove this comment to see the full error message
      AutocompleteSuggestionItem={CustomSuggestionItem}
      Message={ChatCustomMessage}
      MessageNotification={ScrollToBottomButton}
      CustomMessageActionsList={renderMessageActionList}
      MessageTimestamp={() => <></>}
      MessageOptions={CustomMessageOptions}
      Input={ChatCustomThreadInput}
      Attachment={renderAttachment}
      HeaderComponent={renderHeader}
      ThreadHeader={ThreadHeaderCustom}
      emojiSearchIndex={SearchIndex}
      DateSeparator={CustomDateSeparator}
      UnreadMessagesSeparator={undefined}
      PinIndicator={ChatCustomPinIndicator}
      MessageStatus={CustomMessageStatus}
    >
      <ChannelWindow width={width} />
    </Channel>
  );
};

export const ChannelColumnWithId = ({ width, channelId }: { width: number; channelId: string }) => {
  const { chatClient } = useStreamChatAuthContext();
  const [channel, setChannel] = useState<StreamChannel | undefined>();

  useEffect(() => {
    const initChannel = async (id: string) => {
      const channelId = id?.split(':').slice(-1).join('');
      const channelObj = chatClient?.channel(getStreamChatChannelTypeById(id), channelId);

      if (!channelObj?.initialized) {
        await channelObj.watch();
      }

      setChannel(channelObj);
    };

    initChannel(channelId);
  }, [channelId]);

  if (!channel) {
    return (
      <HStack width={width} height="100%" backgroundColor="surfaceLow" justifyContent="center" alignItems="center">
        <Spinner color="primary" />
      </HStack>
    );
  }

  return (
    <HStack width={width} height="100%" flexGrow={0}>
      <VStack height="100%">
        <ChannelColumnInner channel={channel} width={width} />
      </VStack>
    </HStack>
  );
};
