import { Skeleton as GluestackSkeleton } from '@gluestack-ui/themed-native-base';
import type { ComponentProps } from 'react';

export const Skeleton = (props: ComponentProps<typeof GluestackSkeleton>): React.ReactElement => {
  return <GluestackSkeleton startColor="surfaceHighest" endColor="outline" {...props} height={60} />;
};

export const SkeletonText = (props: ComponentProps<typeof GluestackSkeleton.Text>): React.ReactElement => {
  return <GluestackSkeleton.Text startColor="surfaceHighest" endColor="outline" {...props} height={60} />;
};

Skeleton.Text = SkeletonText;
