import { useCallback } from 'react';
import { DateSeparator, useTranslationContext } from 'stream-chat-react';
import { getDateFormat } from './utils';
import type { DateSeparatorProps } from 'stream-chat-react';

export const CustomDateSeparator = (props: DateSeparatorProps) => {
  const { tDateTimeParser } = useTranslationContext();
  const { formatDate: _, ...rest } = props;
  const formatDate = useCallback(
    (date: Date) => {
      const dateFormat = getDateFormat(date);
      return tDateTimeParser(date).format(dateFormat);
    },
    [tDateTimeParser]
  );
  return <DateSeparator position="center" formatDate={formatDate} {...rest} />;
};
