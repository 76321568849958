import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState, useRef } from 'react';
import { useGetChatEphemeralTokenQuery } from '~/api/uFeedApi';

export const useOpenAIRealtimeVoice = () => {
  const {
    data: ephemeralToken,
    isLoading: isEphemeralTokenLoading,
    isSuccess: isEphemeralTokenSuccess,
  } = useGetChatEphemeralTokenQuery();

  const [isOpen, setIsOpen] = useState(false);

  useDidUpdate(() => {
    if (isEphemeralTokenSuccess) {
      console.log('isEphemeralTokenSuccess', ephemeralToken);
    }
  }, [isEphemeralTokenSuccess]);

  const pcRef = useRef<RTCPeerConnection | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);

  const init = async () => {
    // Get an ephemeral key from your server - see server code below
    const EPHEMERAL_KEY = ephemeralToken?.token;

    // Create a peer connection
    const pc = new RTCPeerConnection();

    // Set up to play remote audio from the model
    const audioEl = document.createElement('audio');
    audioEl.autoplay = true;
    pc.ontrack = (e) => (audioEl.srcObject = e.streams[0]);

    // Add local audio track for microphone input in the browser
    const ms = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    const audioTrack = ms.getTracks()[0];
    pc.addTrack(audioTrack);

    // Set up data channel for sending and receiving events
    const dc = pc.createDataChannel('oai-events');
    dc.addEventListener('message', (e) => {
      // Realtime server events appear here!
      console.log(e);
    });

    // Start the session using the Session Description Protocol (SDP)
    const offer = await pc.createOffer();
    await pc.setLocalDescription(offer);

    const baseUrl = 'https://api.openai.com/v1/realtime';
    const model = 'gpt-4o-realtime-preview-2024-12-17';
    const sdpResponse = await fetch(`${baseUrl}?model=${model}`, {
      method: 'POST',
      body: offer.sdp,
      headers: {
        Authorization: `Bearer ${EPHEMERAL_KEY}`,
        'Content-Type': 'application/sdp',
      },
    });

    const answer = {
      type: 'answer',
      sdp: await sdpResponse.text(),
    };
    await pc.setRemoteDescription(answer);

    // PCとMediaStreamの参照を保存
    pcRef.current = pc;
    mediaStreamRef.current = ms;

    setIsOpen(true);
  };

  const stop = () => {
    if (mediaStreamRef.current) {
      // すべてのメディアトラックを停止
      mediaStreamRef.current.getTracks().forEach((track) => {
        track.stop();
      });
      mediaStreamRef.current = null;
    }

    if (pcRef.current) {
      // WebRTC接続を閉じる
      pcRef.current.close();
      pcRef.current = null;
    }

    setIsOpen(false);
  };

  return { init, stop, isOpen };
};
