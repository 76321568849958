import { VStack, Link, Box, Text } from '@gluestack-ui/themed-native-base';
import normalizeUrl from 'normalize-url';
import { useState } from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { Attachment, Modal, ModalGallery, isUploadedImage, isScrapedContent } from '~/lib/StreamChatReact';
import type { AttachmentProps, DefaultStreamChatGenerics } from '~/lib/StreamChatReact';

export const CustomAttachments = (props: AttachmentProps<DefaultStreamChatGenerics>) => {
  // https://github.com/u-motion/u-motion-apps/issues/1146
  const uniqueAttachments = props.attachments.filter(
    (item, index, self) =>
      !item.og_scrape_url ||
      index ===
        self.findIndex(
          (t) =>
            !!t.og_scrape_url &&
            t.title === item.title &&
            (t.title_link ? normalizeUrl(t.title_link) : '') === (item.title_link ? normalizeUrl(item.title_link) : '')
        )
  );

  const images = uniqueAttachments.filter((item) => isUploadedImage(item));
  const ogpContents = uniqueAttachments.filter((item) => isScrapedContent(item));
  const otherContents = uniqueAttachments.filter((item) => !isUploadedImage(item) && !isScrapedContent(item));

  return (
    <>
      <OgpThumbnails ogpContents={ogpContents} />
      <Attachment {...props} attachments={otherContents} />
      <ImageThumbnails images={images} />
    </>
  );
};

const ImageThumbnails: React.FC<{ images: AttachmentProps<DefaultStreamChatGenerics>['attachments'] }> = ({
  images,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [index, setIndex] = useState(0);
  if (images.length === 0) return null;

  if (images.length === 1) {
    return (
      <>
        <img
          style={{
            marginTop: gluestackUIConfig.tokens.space.xs,
            borderRadius: gluestackUIConfig.tokens.borderRadius.small,
            cursor: 'zoom-in',
          }}
          width={450}
          alt={images[0].fallback}
          src={images[0].image_url}
          title={images[0].fallback}
          className="str-chat__base-image"
          data-testid="gallery-image"
          onClick={() => {
            setIsModalOpen(true);
          }}
        />
        <Modal
          className="str-chat__gallery-modal"
          onClose={() => setIsModalOpen((isModalOpen) => !isModalOpen)}
          open={isModalOpen}
        >
          <ModalGallery images={images} index={0} />
        </Modal>
      </>
    );
  } else {
    return (
      <div className="str-chat__attachment-list">
        <div className="str-chat__message-attachment str-chat__message-attachment-dynamic-size str-chat__message-attachment--gallery">
          <div className="str-chat__gallery str-chat__gallery--square">
            {images.map((image, index) => (
              <button
                className="str-chat__gallery-image"
                data-testid="gallery-image"
                key={`gallery-image-${index}`}
                onClick={() => {
                  setIsModalOpen(true);
                  setIndex(index);
                }}
              >
                <img
                  data-testid="str-chat__base-image"
                  alt={image.fallback}
                  src={image.image_url}
                  title={image.fallback}
                  className="str-chat__base-image"
                  style={{ '--original-height': '1000000', '--original-width': '1000000' } as React.CSSProperties}
                />
              </button>
            ))}
            <Modal
              className="str-chat__gallery-modal"
              onClose={() => setIsModalOpen((isModalOpen) => !isModalOpen)}
              open={isModalOpen}
            >
              <ModalGallery images={images} index={index} />
            </Modal>
          </div>
        </div>
      </div>
    );
  }
};

const OgpThumbnails: React.FC<{ ogpContents: AttachmentProps<DefaultStreamChatGenerics>['attachments'] }> = ({
  ogpContents,
}) => {
  return (
    <>
      {ogpContents.map(
        (ogpContent, index) =>
          ogpContent.thumb_url && (
            <Link href={ogpContent.og_scrape_url} isExternal mt={1} key={`ogp-content-${index}`}>
              <VStack
                borderRadius={gluestackUIConfig.tokens.borderRadius.large}
                borderColor="outline"
                borderWidth={1}
                width={450}
              >
                <img
                  style={{
                    borderRadius: gluestackUIConfig.tokens.borderRadius.large,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  alt={ogpContent.title}
                  src={ogpContent.thumb_url}
                  title={ogpContent.title}
                />
                <Box borderColor="outline" borderTopWidth={1} height={120} justifyContent="center">
                  <VStack p="sm" space="xs">
                    <Text color="inverseSurface" fontSize="md" fontWeight="normal">
                      {ogpContent.title}
                    </Text>
                    <Text color="onSurfaceHigh" fontSize="sm" fontWeight="normal" numberOfLines={2}>
                      {ogpContent.text}
                    </Text>
                  </VStack>
                </Box>
              </VStack>
            </Link>
          )
      )}
    </>
  );
};
