import { Ionicons } from '@expo/vector-icons';
import {
  Icon,
  HStack,
  VStack,
  Input,
  Pressable,
  Text,
  Box,
  useToken,
  Button,
  ScrollView,
  Avatar,
} from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { useState, useMemo, useCallback, useEffect } from 'react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useAccounts, useAvatars, useChannelMembers } from '~/hooks';
import { useYomi } from '~/hooks/useYomi';
import ArrowLeftIcons from '~/icons/ArrowLeftIcons';
import type { Channel } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

type Data = {
  id: string;
  name: string;
};

export const ChannelNewMember: React.FC<{
  onClose: () => void;
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
}> = ({ onClose, appChannel }) => {
  const [dataUser, setDataUser] = useState<Data[]>([]);
  const [currentMembers, setCurrentMembers] = useState<{ id?: string; name?: string }[]>([]);
  const { allMembers } = useChannelMembers(appChannel?.id ?? '');
  const navigation = useNavigation();
  const { searchYomi } = useYomi();
  const [onSurfaceHigh] = useToken('color', ['onSurfaceHigh']);
  const { avatarsByChatUserId } = useAvatars();
  const { accounts } = useAccounts();
  const { findUsersByTeamIdAsync } = useStreamChatContext();
  const [query, setQuery] = useState<string>('');
  const [selectedOptionList, setSelectedOptionList] = useState<(string | undefined)[]>([]);

  useEffect(() => {
    setSelectedOptionList(currentMembers.map((m) => m.id));
  }, [currentMembers]);

  useEffect(() => {
    setCurrentMembers(
      allMembers.map((item) => ({
        id: item?.user?.id,
        name: item?.user?.name,
      }))
    );
  }, [allMembers, setCurrentMembers]);

  useEffect(() => {
    const getDataUser = async () => {
      const teamMembers = await updateTeamMembers('');
      if (teamMembers === undefined || teamMembers?.length === 0) {
        return;
      }
      setDataUser(
        teamMembers
          ?.map((m: any) => ({
            id: m.id,
            name: m.name,
          }))
          .filter((m: any) => !currentMembers.find((c) => c.id === m.id)) as Data[]
      );
    };

    getDataUser();
  }, []);

  const updateTeamMembers = async (query: string) => {
    const channelState = await appChannel?.watch();
    const channelTeam = channelState?.channel.team;
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const result = await findUsersByTeamIdAsync(channelTeam, query);
    return result || [];
  };

  const onQuery = (query: any) => {
    return updateTeamMembers(query);
  };

  const filteredOptions = useMemo(() => {
    if (!query) return dataUser;

    const matchedYomi = searchYomi(query);
    return dataUser.filter((opt) => typeof opt.id !== 'undefined' && matchedYomi.find((m) => m.chatUserId === opt.id));
  }, [dataUser, query]);

  const isAllSelected = useMemo(() => {
    return filteredOptions?.every((opt) => selectedOptionList.includes(opt.id));
  }, [dataUser, selectedOptionList]);

  const onSelect = useCallback((id: string) => {
    setSelectedOptionList((prev) => {
      if (prev.includes(id)) {
        return prev.filter((i) => i !== id);
      }
      return [...prev, id];
    });
  }, []);

  const onSelectAll = useCallback(() => {
    setSelectedOptionList(filteredOptions?.map((opt) => opt.id));
  }, [dataUser]);

  const onRemoveAll = useCallback(() => {
    setSelectedOptionList((prev) => prev.filter((i) => !filteredOptions.find((opt) => opt.id === i)));
  }, []);

  const onConfirm = async (selectedChatIds: any) => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    const addedList = selectedChatIds.filter((id) => !currentMembers.some((member) => member.id === id));
    const removeList = currentMembers
      .filter((member) => !selectedChatIds.includes(member.id))
      .map((member) => member.id);

    addedList.length > 0 &&
      appChannel
        ?.addMembers(addedList)
        .then(async (result) => {
          const newMembers = appChannel?.state.members;

          setCurrentMembers(
            Object.keys(newMembers).map((id) => ({
              id,
              name: newMembers[id]?.user?.name,
            }))
          );
        })
        .catch((error) => console.error('addMembersToChannel Error: ', error));

    removeList.length > 0 &&
      appChannel
        ?.removeMembers(removeList as string[])
        .then(async (result) => {
          const memberList = appChannel?.state.members;

          setCurrentMembers(
            Object.keys(memberList).map((id) => ({
              id,
              name: memberList[id]?.user?.name,
            }))
          );
        })
        .catch((error) => console.error('removeMembersToChannel Error: ', error));
  };

  const onSubmit = useCallback(() => {
    if (selectedOptionList) {
      const filteredOptions = selectedOptionList.filter((option): option is string => option !== undefined);
      onConfirm(filteredOptions);
    }

    onClose();
  }, [onConfirm, selectedOptionList]);

  return (
    <VStack height="100vh" width="300px">
      <HStack
        px="sm"
        py="xl"
        gap="sm"
        justifyContent="flex-start"
        alignItems="center"
        borderLeftWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderColor="outlineVariant"
      >
        <Pressable onPress={onClose}>
          <ArrowLeftIcons />
        </Pressable>
        <Text color="onSurface" fontWeight="bold" fontSize="xl">
          メンバーを追加
        </Text>
      </HStack>
      <VStack p="md">
        <Input
          value={query}
          onChangeText={setQuery}
          placeholder="検索"
          variant="rounded"
          color="onSurface"
          // FIXME placeholderTextColor="onSurfaceHigh"
          py="sm"
          px="2xs"
          mb="md"
          fontSize="md"
          size="md"
          borderWidth="none"
          backgroundColor="surfaceHigh"
          InputLeftElement={
            <VStack backgroundColor="surfaceHigh">
              <Icon
                m="xs"
                ml="sm"
                height="xl"
                width="xl"
                fontSize="2xl"
                color="onSurfaceHigh"
                backgroundColor="surfaceHigh"
                as={Ionicons}
                name="search-outline"
              />
            </VStack>
          }
        />
      </VStack>
      <Box px="md" flex={1}>
        <Pressable onPress={isAllSelected ? onRemoveAll : onSelectAll}>
          <HStack justifyContent="flex-end" py="xs">
            <Text fontSize="sm" fontWeight="bold" color="primary">
              {isAllSelected ? 'すべて解除' : 'すべて選択'}
            </Text>
          </HStack>
        </Pressable>
        <ScrollView flex="1">
          {filteredOptions?.map((item) => (
            <Pressable key={`option-${item.id}`} onPress={() => onSelect(item.id)} width="100%">
              <HStack py="xs" alignItems="center" justifyContent="space-between">
                <HStack alignItems="center" justifyContent="flex-start">
                  {item.id ? (
                    <Avatar
                      source={{ uri: avatarsByChatUserId[item.id]?.avatar }}
                      borderColor="outlineVariant"
                      backgroundColor="onSurfaceHighest"
                      alt={item.name}
                      height="2xl"
                      width="2xl"
                      borderRadius="full"
                    />
                  ) : (
                    <Box height="2xl" width="2xl" borderRadius="full" backgroundColor="onSurfaceHigh" />
                  )}
                  <VStack>
                    <Text color="onSurface" ml="xs" fontSize="sm" fontWeight="bold" numberOfLines={1}>
                      {item.name}
                    </Text>
                    <Text color="onSurfaceHigh" ml="2xs" fontSize="sm">
                      {/* @ts-expect-error TS(2538): Type 'any[]' cannot be used as an index type. */}
                      {accounts[[item.id]]}
                    </Text>
                  </VStack>
                </HStack>
                {selectedOptionList.includes(item.id) && (
                  <Ionicons name="checkmark-outline" size={24} color={onSurfaceHigh} />
                )}
              </HStack>
            </Pressable>
          ))}
        </ScrollView>
      </Box>
      <HStack
        py="sm"
        px="md"
        backgroundColor="surfaceLow"
        shadowColor="inverseSurface"
        gap="sm"
        borderRadius={10}
        safeAreaBottom
      >
        <Button
          flex={1}
          onPress={onClose}
          variant="outline"
          rounded="md"
          borderColor="outline"
          _text={{
            color: 'primary',
            fontWeight: 'bold',
          }}
        >
          キャンセル
        </Button>
        <Button
          flex={1}
          onPress={onSubmit}
          rounded="md"
          backgroundColor="primary"
          _text={{
            color: 'onPrimary',
            fontWeight: 'bold',
          }}
        >
          保存
        </Button>
      </HStack>
    </VStack>
  );
};
