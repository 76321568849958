import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '~/store';
import type { PayloadAction } from '@reduxjs/toolkit';

export type PhoneLoginState = {
  phoneNumber: string;
  isSmsSent: boolean;
  isRecaptchaSolved: boolean;
  confirmCode: string;
};

const slice = createSlice({
  name: 'phoneLogin',
  initialState: {} as PhoneLoginState,
  reducers: {
    setPhoneNumber: (state, { payload }: PayloadAction<string>) => {
      state.phoneNumber = payload;
    },
    setIsSmsSent: (state, { payload }: PayloadAction<boolean>) => {
      state.isSmsSent = payload;
    },
    setIsRecaptchaSolved: (state, { payload }: PayloadAction<boolean>) => {
      state.isRecaptchaSolved = payload;
    },
    setConfirmCode: (state, { payload }: PayloadAction<string>) => {
      state.confirmCode = payload;
    },
  },
});

export const { setPhoneNumber, setIsSmsSent, setIsRecaptchaSolved, setConfirmCode } = slice.actions;

export const phoneLoginSelector = (state: RootState) => {
  return state.phoneLogin;
};

export default slice.reducer;
