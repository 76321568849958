// @ts-nocheck
import { useNavigation, useRoute } from '@react-navigation/native';
import { useActor } from '@xstate/react';
import { Text, Center, Button, VStack, Link, Alert } from 'native-base';
import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

import { RegisterToUmotion } from './components/RegisterToUmotion';
import { umotionCowRegisterScreenMachine } from './UmotionCowRegisterScreen.machine';
import type { RouteProp } from '@react-navigation/native';

type UmotionCowRegisterScreenParams = {
  UmotionCowRegisterScreen: {
    cow: any;
  };
};

type RootStackParamList = {
  UmotionCowRegisterScreen: UmotionCowRegisterScreenParams['UmotionCowRegisterScreen'];
};

export const UmotionCowRegisterScreen: React.FC = () => {
  const navigation = useNavigation();
  const route = useRoute<RouteProp<RootStackParamList, 'UmotionCowRegisterScreen'>>();
  const { cow } = route.params;
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [cowUrl, setCowUrl] = useState<string | undefined>(undefined);
  const [state, send] = useActor(umotionCowRegisterScreenMachine.provide({}), {
    input: { cow },
  });

  const handleCancel = useCallback(() => {
    send({ type: 'CANCEL' });
    navigation.goBack();
  }, [navigation, send]);

  const handleError = useCallback(
    (error: string) => {
      setErrorMessage(`${error?.data?.messages?.[0].field}: ${error?.data?.messages?.[0].message}`);
      send({ type: 'REGISTER_ERROR' });
    },
    [send]
  );

  const handleSuccess = useCallback(
    (cowId: number) => {
      setCowUrl(`https://www.sand.u-motion.co.jp/#/cowDetail/${cowId}`);
      send({ type: 'REGISTER_SUCCESS' });
    },
    [send]
  );

  return (
    <ScreenWidthModal title="個体登録">
      {state.matches('registeringCow') && (
        <RegisterToUmotion
          cow={state.context.cow}
          onCancel={handleCancel}
          onError={handleError}
          onSuccess={handleSuccess}
        />
      )}
      {state.matches('registered') && (
        <Center flex={1}>
          <VStack space={4} justifyContent="center">
            <Alert status="success" colorScheme="success">
              <Alert.Icon />
              <Text bold>血統書から個体登録を行いました</Text>
            </Alert>
            <Link href={cowUrl} isExternal>
              U-motionで開く
            </Link>
            <Button variant="ghost" onPress={handleCancel}>
              閉じる
            </Button>
          </VStack>
        </Center>
      )}
      {state.matches('registerError') && (
        <Center flex={1}>
          <VStack space={4}>
            <Alert status="error" colorScheme="error">
              <Alert.Icon />
              <Text>登録に失敗しました</Text>
            </Alert>
            <Text color="error" bold>
              {errorMessage}
            </Text>
            <Button variant="ghost" onPress={handleCancel}>
              閉じる
            </Button>
          </VStack>
        </Center>
      )}
    </ScreenWidthModal>
  );
};

const uMotionCowRegisterShema = yup.object().shape({
  cowNo: yup.string().required('牛番号は必須です'),
  cowUid: yup.string().required('個体識別番号は必須です'),
  birthday: yup.date().required('生年月日は必須です'),
  introduceDate: yup.date().nullable(),
  gender: yup.string().oneOf(['雄', '雌']).required('性別は必須です'),
  cowGroupId: yup.number().nullable(),
  pen: yup.string().nullable(),
  fatherName: yup.string().nullable(),
  motherName: yup.string().nullable(),
  maternalGrandfatherName: yup.string().nullable(),
  maternalGrandmotherName: yup.string().nullable(),
  birthNumber: yup.string().nullable(),
  producingArea: yup.string().nullable(),
  producingFarmName: yup.string().nullable(),
  breedingPoint: yup.string().nullable(),
  registrationNumber: yup.string().nullable(),
});
