import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { FlatList } from '@gluestack-ui/themed';
import { Center, Divider, HStack, Spinner, Text, VStack } from '@gluestack-ui/themed-native-base';
import { useRef } from 'react';
import { Animated, TouchableOpacity } from 'react-native';
import Swipeable from 'react-native-gesture-handler/Swipeable';
import type { Bookmark } from '~/api/uFeedApi';
import { useDeleteCurrentUserBookmarksByIdMutation, useGetCurrentUserBookmarksQuery } from '~/api/uFeedApi';
import { ChatSearchResultItem } from '~/components/ChatSearchResultItem';
import { EmptyMessage } from '~/components/EmptyMessage';
import { useBookmarkQuery } from '~/hooks/useBookmarkQuery';
import type { StreamMessage } from 'stream-chat-react';

type Props = {
  handleMessagePress?: (message?: StreamMessage) => void;
};

export const BookmarkList: React.FC<Props> = ({ handleMessagePress }) => {
  const { messages, initialized, bookmarkQuery } = useBookmarkQuery();
  const currentUserBookmarks = useGetCurrentUserBookmarksQuery();
  const [deleteBookMark] = useDeleteCurrentUserBookmarksByIdMutation();
  const bookmarksRef = useRef<Bookmark[] | null>(null);

  useDidUpdate(
    () => {
      if (currentUserBookmarks.data) {
        bookmarksRef.current = currentUserBookmarks.data;
      }
    },
    [currentUserBookmarks.data],
    true
  );

  const deleteBookmark = (bookMarkID: number | undefined) => {
    if (bookMarkID) {
      deleteBookMark({
        id: bookMarkID,
      });
    }
  };

  const RightAction = (prog: any, drag: any, bookMarkID: number | undefined) => {
    const trans = Animated.add(drag, 65);
    return (
      <TouchableOpacity
        style={{
          justifyContent: 'center',
          transform: [{ translateX: trans }],
        }}
        onPress={() => deleteBookmark(bookMarkID)}
      >
        <HStack backgroundColor="negative" paddingHorizontal="lg" height="full" alignItems="center">
          <Text style={{ color: 'white' }}>削除</Text>
        </HStack>
      </TouchableOpacity>
    );
  };
  return initialized ? (
    <FlatList
      data={messages}
      // @ts-expect-error
      keyExtractor={(item) => `${item.bookmark_created_at}_${item.message.id}`}
      ItemSeparatorComponent={() => (
        <VStack paddingHorizontal="md">
          <Divider backgroundColor="outline" />
        </VStack>
      )}
      renderItem={({ item }) => {
        /* @ts-expect-error */
        const bookMarkID = bookmarksRef.current?.find((b) => b.message_id === item?.message?.id)?.id;

        return (
          <VStack>
            <Swipeable renderRightActions={(prog, drag) => RightAction(prog, drag, bookMarkID)}>
              {/* @ts-expect-error */}
              <ChatSearchResultItem message={item.message} handleMessagePress={handleMessagePress} />
            </Swipeable>
          </VStack>
        );
      }}
      onEndReached={bookmarkQuery}
      onEndReachedThreshold={0.3}
      ListEmptyComponent={<EmptyMessage emptyMessage="ブックマークがありませんでした" />}
      backgroundColor="white"
    />
  ) : (
    <Center flex={1}>
      <Spinner />
    </Center>
  );
};
