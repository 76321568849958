import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { STREAM_CHAT_API_KEY, PUSH_PROVIDER_NAME_FCM, PUSH_PROVIDER_NAME_APN } from '@env';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Notifications from 'expo-notifications';
import { useState, useRef, useMemo } from 'react';
import { Platform, AppState } from 'react-native';
import { StreamChat } from 'stream-chat';

import {
  useDeletePushNotificationsTokenRemoveMutation,
  useGetChatTokenQuery,
  useGetCurrentUserQuery,
  usePatchNotificationsReadMutation,
  usePostPushNotificationsTokenMutation,
} from '~/api/uFeedApi';
import { NUMBER_OF_CHAT_USERS_TO_QUERY, MAX_CHAT_USER_QUERY_OFFSET } from '~/constants';
import { useAuth } from '~/hooks/useAuth';
import { usePushNotification } from '~/hooks/usePushNotification';
import { Streami18n } from '~/lib/StreamChatReact';
import type { StreamChatState } from '~/slices/streamChatStateSlice';
import { setRegisteredPushToken, streamChatSelector } from '~/slices/streamChatStateSlice';
import { useAppSelector, useAppDispatch } from '~/store';
import type { StreamChatGenerics } from '~/types';
import type { UserResponse } from 'stream-chat';

const chatClient = StreamChat.getInstance<StreamChatGenerics>(STREAM_CHAT_API_KEY, {
  timeout: 300000,
});

export const streami18n = new Streami18n({
  language: 'ja',
});

streami18n.registerTranslation('ja', {
  ...streami18n.translations?.ja?.translation,
  // @ts-expect-error TS(2345): Argument of type '{ 'Loading channels...': string;... Remove this comment to see the full error message
  'Loading channels...': 'チャネルを読み込み中...',
  'Loading messages...': 'メッセージを読み込み中...',
  'Loading...': '読み込み中...',
  'Error loading channel list...': 'チャネルリストの読み込み中にエラーが発生しました...',
  'Error loading messages for this channel...': 'このチャネルのメッセージの読み込み中にエラーが発生しました...',
  'Reconnecting...': '再接続中...',
  '1 Reply': '1件のスレッド返信',
  '{{ replyCount }} Replies': '{{ replyCount }}件のスレッド返信',
  '1 Thread Reply': '1件のスレッド返信',
  '{{ replyCount }} Thread Replies': '{{ replyCount }}件のスレッド返信',
  'Thread Reply': 'スレッド返信',
});

const pushProvider = () => {
  switch (Platform.OS) {
    case 'ios':
    case 'macos':
      return 'apn';
    default:
    case 'android':
      return 'firebase';
  }
};

const pushProviderName = () => {
  return Platform.OS === 'android' ? PUSH_PROVIDER_NAME_FCM : PUSH_PROVIDER_NAME_APN;
};

export const useStreamChat = () => {
  const [isError, setIsError] = useState(false);
  const { data: chatToken, isLoading: chatTokenIsLoading, isSuccess: chatTokenIsSuccess } = useGetChatTokenQuery();
  const {
    data: currentUser,
    isLoading: currentUserIsLoading,
    isSuccess: currentUserIsSuccess,
  } = useGetCurrentUserQuery({ real: true });
  const { isLoggedIn } = useAuth();
  const { pushToken, addPushTokenListener } = usePushNotification();
  const isConnectingUserRef = useRef(false);
  const [isUserConnected, setIsUserConnected] = useState(!!chatClient.userID);
  const streamChatState: StreamChatState = useAppSelector(streamChatSelector);
  const dispatch = useAppDispatch();
  const appState = useRef(AppState.currentState);
  const [postToken] = usePostPushNotificationsTokenMutation();
  const [removeToken] = useDeletePushNotificationsTokenRemoveMutation();
  const [readNotification] = usePatchNotificationsReadMutation();

  useDidMount(() => {
    const appStateSubscription = AppState.addEventListener('change', async (nextAppState) => {
      if ((appState.current === 'active' && nextAppState.match(/inactive|background/)) || nextAppState === 'active') {
        // const badgeCount = chatClient?.user?.total_unread_count;
        const result = await updateAppIconBadge(0);
        await readNotification({ type: 'chat' });
      }

      appState.current = nextAppState;
    });

    return () => {
      appStateSubscription.remove();
    };
  });

  useDidUpdate(
    () => {
      if (!chatToken || !currentUser || !isLoggedIn || !chatClient || (Platform.OS !== 'web' && !pushToken)) {
        return;
      }
      init();
    },
    [chatToken, currentUser, isLoggedIn, chatClient, pushToken],
    true
  );

  const init = async () => {
    if (isConnectingUserRef.current) {
      return;
    }

    isConnectingUserRef.current = true;

    if (process.env.APP_VARIANT !== 'beta' && pushToken) {
      const oldToken = await AsyncStorage.getItem('@current_push_token');
      const currentToken = await AsyncStorage.getItem('@chumly_push_token');

      if (oldToken) {
        await unregisterStreamPushToken();
      }

      if (pushToken !== currentToken) {
        AsyncStorage.removeItem('@chumly_push_token');
      }

      if (!streamChatState.registeredPushToken || currentToken == null || pushToken !== currentToken) {
        registerPushToken();
      }
    }

    try {
      if (chatClient?.userID) {
        setIsUserConnected(true);
      } else {
        await connectUser();
      }
    } catch (error) {
      console.error('useStreamChat connectUser() error: ', error);
    }

    isConnectingUserRef.current = false;
  };

  const removeOldTokenAsync = async (oldToken: string) => {
    removeToken({ body: { push_token: oldToken, push_provider_name: pushProvider() } });
  };
  const removeStreamPushTokenAsync = async (oldToken: string) => {
    await chatClient.removeDevice(oldToken).catch((error) => console.error('removeOldTokenAsync error: ', error));
  };

  const handleNewPushTokenAsync = async (newToken: { data: string }) => {
    const oldToken = await AsyncStorage.getItem('@chumly_push_token');
    if (oldToken === newToken.data) {
      return;
    }
    if (oldToken !== null) {
      await removeOldTokenAsync(oldToken);
    }

    await postToken({ body: { push_token: newToken.data, push_provider_name: pushProvider() } });

    await AsyncStorage.setItem('@chumly_push_token', newToken.data).catch((error) =>
      console.error('@chumly_push_token save failed', error)
    );
  };

  const unregisterStreamPushToken = async () => {
    const oldToken = await AsyncStorage.getItem('@current_push_token');
    if (oldToken) {
      removeStreamPushTokenAsync(oldToken);
      AsyncStorage.removeItem('@current_push_token');
    }
  };

  const registerPushToken = async () => {
    try {
      await postToken({ body: { push_token: pushToken, push_provider_name: pushProvider() } });
      dispatch(setRegisteredPushToken(pushToken));
      await AsyncStorage.setItem('@chumly_push_token', pushToken);
    } catch (error) {
      console.error('registerPushToken Error: ', error);
    }

    // @ts-expect-error TS(2345): Argument of type '(newToken: {    data: string;}) ... Remove this comment to see the full error message
    addPushTokenListener(handleNewPushTokenAsync);
  };

  const connectUser = async () => {
    if (!chatToken || !currentUser || chatClient.userID) {
      return;
    }
    try {
      const connectResp = await chatClient.connectUser(
        {
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          id: chatToken?.chat_user_id,
          image: currentUser?.avatar,
          name: currentUser?.name,
        },
        chatToken?.token
      );
      if (connectResp) {
        setIsUserConnected(!!chatClient.userID);
      }
    } catch (error) {
      console.error('connectUser error', error);
      setIsError(true);
    }
  };

  const updateAppIconBadge = (unreadCount: number) => {
    return Notifications.setBadgeCountAsync(unreadCount).catch((error) =>
      console.error('setBadgeCountAsync() error: ', error)
    );
  };

  const { logout, findUsersByTeamIdAsync } = useMemo(() => {
    const logout = async () => {
      chatClient?.disconnectUser();
    };

    const findUsersByTeamIdAsync = async (teamId: string, query: string = '') => {
      let allUsers: UserResponse<StreamChatGenerics>[] = [];

      let offset = 0;
      let shouldLoadMore = true;

      while (shouldLoadMore) {
        const result = await chatClient
          .queryUsers(
            {
              teams: { $contains: teamId },
              ...(query ? { name: { $autocomplete: query } } : null),
            },
            {
              last_active: -1,
            },
            {
              limit: NUMBER_OF_CHAT_USERS_TO_QUERY,
              offset,
            }
          )
          .catch((error) => {
            console.error(`findUsersByTeamIdAsync failed: `, teamId);
            return null;
          });

        if ((result?.users?.length ?? 0) > 0 && offset < MAX_CHAT_USER_QUERY_OFFSET) {
          allUsers = [...allUsers, ...(result?.users ?? [])];
          offset += NUMBER_OF_CHAT_USERS_TO_QUERY;
          shouldLoadMore = result?.users.length === NUMBER_OF_CHAT_USERS_TO_QUERY;
        } else {
          shouldLoadMore = false;
        }
      }

      return allUsers;
    };

    return { logout, findUsersByTeamIdAsync };
  }, [chatClient]);

  const chatUserId = chatToken?.chat_user_id;

  return useMemo(
    () => ({
      isError,
      chatClient,
      logout,
      isUserConnected,
      chatUserId,
      findUsersByTeamIdAsync,
    }),
    [isError, chatClient, logout, isUserConnected, chatUserId, findUsersByTeamIdAsync]
  );
};
