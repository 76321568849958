import { Box, FlatList, HStack, Pressable, Stack, Text, VStack } from '@gluestack-ui/themed-native-base';
import * as ImagePicker from 'expo-image-picker';
import React, { useCallback, useEffect, useState } from 'react';
import { useGetCurrentUserSettingQuery, usePutCurrentUserSettingMutation } from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import ArrowLeftIcons from '~/icons/ArrowLeftIcons';
import BackgroundImageIcons from '~/icons/BackgroundImageIcons';
import CloseIcons from '~/icons/CloseIcons';
import type { MODES } from '.';
import type { Channel } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

const lightColors = [
  '#F0F8FF', // AliceBlue
  '#FAEBD7', // AntiqueWhite
  '#F5F5DC', // Beige
  '#FFE4C4', // Bisque
  '#E0FFFF', // LightCyan
  '#F0FFF0', // HoneyDew
  '#F0E68C', // Khaki
  '#E6E6FA', // Lavender
];

const defaultImage = [
  'https://user0514.cdnw.net/shared/img/thumb/AI_24948800009_TP_V.jpg',
  'https://user0514.cdnw.net/shared/img/thumb/AI_24948800010_TP_V.jpg',
  'https://user0514.cdnw.net/shared/img/thumb/G142_yu-hi_park_TP_V.jpg',
  'https://user0514.cdnw.net/shared/img/thumb/KAZ83_kibakouen_TP_V4.jpg',
  'https://user0514.cdnw.net/shared/img/thumb/aig-ai230816003-xl_TP_V.jpg',
  'https://user0514.cdnw.net/shared/img/thumb/IS107110506_TP_V.jpg',
  'https://user0514.cdnw.net/shared/img/thumb/IMG_3245ISUMI_TP_V.jpg',
];

const ChannelBackgroundEdit: React.FC<{
  onClose: () => void;
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
  setMode: (mode: MODES) => void;
}> = ({ onClose, appChannel, setMode }) => {
  const [colorSelected, setColorSelected] = useState<string>('');
  const [selectImage, setSelectImage] = useState<boolean>(false);
  const [backgroundImage, setBackgroundImage] = useState<string>('');
  const [put] = usePutCurrentUserSettingMutation();
  const userSetting = useGetCurrentUserSettingQuery();

  const addColorChannel = async (channelId: string, color: string) => {
    await put({
      body: {
        user_setting: {
          details: {
            ...userSetting.data?.details,
            channel_background: {
              ...(userSetting.data?.details?.channel_background || {}),
              [channelId]: color,
            },
          },
        },
      },
    }).unwrap();

    setColorSelected('');
    setBackgroundImage('');
  };

  const selectPhoto = useCallback(async () => {
    const response = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (response.granted) {
      const pickerResult = await ImagePicker.launchImageLibraryAsync();
      if (!pickerResult.canceled) {
        setBackgroundImage(pickerResult.assets[0].uri);
      }
    }
  }, []);

  useEffect(() => {
    const chatListDiv = document.querySelector('.str-chat__list') as HTMLElement;
    if (chatListDiv) {
      if (backgroundImage) {
        chatListDiv.style.backgroundImage = `url(${backgroundImage})`;
        chatListDiv.style.backgroundPosition = 'center';
        chatListDiv.style.backgroundSize = 'cover';
      } else if (colorSelected) {
        chatListDiv.style.backgroundColor = colorSelected;
        chatListDiv.style.backgroundImage = '';
      }
    }
  }, [colorSelected, backgroundImage]);

  const handleBackgroundImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setBackgroundImage(imageUrl);
      setColorSelected('');
    }
  };

  return (
    <VStack
      display={!open && 'none'}
      width="300px"
      borderLeftWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderColor="outlineVariant"
    >
      <HStack
        px="sm"
        py="xl"
        gap="sm"
        justifyContent="flex-start"
        alignItems="center"
        borderLeftWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderColor="outlineVariant"
      >
        <Pressable onPress={onClose}>
          <ArrowLeftIcons />
        </Pressable>
        <Text color="onSurface" fontWeight="bold" fontSize="xl">
          背景デザイン
        </Text>
      </HStack>
      <HStack p="md" gap="sm">
        <VStack alignItems="center">
          <Pressable
            onPress={() => {
              setBackgroundImage('');
              setSelectImage(false);
            }}
          >
            <Stack
              width="70"
              height="70"
              backgroundColor={colorSelected || 'surfaceLow'}
              borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
              borderColor="outline"
              borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
            />
          </Pressable>
          <Text fontSize="sm" fontWeight="regular" color={gluestackUIConfig.tokens.colors.mono1000}>
            カラー
          </Text>
        </VStack>
        <VStack alignItems="center">
          <Pressable onPress={() => setSelectImage(true)}>
            <Stack
              width="70"
              height="70"
              justifyContent="center"
              alignItems="center"
              backgroundColor="surfaceHighest"
              borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
              style={{
                backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {!backgroundImage && <BackgroundImageIcons />}
            </Stack>
          </Pressable>
          <input type="file" id="upload-image" style={{ display: 'none' }} accept="image/*" onChange={selectPhoto} />
          <Text fontSize="sm" fontWeight="regular" color={gluestackUIConfig.tokens.colors.mono1000} cursor="pointer">
            自分の写真
          </Text>
          {backgroundImage && (
            <Pressable onPress={() => setBackgroundImage('')} style={{ position: 'absolute', top: 0, right: 0 }}>
              <CloseIcons />
            </Pressable>
          )}
        </VStack>
      </HStack>
      <Stack flex={1}>
        {selectImage ? (
          <FlatList
            data={defaultImage}
            numColumns={3}
            renderItem={({ item }: { item: string }) => (
              <Pressable onPress={() => setBackgroundImage(item)}>
                <Box
                  height="125px"
                  width="100px"
                  m="1px"
                  style={{ backgroundImage: `url(${item})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
                />
              </Pressable>
            )}
            keyExtractor={(item: any, index: any) => index.toString()}
          />
        ) : (
          <FlatList
            data={lightColors}
            numColumns={3}
            renderItem={({ item }: { item: string }) => (
              <Pressable onPress={() => setColorSelected(item)}>
                <Box height="125px" width="100px" m="1px" backgroundColor={item} />
              </Pressable>
            )}
            keyExtractor={(item: any, index: any) => index.toString()}
          />
        )}
      </Stack>
      {(backgroundImage || colorSelected) && (
        <HStack paddingHorizontal="md" paddingVertical="sm" space="sm" justifyContent="space-between">
          <Pressable
            onPress={() => {
              addColorChannel(appChannel?.id as string, gluestackUIConfig.tokens.colors.surfaceLow);
            }}
            flex={1}
            borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outline"
            backgroundColor="surfaceLow"
            height={46}
            alignItems="center"
            justifyContent="center"
          >
            <Text color="primary" bold fontSize="md">
              キャンセル
            </Text>
          </Pressable>
          <Pressable
            onPress={() => {
              if (backgroundImage !== '') {
                addColorChannel(appChannel?.id as string, backgroundImage);
              } else if (colorSelected !== '') {
                addColorChannel(appChannel?.id as string, colorSelected);
              }
            }}
            flex={1}
            borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
            backgroundColor="primaryContainer"
            height={44}
            alignItems="center"
            justifyContent="center"
          >
            <Text color="onPrimary" bold fontSize="md">
              適用する
            </Text>
          </Pressable>
        </HStack>
      )}
    </VStack>
  );
};

export default ChannelBackgroundEdit;
